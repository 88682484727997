import classNames from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';

import IconNew from '../Icon/Icon';
import Text from '../Text/Text';
import { IconName } from '../types';

import styles from './Button.pcss';

import Spinner from 'Atoms/Spinner/Spinner';

type ButtonType = 'black' | 'white' | 'green';
type ButtonDisableState = boolean;
type ButtonText = string;
type ButtonIcon = IconName | null;
type ButtonClickHandler = () => void;
type ButtonInvertedState = boolean;
type ButtonCounterValue = number;
type ButtonLocaleMessage = string;
type ButtonFluid = boolean;
type ButtonIconPosition = 'left' | 'right';
type ButtonActiveState = boolean;

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: ButtonType;
  disabled?: ButtonDisableState;
  text?: ButtonText;
  icon?: ButtonIcon;
  onClick?: ButtonClickHandler;
  inverted?: ButtonInvertedState;
  counter?: ButtonCounterValue;
  msg?: ButtonLocaleMessage;
  fluid?: ButtonFluid;
  iconPosition?: ButtonIconPosition;
  active?: ButtonActiveState;
  className?: string;
  loading?: boolean;
  dataTest?: string;
  formatValues?: { [key: string]: string | number | JSX.Element };
}
const Button: React.FC<Props> = (props) => {
  const {
    text,
    msg,
    fluid,
    color,
    icon,
    iconPosition = 'left',
    dataTest,
    className,
    loading = false,
    formatValues,
    ...nativeProps
  } = props;

  const { disabled = false, onClick } = nativeProps;

  const classList = classNames(styles.root, className, {
    [styles.fluid]: fluid,
    [styles.black]: color === 'black',
    [styles.white]: color === 'white',
    [styles.green]: color === 'green',
  });

  return (
    <button
      {...nativeProps}
      onClick={onClick}
      disabled={disabled}
      className={classList}
      data-test={dataTest}
    >
      {icon && iconPosition === 'left' && !loading && <IconNew name={icon} />}
      {loading && <Spinner color={color === 'black' ? 'white' : undefined} size="sm" />}
      {!!(msg || text) && !loading && (
        <Text msg={msg} formatValues={formatValues} className={styles.text}>
          {text}
        </Text>
      )}
      {icon && iconPosition === 'right' && <IconNew name={icon} />}
    </button>
  );
};

export default Button;
