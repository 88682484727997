import React, { useState, useCallback } from 'react';
import cn from 'classnames';
import { useFragment } from 'react-relay';

import {
  CustomListEditDrawerFragment,
  CustomListEditDrawer_customList$key,
} from './CustomListEditDrawer.Query';
import styles from './CustomListEditDrawer.pcss';
import { CustomListEditor } from './CustomListEditor/CustomListEditor';
import { CustomListRemoveConfirmation } from './CustomListRemoveConfirmation/CustomListRemoveConfirmation';

import Drawer from 'Components/ui/Drawer/Drawer';
import { normalizeFragmentRef } from 'Util/normalizeFragmentRef';

type DrawerProps = React.ComponentProps<typeof Drawer>;

interface Props {
  drawerProps?: Partial<Omit<DrawerProps, 'children'>>;
  customListItemFragment: CustomListEditDrawer_customList$key;
  onCustomListSuccessfulRemoved?: () => void;
  onCustomListSuccessfulEdited?: () => void;
}

const CustomListEditDrawer: React.FC<Props> = (props) => {
  const {
    drawerProps,
    customListItemFragment,
    onCustomListSuccessfulRemoved,
    onCustomListSuccessfulEdited,
  } = props;
  const [variant, setVariant] = useState<Variant>('editor');

  const fragment = useFragment(
    CustomListEditDrawerFragment,
    normalizeFragmentRef<CustomListEditDrawer_customList$key>(customListItemFragment)
  );

  const handleRemoveButtonClick = useCallback(() => {
    setVariant('confirmation');
  }, []);

  const handleRemovingDiscard = useCallback(() => {
    setVariant('editor');
  }, []);

  const handleOpenDrawer = useCallback<NonNullable<DrawerProps['onOpen']>>(() => {
    setVariant('editor');
  }, [drawerProps]);

  if (!fragment) return null;

  const customListId = fragment.id || '';
  const name = fragment.name || '';
  const totalCount = fragment.creators?.totalCount || 0;

  return (
    <Drawer
      rootKey="custom-list-edit"
      removeWhenClosed
      {...drawerProps}
      className={cn(styles.drawer, drawerProps?.className)}
      onOpen={handleOpenDrawer}
    >
      {variant === 'editor' && (
        <CustomListEditor
          customListName={name}
          customListId={customListId}
          customListDescription={fragment.description || ''}
          onRemoveButtonClick={handleRemoveButtonClick}
          onCustomListSuccessfulEdited={onCustomListSuccessfulEdited}
        />
      )}
      {variant === 'confirmation' && (
        <CustomListRemoveConfirmation
          customListName={name}
          totalCount={totalCount}
          customListId={customListId}
          onDiscardButtonClick={handleRemovingDiscard}
          onCustomListSuccessfulRemoved={onCustomListSuccessfulRemoved}
        />
      )}
    </Drawer>
  );
};

export default CustomListEditDrawer;
export { CustomListEditDrawer };

// types

type Variant = 'editor' | 'confirmation';
