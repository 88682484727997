import React, { useState, useContext, useMemo } from 'react';

import styles from './PriceChangeRequest.pcss';

import { amplitude } from 'Helpers/amplitude';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { createNumber } from 'Util/numberFormatter';
import requestPriceChange from 'Mutations/RequestPriceChange.Mutation';
import TopupBalanceContainer from 'Modal/advertiser/TopupBalance/TopupBalanceContainer/TopupBalanceContainer';
import Drawer from 'Components/ui/Drawer/Drawer';
import Text from 'Components/ui/Text/Text';
import Input from 'Components/ui/Input/Input';
import Button from 'Components/ui/Button/Button';
import { Currency } from 'GraphTypes/RequestPriceChangeMutation.graphql';
import { USD } from 'Constants/general';
import { LayoutContext, Types as LayoutTypes } from 'Templates/Layout/Layout.Context';

interface Props {
  currency?: Currency | null;
  projectId: string;
  projectPrice?: number | null;
  balance?: number;
  transactionFee?: number;
  source?: 'popup-chat';
}

const PriceChangeRequest: React.FC<Props> = (props) => {
  const { projectId, projectPrice, balance = 0, source } = props;

  const { closeDrawer } = useContext(DrawerContext);

  const [price, setPrice] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isTopupVisible, setTopupVisible] = useState(false);

  const { dispatch: layoutDispatch } = useContext(LayoutContext);

  const minAmount = 50;

  const isLow = useMemo(() => {
    return price ? price <= Number(balance) + Number(projectPrice) : true;
  }, [price]);

  const getDiff = () => {
    const priceNum = price || 0;

    return !isLow ? priceNum - (Number(projectPrice) + balance) : 0;
  };

  const diff = getDiff();

  const handleChange = (price: string) => {
    if (price) {
      const newValue = price.replace(/[^0-9]/g, '');
      const newPrice = parseInt(newValue, 10) || undefined;
      setPrice(newPrice);
    } else {
      setPrice(undefined);
    }
  };

  const minTopupDone = () => {
    setTopupVisible(false);
    if (!price) return;
    layoutDispatch({ type: LayoutTypes.TOGGLE_PRELOADER, payload: { value: true } });
    setError(false);
    requestPriceChange({ price, projectId, currency: USD }, requestSendDone, sendFailed);
  };

  const handleSubmit = () => {
    setIsLoading(true);

    if (diff !== 0) {
      setTopupVisible(true);
      return;
    }

    if (price) {
      layoutDispatch({ type: LayoutTypes.TOGGLE_PRELOADER, payload: { value: true } });
      requestPriceChange({ price, projectId, currency: USD }, requestSendDone, sendFailed);
    }
  };

  const sendFailed = () => {
    setError(true);
    setIsLoading(false);
    layoutDispatch({ type: LayoutTypes.TOGGLE_PRELOADER, payload: { value: false } });
  };

  const requestSendDone = () => {
    amplitude.sendEvent<341>({
      id: '341',
      category: 'chat',
      name: 'price_change_request',
      param: {
        project_id: projectId,
        price: price || 0,
      },
    });
    layoutDispatch({ type: LayoutTypes.TOGGLE_PRELOADER, payload: { value: false } });
    closePriceChangeRequestModal();
  };

  const closePriceChangeRequestModal = () => {
    closeDrawer(`project-price-request-${projectId}`);
  };

  const handleClose = () => {
    setIsLoading(false);
    setTopupVisible(false);
  };

  const btnTitle = isLow ? 'send_request' : 'topup_send_request';
  const priceValue = price ? createNumber(price) : '';

  return (
    <Drawer
      rootKey={`project-price-request-${projectId}`}
      className={styles.drawer}
      onClose={handleClose}
    >
      {isTopupVisible && (
        <TopupBalanceContainer
          amount={Math.max(minAmount, diff)}
          purpose="TOPUP_FOR_PRICE_CHANGE_REQUEST"
          successCallback={minTopupDone}
        />
      )}
      {!isTopupVisible && (
        <div className={styles.drawerContainer}>
          <div className={styles.content}>
            <Text type="d2" msg="price_change_request_modal.title" className={styles.title} />
            <Text type="md" msg="price_change_request_modal.descr" className={styles.descr} />
            <Input
              placeholderMsg="price_change_request_modal.price_title"
              value={priceValue}
              handleChange={handleChange}
              bordered
              error={!!error}
              className={styles.input}
            />
            {!isLow && (
              <Text
                type="sm"
                msg="price_change_request_modal.need_topup"
                className={styles.needTopup}
              />
            )}
            {error && <Text type="sm" msg="general.error" className={styles.error} />}
          </div>
          <Button
            color="black"
            loading={isLoading}
            msg={`price_change_request_modal.${btnTitle}`}
            onClick={price ? handleSubmit : undefined}
            disabled={!price}
            className={styles.btn}
          />
        </div>
      )}
    </Drawer>
  );
};

export default PriceChangeRequest;
