import React, { FC, useContext } from 'react';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import styles from './OutreachBatch.pcss';
import BatchStatus from './BatchStatus/BatchStatus';

import ErrorsDrawer from '../ErrorsDrawer/ErrorsDrawer';

import colors from 'Styles/vars/colors.css';
import { amplitude } from 'Helpers/amplitude';
import createDate from 'Util/dateCreator';
import Text from 'Components/ui/Text/Text';
import Spinner from 'Atoms/Spinner/Spinner';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Icon from 'Components/ui/Icon/Icon';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { OutreachBatch_outreachBatch$key } from 'GraphTypes/OutreachBatch_outreachBatch.graphql';
import { OutreachBatchQuery } from 'GraphTypes/OutreachBatchQuery.graphql';
import AddGmailAccountButton from '../../OutreachBatchesControls/AddGmailAccountButton/AddGmailAccountButton';

interface Props {
  batch?: OutreachBatch_outreachBatch$key | null;
  campaignId: string;
  showPercentage: boolean;
}

const OutreachBatch: FC<Props> = ({ batch, campaignId, showPercentage }) => {
  const { openDrawer } = useContext(DrawerContext);

  const fragment = useFragment(
    graphql`
      fragment OutreachBatch_outreachBatch on OutreachBatch {
        id
        state
        transport
        gmailAuthorization {
          id
          reauthenticationRequired
        }
        creatorImport {
          csvFilename
          createdAt
          importedCreators
          totalCreators
          completed
          rows {
            edges {
              node {
                id
                email
                username
                error
              }
            }
          }
        }
        template {
          name
        }
      }
    `,
    batch
  );

  if (!fragment) return null;

  const batchId = fragment.id;
  const fileName = fragment?.creatorImport?.csvFilename;
  const createdAt = fragment?.creatorImport?.createdAt;
  const totalCreators = fragment?.creatorImport?.totalCreators || 0;
  const templateName = fragment?.template?.name;
  const transport = fragment.transport;
  const needGmailReauth = Boolean(fragment.gmailAuthorization?.reauthenticationRequired);
  const usedGmailAuthId = fragment.gmailAuthorization?.id;
  const onErrorsClick = () => openDrawer(`invite-outreach-errors-${fragment?.id}`);
  const isBatchWithImport = Boolean(fragment?.creatorImport);

  const data = useLazyLoadQuery<OutreachBatchQuery>(
    graphql`
      query OutreachBatchQuery($campaignId: ID!, $batchId: ID!) {
        campaign(id: $campaignId) {
          enqueuedCount: projects(outreachBatchIds: [$batchId], outreachStages: [ENQUEUED]) {
            totalCount
          }
          reachedCount: projects(
            outreachBatchIds: [$batchId]
            outreachStages: [OUTREACHED, OPENED, CLICKED, APPLIED, HIRED, REPLIED]
          ) {
            totalCount
          }
          openedCount: projects(
            outreachBatchIds: [$batchId]
            outreachStages: [OPENED, CLICKED, APPLIED, HIRED, REPLIED]
          ) {
            totalCount
          }
          clickedCount: projects(
            outreachBatchIds: [$batchId]
            outreachStages: [CLICKED, APPLIED, HIRED, REPLIED]
          ) {
            totalCount
          }
          repliedCount: projects(outreachBatchIds: [$batchId], outreachStages: [REPLIED]) {
            totalCount
          }
          appliedCount: projects(outreachBatchIds: [$batchId], outreachStages: [APPLIED, HIRED]) {
            totalCount
          }
          hiredCount: projects(outreachBatchIds: [$batchId], outreachStages: [HIRED]) {
            totalCount
          }
        }
      }
    `,
    { campaignId, batchId: batchId || '' }
  );

  const date = createDate(createdAt);
  const time = createDate(createdAt, 'h:mm a');
  const rows = fragment.creatorImport?.rows?.edges?.map((edge) => edge?.node);
  const errors = rows
    ?.filter((row) => !!row?.error)
    .map((item) => {
      return {
        email: item?.email || '',
        error: item?.error || 'UNKNOWN_ERROR',
        username: item?.username || '',
      };
    });
  const errorsCount = errors?.length || 0;

  const onDownloadClick = () => {
    amplitude.sendEvent<413>({
      id: '413',
      category: 'campaign',
      name: 'outreach_download_csv_click',
      param: undefined,
    });

    const rowsArray = rows?.map((row) => [row?.username, row?.email]);
    let csvContent = 'data:text/csv;charset=utf-8,';
    rowsArray?.forEach((row) => {
      csvContent += `${row.join(',')}\r\n`;
    });
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', fileName || 'unknownName');
    document.body.appendChild(link);
    link.click();
  };

  const calcMetricValue = (value: number | undefined) => {
    if (value === undefined) {
      return showPercentage ? '0%' : '0';
    }
    return showPercentage ? Number((value / totalCreators).toFixed(2)) * 100 + '%' : value;
  };

  const hiredCount = Number(data?.campaign?.hiredCount.totalCount);
  const appliedCount = Number(data?.campaign?.appliedCount.totalCount);
  const repliedCount = Number(data?.campaign?.repliedCount.totalCount);

  const isCompleted = fragment.creatorImport?.completed;

  return (
    <div className={styles.root}>
      <div className={styles.dataContainer}>
        {isBatchWithImport ? (
          <div className={classnames(styles.title, styles.details)}>
            {!isCompleted && <Spinner size="sm" color={colors.colorGrey50} />}
            <div>
              <div className={styles.downloadContainer}>
                <Text type="md" text={fileName || ''} />
                {isCompleted && (
                  <Icon
                    name="Download-upload"
                    className={styles.purple}
                    handleClick={onDownloadClick}
                    size={16}
                  />
                )}
              </div>
              <BatchStatus id={batchId} />
              <Text
                msg="campaign.outreach.date"
                formatValues={{ date: date || '', time: time || '' }}
                type="label"
                className={styles.grey}
              />
            </div>
          </div>
        ) : (
          <div className={classnames(styles.title, styles.details)}>
            <Icon name="Check" />
            <div>
              <div className={styles.downloadContainer}>
                <Text type="md" text={'Invitations sent'} />
              </div>
            </div>
          </div>
        )}
        <div className={styles.names}>
          <Text
            type="md"
            msg={transport === 'GMAIL' ? 'campaign.outreach.gmail' : 'campaign.outreach.email'}
          />
          <Text text={templateName} type="label" className={styles.grey} />
        </div>
        {needGmailReauth && (
          <AddGmailAccountButton
            buttonProps={{
              msg: 'campaign.outreach.reauth',
              leftElement: <Icon name={'Warning-error'} />,
              className: styles.errors,
              icon: null,
              type: 'white',
            }}
            authIdForReauth={usedGmailAuthId}
          />
        )}
        <div className={styles.controls}>
          {errorsCount > 0 && (
            <AlterButton
              msg={'campaign.outreach.errors'}
              leftElement={<Icon name={'Warning-error'} />}
              counter={errorsCount}
              className={styles.errors}
              onClick={onErrorsClick}
            />
          )}
        </div>
      </div>
      <div className={styles.dataContainer}>
        <div className={styles.subtitleContainer}>
          <Text type="md" text={totalCreators} />
          <Text msg="campaign.outreach.column_title.all" type="label" className={styles.grey} />
        </div>
        <div className={styles.subtitleContainer}>
          <Text type="md" text={`${calcMetricValue(data?.campaign?.enqueuedCount.totalCount)}`} />
          <Text
            msg="campaign.outreach.column_title.enqueued"
            type="label"
            className={styles.grey}
          />
        </div>
        <div className={styles.subtitleContainer}>
          <Text type="md" text={`${calcMetricValue(data?.campaign?.reachedCount.totalCount)}`} />
          <Text msg="campaign.outreach.column_title.reached" type="label" className={styles.grey} />
        </div>
        <div className={styles.subtitleContainer}>
          <Text type="md" text={`${calcMetricValue(data?.campaign?.openedCount.totalCount)}`} />
          <Text msg="campaign.outreach.column_title.opened" type="label" className={styles.grey} />
        </div>
        <div className={styles.subtitleContainer}>
          <Text type="md" text={`${calcMetricValue(data?.campaign?.clickedCount.totalCount)}`} />
          <Text msg="campaign.outreach.column_title.clicked" type="label" className={styles.grey} />
        </div>
        <div className={styles.subtitleContainer}>
          {repliedCount > 0 ? (
            <Link to={`/campaigns/${campaignId}/awaiting-list`}>
              <Text
                type="md"
                text={`${showPercentage ? calcMetricValue(repliedCount) : repliedCount}`}
              />
            </Link>
          ) : (
            <Text
              type="md"
              text={`${showPercentage ? calcMetricValue(repliedCount) : repliedCount}`}
            />
          )}
          <Text msg="campaign.outreach.column_title.replied" type="label" className={styles.grey} />
        </div>
        <div className={styles.subtitleContainer}>
          {appliedCount > 0 ? (
            <Link to={`/campaigns/${campaignId}/applicants`}>
              <Text
                type="md"
                text={`${showPercentage ? calcMetricValue(appliedCount) : appliedCount}`}
                className={styles.purple}
              />
            </Link>
          ) : (
            <Text
              type="md"
              text={`${showPercentage ? calcMetricValue(appliedCount) : appliedCount}`}
            />
          )}
          <Text msg="campaign.outreach.column_title.applied" type="label" className={styles.grey} />
        </div>
        <div className={styles.subtitleContainer}>
          {hiredCount > 0 ? (
            <Link to={`/campaigns/${campaignId}/creators`}>
              <Text
                type="md"
                text={`${showPercentage ? calcMetricValue(hiredCount) : hiredCount}`}
                className={styles.purple}
              />
            </Link>
          ) : (
            <Text type="md" text={`${showPercentage ? calcMetricValue(hiredCount) : hiredCount}`} />
          )}
          <Text msg="campaign.outreach.column_title.hired" type="label" className={styles.grey} />
        </div>
      </div>
      {errors && <ErrorsDrawer batchId={batchId || 'notAnId'} errors={errors} />}
    </div>
  );
};

export default OutreachBatch;
