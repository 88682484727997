import React, { useContext } from 'react';

import { amplitude } from 'Helpers/amplitude';
import { modalName } from 'Types/modals';
import Button from 'Atoms/Button/Button';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

interface Props {
  campaignId: string;
  creatorId: string;
  projectId: string;
}

const StartNewDealAction: React.FC<Props> = (props) => {
  const { campaignId, creatorId, projectId } = props;

  const { dispatch: modalDispatch } = useContext(ModalContext);

  const handleStarNewDealClick = () => {
    amplitude.sendEvent<153>({
      id: '153',
      category: 'brand',
      name: 'start_new_deal_chat',
      param: { creator_id: creatorId, project_id: projectId },
    });
    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: { name: modalName.START_NEW_DEAL, attach: { campaignId, creatorId, projectId } },
    });
  };

  return (
    <Button msg="chat.project_creator_rating.start_new_deal" onClick={handleStarNewDealClick} />
  );
};

export default StartNewDealAction;
