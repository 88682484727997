import React, { useState, useContext } from 'react';
import track, { useTracking } from 'react-tracking';

import styles from './ListingAcceptanceActions.css';

import hideListing from 'Mutations/HideListing.Mutation';
import Button from 'Atoms/Button/Button';
import { productPurchaseData } from 'Constants/messageModal';
import { ProductDeliveryOption } from 'GraphTypes/ListingBriefQuery.graphql';
import { modalName } from 'Types/modals';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

interface Props {
  hasProfiles: boolean;
  listingId: string;
  productDelivery?: ProductDeliveryOption | null;
}

const ListingAcceptanceActions: React.FC<Props> = (props) => {
  const { listingId, hasProfiles, productDelivery } = props;

  const [loading, setLoading] = useState(false);
  const [isHidden, setHidden] = useState(false);

  const { dispatch: modalDispatch } = useContext(ModalContext);

  const tracking = useTracking();

  const handleHideListing = (trackData?: object) => {
    tracking.trackEvent({
      goal: 'contractor_brief_reject_click',
      element: 'reject',
      event: 'clicked',
      ...trackData,
    });
    setLoading(true);
    hideListing({ listingId }, handleRejectDone);
  };

  const handleRejectDone = () => {
    setHidden(true);
  };

  const handleApplyClick = (trackData?: object) => {
    tracking.trackEvent({
      goal: 'contractor_brief_apply_click',
      element: 'apply',
      event: 'clicked',
      ...trackData,
    });
    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: { name: modalName.POTENTIAL_PARTICIPANT, attach: { listingId } },
    });
  };

  if (isHidden) return null;

  const handleShoppingProductApply = () => {
    tracking.trackEvent({
      goal: 'contractor_brief_apply_click',
      element: 'apply',
      event: 'clicked',
    });

    const handleRejectInModal = () => {
      handleHideListing();
    };

    const handleApplyInModal = () => {
      handleApplyClick();
    };

    const el = (
      <div className={styles.buttonsInModal}>
        <Button
          color="normal"
          size="lg"
          width="full"
          msg="brief_modal.reject"
          className={styles.button}
          onClick={handleRejectInModal}
        />
        <Button
          size="lg"
          width="full"
          msg="brief_modal.agree"
          className={styles.button}
          onClick={handleApplyInModal}
        />
      </div>
    );

    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: { name: modalName.MESSAGE, attach: { ...productPurchaseData, el } },
    });
  };

  const handleApplyFn =
    productDelivery === 'BRAND_WILL_REFUND_THE_PURCHASED_PRODUCT'
      ? handleShoppingProductApply
      : handleApplyClick;

  const applyClick = handleApplyFn;

  const rejectOffer = () => {
    handleHideListing();
  };

  const applyOffer = () => {
    applyClick();
  };

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <Button
          color="normal"
          size="lg"
          msg="brief_modal.reject"
          loading={loading}
          className={styles.button}
          onClick={rejectOffer}
        />
        {hasProfiles ? (
          <Button
            size="lg"
            msg="brief_modal.apply"
            loading={loading}
            className={styles.button}
            onClick={applyOffer}
          />
        ) : (
          <Button
            size="lg"
            msg="brief_modal.no_profiles"
            disabled={true}
            loading={loading}
            className={styles.button}
          />
        )}
      </div>
    </div>
  );
};

export default track({ subsection: 'buttons' })(ListingAcceptanceActions);
