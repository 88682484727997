import React, { createContext, useReducer, PropsWithChildren, FC, Dispatch } from 'react';

type LayoutContextValue = {
  showHeader: boolean;
  showPreloader: boolean;
};

const initialValue: LayoutContextValue = {
  showHeader: false,
  showPreloader: false,
};

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum Types {
  TOGGLE_HEADER = 'TOGGLE_HEADER',
  TOGGLE_PRELOADER = 'TOGGLE_PRELOADER',
}

type LayoutPayload = {
  [Types.TOGGLE_HEADER]: {
    value: boolean;
  };
  [Types.TOGGLE_PRELOADER]: {
    value: boolean;
  };
};

export type LayoutActions = ActionMap<LayoutPayload>[keyof ActionMap<LayoutPayload>];

type Reducer = (state: LayoutContextValue, action: LayoutActions) => LayoutContextValue;
const reducer: Reducer = (state, action) => {
  switch (action.type) {
    case Types.TOGGLE_HEADER: {
      return {
        ...state,
        showHeader: action.payload.value,
      };
    }
    case Types.TOGGLE_PRELOADER: {
      return {
        ...state,
        showPreloader: action.payload.value,
      };
    }
    default:
      return state;
  }
};

const LayoutContext = createContext<{
  state: LayoutContextValue;
  dispatch: Dispatch<LayoutActions>;
}>({ state: initialValue, dispatch: () => null });

type LayoutContextProviderType = FC<PropsWithChildren<{}>>;

const LayoutContextProvider: LayoutContextProviderType = (props) => {
  const { children } = props;

  const [state, dispatch] = useReducer<Reducer>(reducer, initialValue);

  return <LayoutContext.Provider value={{ state, dispatch }}>{children}</LayoutContext.Provider>;
};

export { LayoutContext, LayoutContextProvider };
export type { LayoutContextProviderType };
