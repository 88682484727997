import React, { useEffect, useContext } from 'react';
import { identifyUser, trackPage } from 'Analytics/engines/Hubspot';
import ls from 'Util/localStorage';
import { ADVERTISER } from 'Constants/general';
import { LayoutContext, Types as LayoutTypes } from 'Templates/Layout/Layout.Context';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

const LocationHandler: React.FC = () => {
  const { dispatch: layoutDispatch } = useContext(LayoutContext);
  const { state: modalState, dispatch: modalDispatch } = useContext(ModalContext);

  const { opened, forceClose } = modalState;

  useEffect(() => {
    const email = ls.get('email');
    const userType = ls.get('userType');

    if (email && userType === ADVERTISER) {
      identifyUser({ email });
      trackPage(location.pathname);
    }

    if (!window.unblock) {
      layoutDispatch({ type: LayoutTypes.TOGGLE_PRELOADER, payload: { value: false } });
      if (opened && !forceClose) {
        modalDispatch({
          type: ModalTypes.TOGGLE_MODAL,
        });
      }
    }
  }, []);

  return null;
};

export default LocationHandler;
