import React, { useContext } from 'react';
import { useFragment, graphql } from 'react-relay';

import styles from './BalanceInfo.pcss';

import { amplitude } from 'Helpers/amplitude';
import { createSum } from 'Util/numberFormatter';
import TopupBalanceDrawer from 'Modal/advertiser/TopupBalance/TopupBalance';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import IconNew from 'Components/ui/Icon/Icon';
import {
  BalanceInfo_organization$key,
  BalanceInfo_organization$data,
} from 'GraphTypes/BalanceInfo_organization.graphql';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { BILLINGHISTORY_ROUTE } from 'Constants/general';

interface Props {
  organization: BalanceInfo_organization$key;
}

const BalanceInfo: React.FC<Props> = (props) => {
  const { organization } = props;

  const { openDrawer } = useContext(DrawerContext);

  if (!organization) return null;

  const data = useFragment(
    graphql`
      fragment BalanceInfo_organization on Organization {
        paymentAccount {
          balance
          currency
        }
        subscription {
          canCreateDeposits
        }
      }
    `,
    organization as any
  ) as BalanceInfo_organization$data;

  const balance = data.paymentAccount?.balance;
  const currency = data.paymentAccount?.currency;
  const finalBalance = balance && balance < 0 ? 0 : balance;
  const balanceValue = createSum(finalBalance, currency);

  const canCreateDeposits = data.subscription?.canCreateDeposits;

  const handleTopupClick = () => {
    amplitude.sendEvent({
      id: '163',
      category: 'plans_and_billing',
      name: 'top_up_the_balance_click',
    });
    openDrawer('topup-balance-top_up');
  };

  const handleTransactionsClick = () => {
    amplitude.sendEvent({
      id: '164',
      category: 'plans_and_billing',
      name: 'transaction_history_click',
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.balanceContent}>
        <div className={styles.balanceValue}>
          <Text type="h1" msg="billing.balance.title" className={styles.title} />
          <Text type="d2" text={balanceValue} />
          <Text type="md" msg="billing.balance.descr" className={styles.subtitle} />
        </div>
        <Tooltip
          id="topup"
          tooltipMsg={canCreateDeposits ? undefined : 'billing.balance.topup_disabled.tooltip'}
        >
          <AlterButton
            type={canCreateDeposits ? 'black' : 'grey'}
            icon="Add-plus-circle"
            msg="billing.balance.topup"
            disabled={!canCreateDeposits}
            className={styles.topup}
            onClick={handleTopupClick}
          />
        </Tooltip>
      </div>
      <SmartLink
        path={BILLINGHISTORY_ROUTE}
        target="_blank"
        className={styles.transactionsContent}
        onClick={handleTransactionsClick}
      >
        <Text type="md" msg="billing.balance.transactions" className={styles.transactions} />
        <IconNew name="Time-clock" />
      </SmartLink>
      <TopupBalanceDrawer purpose="TOP_UP" />
    </div>
  );
};

export default BalanceInfo;
