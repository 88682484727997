import React, { FC } from 'react';

import styles from './OutreachBatchesControls.pcss';

import ProgressBar from 'Components/ui/ProgressBar/ProgressBar';
import Text from 'Components/ui/Text/Text';
import Toggle from 'Components/ui/Toggle/Toggle';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

interface Props {
  handleInvite: () => void;
  todayLimit: number;
  totalLimit: number;
  showPercentages: boolean;
  onShowPercentagesClick: () => void;
}

const OutreachBatchesControls: FC<Props> = ({
  handleInvite,
  totalLimit,
  todayLimit,
  onShowPercentagesClick,
  showPercentages,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.limit}>
        <ProgressBar
          value={(todayLimit / totalLimit) * 100}
          className={styles.progressBar}
          classes={{ root: styles.barContainer, bar: styles.bar }}
        />
        <Text
          msg="campaign.outreach.limit"
          formatValues={{ sended: todayLimit, limit: totalLimit }}
          type="md"
        />
      </div>
      <div className={styles.controls}>
        <Text msg="campaign.outreach.percentage.toggle" type="md" />
        <Toggle onChange={onShowPercentagesClick} on={showPercentages} />
        <AlterButton
          msg="campaign.outreach.invite"
          type="black"
          className={styles.button}
          onClick={handleInvite}
        />
      </div>
    </div>
  );
};

export default OutreachBatchesControls;
