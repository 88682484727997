import React, { createContext, useReducer, PropsWithChildren, FC, Dispatch } from 'react';

type SearchFiltersDataContextValue = {
  searchParamsData: DataType | null;
};

const initialValue: SearchFiltersDataContextValue = {
  searchParamsData: null,
};

type DataType = {
  [key: string]: { id: number; name: string }[];
};

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum Types {
  ADD_SEARCH_PARAMS_DATA = 'ADD_SEARCH_PARAMS_DATA',
}

type SearchFiltersDataPayload = {
  [Types.ADD_SEARCH_PARAMS_DATA]: {
    searchParamsData: any;
  };
};

export type SearchFiltersDataActions =
  ActionMap<SearchFiltersDataPayload>[keyof ActionMap<SearchFiltersDataPayload>];

const formatSearchData = (data: DataType) => {
  const keys = Object.keys(data);
  const newData: DataType = {};
  keys.forEach((key) => {
    const list = data[key];
    const items = list.map((item) => {
      return { ...item, value: item.id };
    });
    newData[key] = items;
  });

  return newData;
};

type Reducer = (
  state: SearchFiltersDataContextValue,
  action: SearchFiltersDataActions
) => SearchFiltersDataContextValue;
const reducer: Reducer = (state, action) => {
  switch (action.type) {
    case Types.ADD_SEARCH_PARAMS_DATA: {
      const searchParamsData = formatSearchData(action.payload.searchParamsData);

      return {
        ...state,
        searchParamsData,
      };
    }
    default:
      return state;
  }
};

const SearchFiltersDataContext = createContext<{
  state: SearchFiltersDataContextValue;
  dispatch: Dispatch<SearchFiltersDataActions>;
}>({ state: initialValue, dispatch: () => null });

type SearchFiltersDataProviderType = FC<PropsWithChildren<{}>>;

const SearchFiltersDataProvider: SearchFiltersDataProviderType = (props) => {
  const { children } = props;

  const [state, dispatch] = useReducer<Reducer>(reducer, initialValue);

  return (
    <SearchFiltersDataContext.Provider value={{ state, dispatch }}>
      {children}
    </SearchFiltersDataContext.Provider>
  );
};

export { SearchFiltersDataContext, SearchFiltersDataProvider };
export type { SearchFiltersDataProviderType };
