import React, { useContext, useEffect, useState } from 'react';
import classnames from 'classnames';

import styles from './MediaUploader.pcss';

import { MessageAttachmentsContext } from '../../../MessageAttachments/MessageAttachmentsContext';

import { amplitude } from 'Helpers/amplitude';
import { createBytesSize } from 'Util/numberFormatter';
import { downloadFile } from 'Util/downloadFile';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import { MessageMedia_file$data } from 'GraphTypes/MessageMedia_file.graphql';

interface Props {
  data: MessageMedia_file$data;
  projectId: string;
  index: number;
}

const MediaUploader: React.FC<Props> = (props) => {
  const { data, projectId, index } = props;
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [failedUpload, setFailedUpload] = useState(false);

  const { loadingAll, handleFileUploaded } = useContext(MessageAttachmentsContext);

  useEffect(() => {
    if (loadingAll) {
      handleUpload();
    }
  }, [loadingAll]);

  const handleLoaded = () => {
    handleFileUploaded();
    setUploading(false);
    setProgress(0);
  };

  const handleFailed = () => {
    handleFileUploaded();
    setUploading(false);
    setFailedUpload(true);
    setProgress(0);
    amplitude.sendEvent<437>({
      id: '437',
      category: 'chat',
      name: 'download_file_error',
      param: { project_id: projectId },
    });
  };

  const handleProgress = (loaded: number, total: number) => {
    setProgress(Math.round((loaded / total) * 100));
  };

  const handleUpload = () => {
    setUploading(true);
    setFailedUpload(false);
    const bcaTaggedUrl = data?.bcaTaggedUrl;
    const secureUrl = data?.secureUrl;
    const originalFilename = data?.originalFilename;
    const finalName = decodeURIComponent(bcaTaggedUrl || secureUrl || '');
    const filename = originalFilename || finalName?.split('/')?.pop()?.split('?')[0];
    const finalLink = bcaTaggedUrl || secureUrl;
    setTimeout(() => {
      downloadFile(finalLink, filename, handleLoaded, handleFailed, handleProgress);
    }, 300 * index);
  };

  const handleDownloadRetry = () => {
    handleUpload();
  };

  const classes = classnames(styles.uploaderWrapper, {
    [styles.uploading]: uploading,
    [styles.failedUpload]: failedUpload,
  });

  if (!uploading) {
    return null;
  }

  return (
    <div className={classes}>
      {uploading && (
        <div className={styles.uploader}>
          <div
            className={styles.progressBar}
            style={{
              background: `radial-gradient(closest-side, #999999 90%, transparent 80% 100%),
    conic-gradient(white ${progress}%, #999999 0)`,
            }}
          >
            <Text type="labelSm" text={`${progress}%`} />
          </div>
        </div>
      )}
      {failedUpload && (
        <div className={styles.downloadRetry} onClick={handleDownloadRetry}>
          <Icon size={16} name="Download-upload" />
          <Text type="labelSm" text={createBytesSize(data.bytes)} />
        </div>
      )}
    </div>
  );
};

export default MediaUploader;
