import { graphql, useMutation } from 'react-relay';
import { IndicateInterestInUseCaseMutation } from 'GraphTypes/IndicateInterestInUseCaseMutation.graphql';
import { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const mutation = graphql`
  mutation IndicateInterestInUseCaseMutation($input: IndicateInterestInUseCaseInput!) {
    indicateInterestInUseCase(input: $input) {
      ... on EmptyPayload {
        clientMutationId
      }
    }
  }
`;

export const useIndicateInterestInUseCaseMutation: BaseMutation<
  IndicateInterestInUseCaseMutation
> = (config) => {
  const [indicateInterestInUseCase, loading] =
    useMutation<IndicateInterestInUseCaseMutation>(mutation);

  const indicateInterestInUseCaseCaller: BaseMutationExecFn<IndicateInterestInUseCaseMutation> = (
    execConfig
  ) => {
    indicateInterestInUseCase({
      ...config,
      ...execConfig,
    });
  };
  return [indicateInterestInUseCaseCaller, loading];
};
