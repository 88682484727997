import React, { createContext, useReducer, PropsWithChildren, FC, Dispatch } from 'react';

type ActionsDataProps = {
  id: string;
  text?: string;
  values?: object;
  btnCaption?: string;
  callback?: (preventUpdate?: boolean) => void;
};

interface NotificationsStateProps {
  items: ActionsDataProps[];
}

const initialValue: NotificationsStateProps = {
  items: [],
};

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum Types {
  ADD_NOTIFICATION = 'ADD_NOTIFICATION',
  REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION',
}

type NotificationsPayload = {
  [Types.ADD_NOTIFICATION]: {
    item: ActionsDataProps;
  };
  [Types.REMOVE_NOTIFICATION]: {
    itemId: string;
  };
};

export type NotificationsActions =
  ActionMap<NotificationsPayload>[keyof ActionMap<NotificationsPayload>];

type Reducer = (
  state: NotificationsStateProps,
  action: NotificationsActions
) => NotificationsStateProps;
const reducer: Reducer = (state, action) => {
  switch (action.type) {
    case Types.ADD_NOTIFICATION: {
      const newItem = action.payload.item;

      const exist = state.items.some((item) => item.id === newItem.id);
      if (exist) return state;

      return {
        ...state,
        items: [...state.items, newItem],
      };
    }
    case Types.REMOVE_NOTIFICATION: {
      const idForRemove = action.payload.itemId;
      const newItems = state.items.filter((item) => item.id !== idForRemove);
      return {
        ...state,
        items: newItems,
      };
    }
    default:
      return state;
  }
};

const NotificationsContext = createContext<{
  state: NotificationsStateProps;
  dispatch: Dispatch<NotificationsActions>;
}>({ state: initialValue, dispatch: () => null });

type NotificationsContextProviderType = FC<PropsWithChildren<{}>>;

const NotificationsContextProvider: NotificationsContextProviderType = (props) => {
  const { children } = props;

  const [state, dispatch] = useReducer<Reducer>(reducer, initialValue);

  return (
    <NotificationsContext.Provider value={{ state, dispatch }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export { NotificationsContext, NotificationsContextProvider };
export type { NotificationsContextProviderType };
