import { CampaignPlatform } from 'GraphTypes/CampaignInfo_campaign.graphql';
import {
  CreatorImportPlatform,
  OutreachTransport,
} from 'GraphTypes/ImportOutreachBatchQuery.graphql';
import { ParseResult } from 'papaparse';
import { createContext } from 'react';

type InviteOutreachCreatorsContextType = {
  source: InviteOutreachCreatorsSource | null;
  fileName: string;
  fileSize: number;
  fileError: string;
  locale: string;
  selectedLists: InviteOutreachCreatorsSelectedLists;
  rows: InviteOutreachImportCsvDataRow[];
  templateData: InviteOutreachTemplateData;
  transport: OutreachTransport;
  senderName: string;
  senderEmail: string;
  batchId: string;
  error: InviteOutreachCreatorsError | null;
  templatePreview: InviteOutreachTemplateItemData | null;
  templateEditor: boolean;
  platform: CampaignPlatform;
  isUserNeedToChangePlatform: boolean;
  supportedPlatformList: CreatorImportPlatform[];
  handleTemplateClose: () => void;
  handleFileReset: () => void;
  handleListChange: (files: FileList | null) => void;
  handleParseComplete: (result: ParseResult<string>) => void;
  handleImportOutreachBatch: (campaignId: string, platform?: CampaignPlatform | null) => void;
  isFormValid: boolean;
  importOutreachBatchLoading: boolean;
  createOutreachBatchBySearchLoading: boolean;
  processing: boolean;
  showRowErrors: boolean;
  rowMetrics?: InviteOutreachImportCsvDataRowsMetrics;
  rowErrors?: InviteOutreachImportCsvDataErrorRow[];
  setTemplateData: (data: InviteOutreachTemplateData) => void;
  setTemplatePreview: (data: InviteOutreachTemplateItemData | null) => void;
  setTransportProvider: (provider: OutreachTransport) => void;
  setSenderName: (name: string) => void;
  setSenderActiveAuth: (authId: string) => void;
  setSenderEmail: (email: string) => void;
  setError: (error: InviteOutreachCreatorsError | null) => void;
  setShowRowErrors: (error: boolean) => void;
  setTemplateEditorStatus: (status: boolean) => void;
  setSource: (source: InviteOutreachCreatorsSource) => void;
  setPlatform: (platform: CampaignPlatform) => void;
  setSelectedLists: (lists: InviteOutreachCreatorsSelectedLists) => void;
};

export type InviteOutreachTemplateData = {
  id: string;
  name: string;
} & Partial<InviteOutreachTemplateItemData>;

export type InviteOutreachTemplateItemData = {
  subject: string;
  body: string;
  name: string;
};

export type InviteOutreachImportCsvDataRow = {
  username: string;
  email: string;
};

export type InviteOutreachImportCsvDataErrorRow = {
  index: number;
  username?: string;
  email?: string;
};

export type InviteOutreachImportCsvDataRowsMetrics = {
  totalCount: number;
  parsedCount: number;
};

export type InviteOutreachCreatorsTemplate =
  | {
      htmlBody: string;
      id: string;
      locale: string;
      name: string;
      subject: string;
    }
  | undefined
  | null;

export enum InviteOutreachCreatorsError {
  NONE,
  GENERAL_ERROR,
  INVALID_GMAIL_SCOPE,
  FAILED_TO_CREATE_LIST,
}

export enum InviteOutreachCreatorsSource {
  DISCOVERY = 'Discovery',
  LISTS = 'Lists',
  CSV_FILE = 'CSV',
  MANUALLY = 'Manually',
}

export type InviteOutreachCreatorsSelectedLists = string[];

const InviteOutreachCreatorsContext = createContext<InviteOutreachCreatorsContextType>(
  {} as InviteOutreachCreatorsContextType
);

export { InviteOutreachCreatorsContext };
export type { InviteOutreachCreatorsContextType };
