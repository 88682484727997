import React, { useContext } from 'react';
import Handlebars from 'handlebars';

import styles from '../InviteOutreachCreators.pcss';
import { InviteOutreachCreatorsContext } from '../InviteOutreachCreatorsContext';

import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import moment from 'moment';

interface Props {
  variables: TemplateSpecification;
}

const TemplatePreview: React.FC<Props> = (props) => {
  const { variables } = props;
  const { templatePreview, senderName, senderEmail, handleTemplateClose } = useContext(
    InviteOutreachCreatorsContext
  );

  if (!templatePreview) return null;

  const subject = Handlebars.compile(templatePreview.subject);
  const compliledSubject = subject(variables);

  const body = Handlebars.compile(templatePreview.body);
  const compliledBody = body(variables);

  return (
    <>
      <div className={styles.templateDrawer}>
        <div className={styles.previewContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.backContainer}>
              <AlterButton
                icon="Arrow-big-left"
                className={styles.resetTemplate}
                onClick={handleTemplateClose}
              />
              <Text
                type="d2"
                className={styles.title}
                msg="invite_outreach_creators_modal.preview.title"
                formatValues={{
                  templateName: templatePreview.name,
                }}
              />
            </div>
          </div>
          <div className={styles.previewMail}>
            <div className={styles.title}>
              <Text type="h2" className={styles.title} text={compliledSubject} />
            </div>
            <div className={styles.titleContainer}>
              <div>
                <div className={styles.backContainer}>
                  <Text text={`${senderName || 'Rodrigo from Insense'}`} />
                  <Text
                    type="md"
                    className={styles.previewMailSecondaryText}
                    text={`<${senderEmail || 'rodrigo@insense.pro'}>`}
                  />
                </div>
                <Text
                  type="md"
                  className={styles.previewMailSecondaryText}
                  text="to creator@email.com"
                />
              </div>
              <Text
                type="md"
                className={styles.previewMailSecondaryText}
                text={`${moment().format('MMM DD, YYYY, HH:mm')}`}
              />
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: compliledBody }}
              className={styles.templateContent}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplatePreview;
