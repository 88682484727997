import React, { useState, useCallback, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { KeyType } from 'react-relay/relay-hooks/helpers';

import CreatorDetails from '../../../../components/CreatorDetails/CreatorDetails';

import styles from './CustomListCreatorItem.pcss';

import { useCampaignActions } from 'Hooks/useCampaignActions';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { getCreatorProfileLink } from 'Util/links';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { modalName } from 'Types/modals';
import { normalizeFragmentRef } from 'Util/normalizeFragmentRef';
import type {
  CustomListCreatorItem_creator$key,
  CustomListCreatorItem_creator$data,
} from 'GraphTypes/CustomListCreatorItem_creator.graphql';
import { useRemoveCreatorFromCustomListMutation } from 'Mutations/RemoveCreatorFromCustomList.Mutation';
import Icon from 'Components/ui/Icon/Icon';
import { amplitude } from 'Helpers/amplitude';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

interface Props {
  creator: CustomListCreatorItem_creator$key;
  customListId: string;
  customListName: string;
  isBlockedView?: boolean;
  showUnlockCreatorBanner: () => void;
  organizationId?: string;
}

const CustomListCreatorItem: React.FC<Props> = (props) => {
  const {
    creator,
    customListId,
    customListName,
    isBlockedView,
    showUnlockCreatorBanner,
    organizationId,
  } = props;
  const { dispatch: modalDispatch } = useContext(ModalContext);
  const [removeCreatorFromCustomList] = useRemoveCreatorFromCustomListMutation(organizationId);

  const [isRemoveCreatorPending, setIsRemoveCreatorPending] = useState<boolean>(false);

  const { createNewCampaign } = useCampaignActions();

  const data = useFragment<KeyType<CustomListCreatorItem_creator$data>>(
    graphql`
      fragment CustomListCreatorItem_creator on Creator {
        id
        ...CreatorDetails_creator
      }
    `,
    normalizeFragmentRef<CustomListCreatorItem_creator$key>(creator)
  );

  const creatorId = data.id;

  const profileLink = getCreatorProfileLink({ creatorId });

  const handleNewCampaignCreated = () => {
    modalDispatch({
      type: ModalTypes.TOGGLE_MODAL,
    });
  };

  const handleStartNewDeal = useCallback(() => {
    amplitude.sendEvent<278>({
      id: '278',
      category: 'creator_lists',
      name: 'start_deal_click',
      param: {
        creator_id: creatorId,
        list_name: customListName,
      },
    });
    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: {
        name: modalName.START_NEW_DEAL,
        attach: {
          creatorId,
          campaignId: null,
          source: 'creators_space',
          onStartNewCampaignButtonClick: () => {
            amplitude.sendEvent<279>({
              id: '279',
              category: 'creator_lists',
              name: 'start_deal_new_campaign_click',
              param: {
                creator_id: creatorId,
                list_name: customListName,
              },
            });
            createNewCampaign({ onSuccess: handleNewCampaignCreated });
          },
          onAddToCampaignButtonClick: () => {
            amplitude.sendEvent<280>({
              id: '280',
              category: 'creator_lists',
              name: 'start_deal_existing_campaign_click',
              param: {
                creator_id: creatorId,
                list_name: customListName,
              },
            });
          },
        },
      },
    });
  }, [creatorId, customListName]);

  const handleProfileClick = useCallback(() => {
    amplitude.sendEvent<277>({
      id: '277',
      category: 'creator_lists',
      name: 'view_profile_click',
      param: {
        creator_id: creatorId,
        list_name: customListName,
      },
    });
  }, [creatorId, customListName]);

  const handleRemoveButtonClick = useCallback(() => {
    if (isRemoveCreatorPending) return;
    amplitude.sendEvent<281>({
      id: '281',
      category: 'creator_lists',
      name: 'creator_removed_lists',
      param: {
        creator_id: creatorId,
        list_name: customListName,
      },
    });
    setIsRemoveCreatorPending(true);
    removeCreatorFromCustomList({
      variables: {
        input: {
          creatorId,
          customListId,
        },
      },
      onCompleted: () => {
        setIsRemoveCreatorPending(false);
      },
      onError: () => {
        setIsRemoveCreatorPending(false);
      },
    });
  }, [isRemoveCreatorPending, creatorId, customListId, customListName]);

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.creator}>
          <CreatorDetails
            creator={data}
            isBlockedView={isBlockedView}
            showUnlockCreatorBanner={showUnlockCreatorBanner}
          />
        </div>
      </div>
      <div className={styles.controls}>
        <Tooltip
          id="favorite_view_button_tooltip"
          tooltipMsg="creators.dashboard.customLists.view_profile"
          place="top-start"
          className={styles.tooltip}
        >
          <SmartLink path={profileLink} target="_blank">
            <AlterButton icon="Profile" type="grey" onClick={handleProfileClick} />
          </SmartLink>
        </Tooltip>
        <Tooltip
          id="favorite_new_deal_button_tooltip"
          tooltipMsg="creators.dashboard.favorites.start_new_deal"
          place="top-start"
          className={styles.tooltip}
        >
          <AlterButton icon="Add-plus-circle" type="grey" onClick={handleStartNewDeal} />
        </Tooltip>
        <Tooltip
          id="favorite_button_tooltip"
          tooltipMsg="creators.dashboard.favorites.favorite_button_tooltip"
          place="top-start"
          className={styles.tooltip}
        >
          <AlterButton
            type="grey"
            rightElement={<Icon name="Trash-delele" size={24} />}
            onClick={handleRemoveButtonClick}
          />
        </Tooltip>
      </div>
    </div>
  );
};
export default CustomListCreatorItem;
