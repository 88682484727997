import React, { useState, useCallback, MouseEvent, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { KeyType } from 'react-relay/relay-hooks/helpers';

import CreatorDetails from '../../../../components/CreatorDetails/CreatorDetails';

import styles from './Creator.pcss';

import { useCampaignActions } from 'Hooks/useCampaignActions';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { getCreatorProfileLink } from 'Util/links';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { modalName } from 'Types/modals';
import { normalizeFragmentRef } from 'Util/normalizeFragmentRef';
import type { Creator_creator$key, Creator_creator$data } from 'GraphTypes/Creator_creator.graphql';
import removeCreatorFromFavorites, {
  RemoveCreatorFromFavoritesMutation,
} from 'Mutations/RemoveCreatorFromFavorites.Mutation';
import { promisifyMutation } from 'Util/promisifyMutation';
import Icon from 'Components/ui/Icon/Icon';
import { amplitude } from 'Helpers/amplitude';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

interface Props {
  creator: Creator_creator$key;
  organizationId?: string;
  isBlockedView?: boolean;
  showUnlockCreatorBanner: () => void;
}

const Creator: React.FC<Props> = (props) => {
  const { creator, organizationId = '', isBlockedView, showUnlockCreatorBanner } = props;

  const { dispatch: modalDispatch } = useContext(ModalContext);

  const { createNewCampaign } = useCampaignActions();

  const [
    isRemoveCreatorFromFavoritesMutationPending,
    setIsRemoveCreatorFromFavoritesMutationPending,
  ] = useState<boolean>(false);

  const data = useFragment<KeyType<Creator_creator$data>>(
    graphql`
      fragment Creator_creator on Creator {
        id
        ...CreatorDetails_creator
      }
    `,
    normalizeFragmentRef<Creator_creator$key>(creator)
  );
  if (!data) return null;

  const creatorId = data.id;

  const profileLink = getCreatorProfileLink({ creatorId });

  const handleNewCampaignCreated = () => {
    modalDispatch({
      type: ModalTypes.TOGGLE_MODAL,
    });
  };

  const handleStartNewDeal = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      amplitude.sendEvent<265>({
        id: '265',
        category: 'favorites',
        name: 'start_deal_click',
        param: {
          creator_id: creatorId,
        },
      });
      modalDispatch({
        type: ModalTypes.SET_MODAL,
        payload: {
          name: modalName.START_NEW_DEAL,
          attach: {
            creatorId,
            campaignId: null,
            source: 'creators_space',
            onStartNewCampaignButtonClick: () => {
              amplitude.sendEvent<266>({
                id: '266',
                category: 'favorites',
                name: 'start_deal_new_campaign_click',
                param: {
                  creator_id: creatorId,
                },
              });
              createNewCampaign({ onSuccess: handleNewCampaignCreated });
            },
            onAddToCampaignButtonClick: () => {
              amplitude.sendEvent<267>({
                id: '267',
                category: 'favorites',
                name: 'start_deal_existing_campaign_click',
                param: {
                  creator_id: creatorId,
                },
              });
            },
          },
        },
      });
    },
    [organizationId, creatorId]
  );

  const handleProfileClick = useCallback(() => {
    amplitude.sendEvent<264>({
      id: '264',
      category: 'favorites',
      name: 'view_profile_click',
      param: {
        creator_id: creatorId,
      },
    });
  }, [organizationId, creatorId]);

  const handleFavoriteClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (isRemoveCreatorFromFavoritesMutationPending) return;
      amplitude.sendEvent<268>({
        id: '268',
        category: 'favorites',
        name: 'creator_removed_favorites',
        param: {
          creator_id: creatorId,
        },
      });
      const mutation = promisifyMutation<RemoveCreatorFromFavoritesMutation>(
        removeCreatorFromFavorites
      );
      setIsRemoveCreatorFromFavoritesMutationPending(true);
      mutation({ creatorId, organizationId }).finally(() => {
        setIsRemoveCreatorFromFavoritesMutationPending(false);
      });
    },
    [isRemoveCreatorFromFavoritesMutationPending, creatorId, organizationId]
  );

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.creator}>
          <CreatorDetails
            creator={data}
            isBlockedView={isBlockedView}
            showUnlockCreatorBanner={showUnlockCreatorBanner}
          />
        </div>
      </div>
      <div className={styles.controls}>
        <Tooltip
          id="favorite_view_button_tooltip"
          tooltipMsg="creators.dashboard.customLists.view_profile"
          place="top-start"
          className={styles.tooltip}
        >
          <SmartLink path={profileLink} target="_blank">
            <AlterButton icon="Profile" type="grey" onClick={handleProfileClick} />
          </SmartLink>
        </Tooltip>
        <Tooltip
          id="favorite_new_deal_button_tooltip"
          tooltipMsg="creators.dashboard.favorites.start_new_deal"
          place="top-start"
          className={styles.tooltip}
        >
          <AlterButton icon="Add-plus-circle" type="grey" onClick={handleStartNewDeal} />
        </Tooltip>
        <Tooltip
          id="favorite_button_tooltip"
          tooltipMsg="creators.dashboard.favorites.favorite_button_tooltip"
          place="top-start"
          className={styles.tooltip}
        >
          <AlterButton
            type="grey"
            rightElement={<Icon name="Trash-delele" size={24} />}
            onClick={handleFavoriteClick}
          />
        </Tooltip>
      </div>
    </div>
  );
};
export default Creator;
