/**
 * @generated SignedSource<<48892d5e18bf6939f4462f193939b10e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MessageEvent_event$data = {
  readonly __typename: string;
  readonly actor: {
    readonly __typename: string;
    readonly id: string;
    readonly logo?: {
      readonly transformations?: {
        readonly brandLogoUrl: string;
      } | null | undefined;
    } | null | undefined;
    readonly profile?: {
      readonly profilePictureFile: {
        readonly transformations?: {
          readonly collageThumbnailUrl: string;
        } | null | undefined;
        readonly url: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly attachments?: {
    readonly totalCount: number;
  } | null | undefined;
  readonly createdAt: any;
  readonly deletedAt?: any | null | undefined;
  readonly id: string;
  readonly readByAdvertiser: boolean;
  readonly readByContractor: boolean;
  readonly text?: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"MessageAttachments_message">;
  readonly " $fragmentType": "MessageEvent_event";
};
export type MessageEvent_event$key = {
  readonly " $data"?: MessageEvent_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"MessageEvent_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CreatorProfile",
    "kind": "LinkedField",
    "name": "profile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "profilePictureFile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ImageTransformation",
                "kind": "LinkedField",
                "name": "transformations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "collageThumbnailUrl",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Image",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MessageEvent_event",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readByAdvertiser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readByContractor",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MessageAttachments_message"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MessageAttachmentConnection",
          "kind": "LinkedField",
          "name": "attachments",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deletedAt",
          "storageKey": null
        }
      ],
      "type": "Message",
      "abstractKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "actor",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "InstagramCreator",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "YoutubeCreator",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "TiktokCreator",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "logo",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ImageTransformation",
                      "kind": "LinkedField",
                      "name": "transformations",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "brandLogoUrl",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "Image",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Brand",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectEvent",
  "abstractKey": "__isProjectEvent"
};
})();

(node as any).hash = "ebdea61a4155a16ea74cbc05408df688";

export default node;
