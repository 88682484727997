import React, { Suspense } from 'react';
import { useIntl } from 'react-intl';

import styles from './MetaAdsPromo.pcss';

import Text from 'Components/ui/Text/Text';
import postImg from 'Images/ads_promo/meta/post.png';
import post2xImg from 'Images/ads_promo/meta/post_2x.png';
import post2Img from 'Images/ads_promo/meta/post2.png';
import post2_2xImg from 'Images/ads_promo/meta/post2_2x.png';
import exampleImg from 'Images/ads_promo/meta/example.png';
import example2xImg from 'Images/ads_promo/meta/example_2x.png';
import connectImg from 'Images/ads_promo/meta/connect.png';
import connect2xImg from 'Images/ads_promo/meta/connect_2x.png';
import connectEasilyImg from 'Images/ads_promo/meta/connect_easily.png';
import connectEasily2xImg from 'Images/ads_promo/meta/connect_easily_2x.png';
import helpImg from 'Images/ads_promo/meta/help.png';
import help2xImg from 'Images/ads_promo/meta/help_2x.png';
import Icon from 'Components/ui/Icon/Icon';
import TextButton from 'Components/ui/TextButton/TextButton';
import BookACallCalendar from 'Molecules/BookACallCalendar/BookACallCalendar';
import CreatorForNewUseCase from 'Molecules/CreatorForNewUseCase/CreatorForNewUseCase';

interface Props {
  creatorId?: string;
  showBookCall?: boolean;
  onBookCallClick: () => void;
}

const MetaAdsPromoContent: React.FC<Props> = (props) => {
  const { creatorId, showBookCall, onBookCallClick } = props;

  const intl = useIntl();

  const calendar = (
    <div>
      <Text type="d2" msg="creator_ads_meta_info.book_call" className={styles.benefitTitle} />
      <BookACallCalendar />
    </div>
  );

  if (showBookCall) {
    return calendar;
  }

  const handleBookCallClick = () => {
    onBookCallClick();
  };

  return (
    <div>
      {creatorId && (
        <div>
          <Text type="d2" msg="creator_ads_promo.ig.title" className={styles.title} />
          <Suspense fallback={null}>
            <CreatorForNewUseCase creatorId={creatorId} />
          </Suspense>
        </div>
      )}
      {!creatorId && <Text type="d2" msg="creator_ads_meta_info.title" className={styles.title} />}
      <div className={styles.benefit1}>
        <Text
          type="h1"
          msg="creator_ads_meta_info.showcase.title"
          className={styles.benefitTitle}
        />
        <Text
          type="md"
          msg="creator_ads_meta_info.showcase.descr"
          className={styles.benefitDescr}
        />
        <div className={styles.images}>
          <div>
            <img srcSet={`${postImg} 1x, ${post2xImg} 2x`} />
            <Text
              type="md"
              msg="creator_ads_meta_info.showcase.before"
              className={styles.imageTitle}
            />
          </div>
          <div>
            <img srcSet={`${post2Img} 1x, ${post2_2xImg} 2x`} />
            <Text
              type="md"
              msg="creator_ads_meta_info.showcase.new_wl_ads"
              className={styles.imageTitle}
            />
          </div>
        </div>
      </div>
      <div className={styles.spacer} />
      <div className={styles.benefit2}>
        <Text
          type="h1"
          msg="creator_ads_meta_info.increase.title"
          className={styles.benefitTitle}
        />
        <div className={styles.adsBenefit}>
          <Icon name="Compass-explore" className={styles.blue} />
          <div>
            <Text
              type="md"
              msg="creator_ads_meta_info.increase.social_proof.title"
              className={styles.blue}
            />
            <Text type="md" msg="creator_ads_meta_info.increase.social_proof.descr" />
          </div>
        </div>
        <div className={styles.adsBenefit}>
          <Icon name="Group-users" className={styles.pink} />
          <div>
            <Text
              type="md"
              msg="creator_ads_meta_info.increase.boost.title"
              className={styles.pink}
            />
            <Text type="md" msg="creator_ads_meta_info.increase.boost.descr" />
          </div>
        </div>
        <div className={styles.adsBenefit}>
          <Icon name="Badge-top-ratad-stroke" className={styles.green} />
          <div>
            <Text
              type="md"
              msg="creator_ads_meta_info.increase.connect.title"
              className={styles.green}
            />
            <Text type="md" msg="creator_ads_meta_info.increase.connect.descr" />
          </div>
        </div>
      </div>
      <div className={styles.spacer} />
      <div className={styles.benefit3}>
        <img srcSet={`${exampleImg} 1x, ${example2xImg} 2x`} />
        <div>
          <Text
            type="h1"
            msg="creator_ads_meta_info.example.title"
            className={styles.benefitTitle}
          />
          <Text
            type="label"
            msg="creator_ads_meta_info.example.descr"
            className={styles.benefitDescr}
          />
          <div className={styles.exampleItem}>
            <Text
              type="md"
              msg="creator_ads_meta_info.example.item_1.title"
              className={styles.purple}
            />
            <Text type="label" msg="creator_ads_meta_info.example.item_1.descr" />
          </div>
          <div className={styles.exampleItem}>
            <Text
              type="md"
              msg="creator_ads_meta_info.example.item_2.title"
              className={styles.purple}
            />
            <Text type="label" msg="creator_ads_meta_info.example.item_2.descr" />
          </div>
          <div className={styles.exampleItem}>
            <Text
              type="md"
              msg="creator_ads_meta_info.example.item_3.title"
              className={styles.purple}
            />
            <Text type="label" msg="creator_ads_meta_info.example.item_3.descr" />
          </div>
          <div className={styles.link}>
            <Text type="label" msg="creator_ads_meta_info.example.result" />
            <TextButton
              msg="creator_ads_meta_info.book_call"
              color="purple"
              size="xs"
              onClick={handleBookCallClick}
            />
          </div>
        </div>
      </div>
      <div className={styles.spacer} />
      <div className={styles.benefit4}>
        <div>
          <Text
            type="h1"
            msg="creator_ads_meta_info.connect.title"
            className={styles.benefitTitle}
          />
          <Text type="md" msg="creator_ads_meta_info.connect.descr" className={styles.grey} />
        </div>
        <img srcSet={`${connectImg} 1x, ${connect2xImg} 2x`} />
      </div>
      <div className={styles.benefit5}>
        <img srcSet={`${connectEasilyImg} 1x, ${connectEasily2xImg} 2x`} />
        <div>
          <Text
            type="h1"
            msg="creator_ads_meta_info.easily_connect.title"
            className={styles.benefitTitle}
          />
          <Text
            type="md"
            msg="creator_ads_meta_info.easily_connect.descr"
            className={styles.grey}
          />
        </div>
      </div>
      <div className={styles.benefi6}>
        <div>
          <Text type="h1" msg="creator_ads_meta_info.helps.title" className={styles.benefitTitle} />
          <TextButton
            size="m"
            msg="creator_ads_meta_info.book_call"
            color="purple"
            className={styles.link}
            onClick={handleBookCallClick}
          />
        </div>
        <img srcSet={`${helpImg} 1x, ${help2xImg} 2x`} className={styles.image} />
      </div>
      <div className={styles.spacer} />
    </div>
  );
};

export default MetaAdsPromoContent;
