import React, { useContext } from 'react';

import styles from './CreatorsSourceSelector.pcss';
import {
  InviteOutreachCreatorsContext,
  InviteOutreachCreatorsSource,
} from '../InviteOutreachCreatorsContext';

import Text from 'Components/ui/Text/Text';
import RadioButton from 'Components/ui/RadioButton/RadioButton';
import { amplitude } from 'Helpers/amplitude';

interface Props {}

const sourceList = [
  // InviteOutreachCreatorsSource.DISCOVERY,
  InviteOutreachCreatorsSource.LISTS,
  InviteOutreachCreatorsSource.CSV_FILE,
  InviteOutreachCreatorsSource.MANUALLY,
];

const CreatorsSourceSelector: React.FC<Props> = (props) => {
  const { source, setSource, handleFileReset } = useContext(InviteOutreachCreatorsContext);

  const handleSourceSelect = (type: InviteOutreachCreatorsSource) => {
    handleFileReset();
    setSource(type);
    amplitude.sendEvent<441>({
      id: '441',
      category: 'campaign',
      name: 'outreach_select_source',
      param: {
        source: type,
      },
    });
  };

  return (
    <>
      <Text type="md" msg="invite_outreach_creators_modal.step_0" />
      <div className={styles.sourceList}>
        {sourceList.map((type) => (
          <div
            className={styles.sourceItem}
            onClick={() => {
              handleSourceSelect(type);
            }}
          >
            <RadioButton checked={source === type} />
            <Text type="md" msg={`invite_outreach_creators_modal.source.${type.toLowerCase()}`} />
          </div>
        ))}
      </div>
    </>
  );
};

export default CreatorsSourceSelector;
