import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useLazyLoadQuery, graphql } from 'react-relay';

import styles from './Home.pcss';
import OldCases from './OldCases/OldCases';
import NewCases from './NewCases/NewCases';

import ls from 'Util/localStorage';
import Text from 'Components/ui/Text/Text';
import Page from 'Templates/Page/Page';
import ButtonPreset from 'Components/ui/ButtonPreset/ButtonPreset';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { PLANS_ROUTE } from 'Constants/general';
import { amplitude } from 'Helpers/amplitude';
import { HomeQuery as QueryType } from 'GraphTypes/HomeQuery.graphql';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';

export const HomeQuery = graphql`
  query HomeQuery {
    currentUser {
      organization {
        newClient
        subscription {
          availableCampaignUseCases
        }
      }
    }
  }
`;

const Home = () => {
  const { hasFeatureFlag } = useContext(FeatureFlagsContext);
  const newUseCases = hasFeatureFlag('use_cases_5389');

  const warningVisibleDeafult = !ls.get('home_browse_plans_warning');

  const [warningVisible, setWarningVisible] = useState(warningVisibleDeafult);

  useEffect(() => {
    amplitude.sendEvent<360>({
      id: '360',
      category: 'home',
      name: 'pageview',
      param: undefined,
    });
  }, []);

  const { currentUser } = useLazyLoadQuery<QueryType>(HomeQuery, {});

  const isNewClient = currentUser?.organization?.newClient;
  const subscription = currentUser?.organization?.subscription;

  const handleWarningClose = () => {
    setWarningVisible(false);
    ls.set('home_browse_plans_warning', true);
  };

  const handlePlanClick = () => {
    amplitude.sendEvent<347>({
      id: '347',
      category: 'home',
      name: 'select_plan_click',
      param: undefined,
    });
  };

  return (
    <Page className={styles.root}>
      <div className={styles.container}>
        {newUseCases ? <NewCases subscription={subscription} /> : <OldCases />}
        {warningVisible && isNewClient && (
          <div className={styles.warningWrap}>
            <div className={styles.warning}>
              <Text type="md" msg="home.videos.browse_plans.descr" />
              <ButtonPreset className={styles.controls}>
                <Link to={PLANS_ROUTE} onClick={handlePlanClick}>
                  <AlterButton type="white" msg="general.browse_plans" className={styles.control} />
                </Link>
                <AlterButton
                  type="white"
                  icon="Close-small"
                  className={styles.closeWraning}
                  onClick={handleWarningClose}
                />
              </ButtonPreset>
            </div>
          </div>
        )}
      </div>
    </Page>
  );
};

export default Home;
