import React, {
  ChangeEvent,
  DetailedHTMLProps,
  FC,
  LabelHTMLAttributes,
  PropsWithChildren,
  useContext,
} from 'react';
import classNames from 'classnames';

import styles from './UploadFile.pcss';

import Text from 'Atoms/Text/Text';
import Spinner from 'Atoms/Spinner/Spinner';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

interface Props {
  name: string;
  result: string;
  msg: string;
  ratio: number;
  className: string;
  style: DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>;
  types: string;
  multiple: boolean;
  plain: boolean;
  loading: boolean;
  callback: () => void;
  onImageUploaded: (files: File | string) => void;
  loadImage: (files: File[]) => void;
  filesLoaded: (files: File[]) => void;
}

const UploadFile: FC<PropsWithChildren<Props>> = (props) => {
  const {
    result,
    onImageUploaded,
    loadImage,
    filesLoaded,
    msg,
    callback,
    className,
    style,
    types,
    multiple,
    plain,
    children,
    loading,
  } = props;

  const { dispatch: modalDispatch } = useContext(ModalContext);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      return;
    }

    // TODO: refactor upload result action

    if (result && result === 'upload') {
      onImageUploaded(files[0]);
    } else if (result === 'upload2') {
      const fileList = [];
      for (let i = 0; i < files.length; i++) {
        fileList.push(files[i]);
      }
      loadImage(fileList);
    } else if (result === 'upload3') {
      filesLoaded([...files]);
    } else if (result && result === 'crop') {
      const src = URL.createObjectURL(files[0]);
      modalDispatch({
        type: ModalTypes.SET_MODAL,
        payload: { name: 'cropper', attach: { src, msg, callback } },
      });
    } else if (result && result === 'crop2') {
      const src = URL.createObjectURL(files[0]);
      onImageUploaded(src);
    }

    // Reset input to allow multiple uploads of the same file
    e.target.value = '';
  };

  const typeList = types ? types : 'image/*';

  if (plain) {
    return (
      <div className={classNames(styles.plain, className)}>
        {children}
        <label className={styles.label} style={style}>
          <input
            className={styles.input}
            type="file"
            multiple={multiple}
            accept={typeList}
            onChange={onChange}
            disabled={loading}
          />
        </label>
      </div>
    );
  }

  return (
    <div className={`${className} ${styles.root}`}>
      <div className={styles.descr}>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <Text size="sm" color="grayDog" msg="drag_drop.title" />
            <Text size="sm" color="base" msg="drag_drop.title2" />
          </>
        )}
      </div>
      <label className={styles.label} style={style}>
        <input
          className={styles.input}
          type="file"
          multiple={multiple}
          accept={typeList}
          onChange={onChange}
          disabled={loading}
        />
      </label>
    </div>
  );
};

export default UploadFile;
