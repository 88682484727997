import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { amplitude } from 'Helpers/amplitude';
import { createSum } from 'Util/numberFormatter';
import Button from 'Atoms/Button/Button';
import Icon from 'Components/ui/Icon/Icon';
import { modalName } from 'Types/modals';
import { BILLING_ROUTE, CONTRACTOR, PLANS_ROUTE } from 'Constants/general';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

interface Props {
  balance?: number;
  currency?: string;
  userType?: string;
  newClient?: boolean;
  classes?: {
    button?: string;
  };
}

const Balance: React.FC<Props> = (props) => {
  const { userType, balance, currency, classes, newClient } = props;
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const isContractor = userType === CONTRACTOR;

  const finalBalance = balance && balance < 0 ? 0 : balance;

  const balanceForAdvertiser = newClient
    ? { msg: 'header.browse_plans' }
    : { text: createSum(finalBalance, currency) };
  const linkForAdvertiser = newClient ? PLANS_ROUTE : BILLING_ROUTE;

  const btnData = isContractor ? { msg: 'header.wallet' } : balanceForAdvertiser;

  const handleBalanceClick = () => {
    if (isContractor) {
      modalDispatch({
        type: ModalTypes.SET_MODAL,
        payload: { name: modalName.DOWNLOAD_APP, attach: {} },
      });
    }
    amplitude.sendEvent({
      id: '162',
      category: 'plans_and_billing',
      name: 'wallet_click',
    });
  };

  const btn = <AlterButton icon="Wallet" className={classes?.button} {...btnData} />;

  if (!isContractor) {
    return (
      <Link to={linkForAdvertiser} onClick={handleBalanceClick}>
        {btn}
      </Link>
    );
  }

  return (
    <Button
      color="normal"
      leftIcon={<Icon name="Wallet" />}
      {...btnData}
      onClick={handleBalanceClick}
    />
  );
};

export default Balance;
