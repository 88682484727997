import React from 'react';
import { graphql } from 'relay-runtime';
import { useLazyLoadQuery } from 'react-relay';

import styles from './CreatorForNewUseCase.pcss';

import Image, { ImagePlatform } from 'Components/ui/Image/Image';
import { CreatorForNewUseCaseQuery as QueryType } from 'GraphTypes/CreatorForNewUseCaseQuery.graphql';
import Text from 'Components/ui/Text/Text';
import CreatorBadge from 'Molecules/CreatorBadge/CreatorBadge';
import Tag from 'Components/ui/Tag/Tag';
import { IconName } from 'Components/ui/types';

interface Props {
  creatorId: string;
}

const CreatorForNewUseCaseQuery = graphql`
  query CreatorForNewUseCaseQuery($id: ID!) {
    creator(id: $id) {
      id
      username
      type
      projects(stages: [COMPLETED]) {
        totalCount
      }
      profile {
        badges {
          name
        }
        profilePictureFile {
          url
          ... on Image {
            transformations {
              collageThumbnailUrl
            }
          }
        }
      }
    }
  }
`;

const CreatorForNewUseCase: React.FC<Props> = (props) => {
  const { creatorId } = props;

  const data = useLazyLoadQuery<QueryType>(CreatorForNewUseCaseQuery, { id: creatorId });

  const creator = data.creator;
  const username = creator?.username;
  const avaSrc = creator?.profile?.profilePictureFile?.transformations?.collageThumbnailUrl;
  const type = creator?.type;
  const badges = creator?.profile?.badges;
  const finishedDeals = creator?.projects.totalCount;

  const tags =
    type === 'TIKTOK'
      ? [
          { icon: 'Group-users', msg: 'dashboard.creator_ads_tiktok_info.benefit_3' },
          { icon: 'Thumbs-up-accept', msg: 'dashboard.creator_ads_tiktok_info.benefit_1' },
          { icon: 'Analytics', msg: 'dashboard.creator_ads_tiktok_info.benefit_2' },
        ]
      : [
          { icon: 'Thumbs-up-accept', msg: 'dashboard.creator_ads_meta_info.benefit_1' },
          { icon: 'Heart', msg: 'dashboard.creator_ads_meta_info.benefit_2' },
          { icon: 'target-bullseye', msg: 'dashboard.creator_ads_meta_info.benefit_3' },
        ];

  const descr = type === 'TIKTOK' ? 'creator_ads_promo.tiktok.descr' : 'creator_ads_promo.ig.descr';

  return (
    <div className={styles.root}>
      <div className={styles.creator}>
        <Image size={60} type="round" src={avaSrc} platform={type as ImagePlatform} />
        <div className={styles.details}>
          <Text type="md" text={username} />
          {badges?.map((badge) => (
            <CreatorBadge
              key={badge.name}
              badge={badge.name}
              withText={false}
              className={styles.badge}
            />
          ))}
        </div>
        <Text
          type="label"
          msg="creator_ads_promo.creator.finished_deals"
          formatValues={{ count: finishedDeals }}
          className={styles.deals}
        />
      </div>
      <Text type="md" msg={descr} className={styles.descr} />
      <div className={styles.tags}>
        {tags.map((tag) => (
          <Tag
            key={tag.msg}
            size="m"
            icon={tag.icon as IconName}
            msg={tag.msg}
            color="pink"
            needMargin={false}
            className={styles.tag}
          />
        ))}
      </div>
    </div>
  );
};

export default CreatorForNewUseCase;
