import React, { MouseEventHandler } from 'react';
import classnames from 'classnames';

import IconNew from '../Icon/Icon';
import Text, { Props as TextProps } from '../Text/Text';
import { Color, IconName, TextType } from '../types';

import styles from './TextButton.pcss';
import Spinner from 'Atoms/Spinner/Spinner';

type ButtonDisableState = boolean;
type ButtonText = string | null;
type ButtonIcon = IconName | null;
type ButtonLocaleMessage = string;
type ButtonFluid = boolean;
type ButtonIconPosition = 'left' | 'right';
type ButtonActiveState = boolean;
type ButtonHover = boolean;
type ButtonSize = 's' | 'm' | 'xs';

type TextSizeMap = Record<ButtonSize, TextType>;

type Props = {
  size?: ButtonSize;
  disabled?: ButtonDisableState;
  text?: ButtonText;
  icon?: ButtonIcon;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  msg?: ButtonLocaleMessage;
  msgValues?: TextProps['formatValues'];
  fluid?: ButtonFluid;
  iconPosition?: ButtonIconPosition;
  active?: ButtonActiveState;
  className?: string;
  color?: Color;
  classes?: {
    icon?: string;
    text?: string;
  };
  dataTest?: string;
  textByCenter?: boolean;
  loading?: boolean;
};

const TextButton: React.FC<Props> = (props) => {
  const {
    onClick = null,
    text,
    icon,
    size = 's',
    msg,
    msgValues,
    fluid = false,
    iconPosition = 'left',
    active,
    className,
    disabled,
    color,
    classes,
    dataTest,
    textByCenter,
    loading,
  } = props;

  const sizeStyle = styles[size];

  const classNameList = classnames(
    styles.root,
    className,
    sizeStyle,
    {
      [styles.fluid]: fluid,
      [styles.active]: active,
    },
    styles[color || 'dark']
  );

  const textSizeMapping: TextSizeMap = {
    s: 'md',
    xs: 's',
    m: 'button',
  };

  const iconSizeMapping = {
    s: 24,
    xs: 16,
    m: 24,
  };

  const iconEl = icon ? (
    <IconNew size={iconSizeMapping[size]} name={icon} className={classes?.icon} />
  ) : undefined;

  return (
    <button
      {...(onClick ? { onClick } : {})}
      data-test={dataTest}
      className={classNameList}
      disabled={disabled}
    >
      {loading && <Spinner size="xs" />}
      {!loading && icon && iconPosition === 'left' && iconEl}
      {(msg || text) && (
        <Text
          type={textSizeMapping[size]}
          msg={msg}
          formatValues={msgValues}
          className={classnames(styles.buttonText, classes?.text, {
            [styles.buttonTextByCenter]: textByCenter,
          })}
        >
          {text}
        </Text>
      )}
      {!loading && icon && iconPosition === 'right' && iconEl}
    </button>
  );
};

export default TextButton;
