import React, { PropsWithChildren, useContext } from 'react';
import track, { useTracking } from 'react-tracking';

import { encodeQueryData } from 'Util/encodeQueryData';
import { OAUTH_PROXY_URL, FB_OAUTH_API } from 'Constants/general';
import { modalName } from 'Types/modals';
import { failData } from 'Constants/messageModal';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

const isDev = process.env.NODE_ENV === 'development';

interface Props {
  additionalPermissions?: string[];
  callback: (socialIdentityId?: string) => void;
  onCheckingConnectionPossibility?: () => boolean;
}

const ConnectFb: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, callback, onCheckingConnectionPossibility, additionalPermissions } = props;

  const tracking = useTracking();
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const additionalPermissionsValue = additionalPermissions
    ? `,${additionalPermissions.join(',')}`
    : '';

  const handleConnectClick = () => {
    const isConnectionPossible = onCheckingConnectionPossibility?.() ?? true;
    if (!isConnectionPossible) return;

    if (isDev) {
      callback();
      return;
    }

    tracking.trackEvent({ event: 'click' });
    const params = {
      redirect_uri: `${OAUTH_PROXY_URL}`,
      client_id: process.env.FB_APP_ID,
      response_type: 'token',
      scope: `email,pages_read_engagement,pages_show_list,business_management,ads_management,instagram_basic,instagram_manage_insights,pages_manage_metadata${additionalPermissionsValue}`,
      state: `origin=${location.origin}&route=/auth`,
    };
    const url = `${FB_OAUTH_API}${encodeQueryData(params)}`;
    window.callSuccess = (socialIdentityId: string) => {
      callback(socialIdentityId);
    };
    window.callFail = () => {
      modalDispatch({
        type: ModalTypes.SET_MODAL,
        payload: { name: modalName.MESSAGE, attach: { ...failData } },
      });
    };
    window.open(url, 'auth', 'width=500,height=500');
  };

  return React.cloneElement(children as React.ReactElement<any>, { onClick: handleConnectClick });
};

export default track({
  element: 'add_to_facebook',
})(ConnectFb);
