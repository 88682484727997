import React from 'react';

import styles from './AdminAdditionalControls.css';

import Button from 'Atoms/Button/Button';

interface Props {
  campaignId: string;
  hasMore?: boolean;
  onClickNext?: () => void;
}

const AdminAdditionalControls: React.FC<Props> = (props) => {
  const { onClickNext, hasMore } = props;

  return (
    <div className={styles.root}>
      {hasMore && (
        <Button
          color="normal"
          msg="general.show_more"
          size="lg"
          className={styles.button}
          onClick={onClickNext}
        />
      )}
    </div>
  );
};

export default AdminAdditionalControls;
