import type { EventData, SendEventType } from './amplitude.types';

import ls from 'Util/localStorage';

const dataLayer = (window as any).dataLayer || [];
import * as amplitudeSdk from '@amplitude/analytics-browser';
import { v4 as uuid } from 'uuid';

type Amplitude = {
  sendEvent: SendEventType;
  reset: () => void;
};
const amplitude: Amplitude = {
  sendEvent: (data) => {
    const { id, category, name, param } = data;
    amplitudeSdk.logEvent(`web_${category}_${name}`, param);
    if (ls.get('admin') === true) {
      console.info(
        `Sended amplitude event: %c${category}_${name} [${id}]`,
        'font-weight: bold',
        param || ''
      );
    }
  },
  reset: () => {
    if (!amplitudeSdk) return;
    amplitudeSdk.setUserId(undefined);
    amplitudeSdk.setDeviceId(uuid());
  },
};

export { amplitude };
