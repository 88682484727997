import { graphql, requestSubscription } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import { Primitive } from 'relay-runtime/lib/store/RelayStoreTypes';

import environment from 'Api/Environment';
import { PRICE_CHANGE_EVENT, DEADLINE_EVENT } from 'Constants/general';
import { ProjectEventCreatedSubscription } from 'GraphTypes/ProjectEventCreatedSubscription.graphql';
import { OFFER, OFFER_READ_MARK } from 'Types/enums';
import { chatListFilters } from 'Api/mutations/MarkProjectEventsAsRead.Mutation';

const subscription = graphql`
  subscription ProjectEventCreatedSubscription {
    projectEventCreated {
      project {
        id
        advertiserStage
        contentReview {
          status
        }
        events {
          unreadCount
        }
        publicationEventExists
        lastEvent: events(
          last: 1
          types: [
            MESSAGE
            OFFER_ACCEPTANCE
            PRICE_CHANGE
            PRICE_CHANGE_REQUEST
            PRICE_CHANGE_REQUEST_REJECTION
            PRICE_CHANGE_REQUEST_ACCEPTANCE
            PRODUCT_SHIPMENT_COMPLETION
            CONTENT_REVIEW_FAIL
            CONTENT_REVIEW_PASS
            PUBLICATION_EVENT
            PUBLICATION_REQUEST
            DEADLINE_CHANGE_REQUEST
            EXTRA_PAYOUT
            DEADLINE
            PUBLICATION_DEADLINE_CHANGE
            LAUNCH
            ARCHIVATION
            DEADLINE_CHANGE_REQUEST_ACCEPTANCE
            CONTENT_SUBMISSION
            CONTENT_REVIEW_FAIL
          ]
        ) {
          unreadCount
          edges {
            node {
              __typename
              createdAt
              readByContractor
              ... on Message {
                text
                attachments {
                  edges {
                    node {
                      file {
                        __typename
                        type
                        id
                      }
                      id
                      __typename
                    }
                    cursor
                  }
                  pageInfo {
                    endCursor
                    hasNextPage
                  }
                }
              }
              ... on OfferAcceptance {
                comment
              }
              actor {
                __typename
                id
              }
              id
            }
          }
        }
        campaign {
          id
          organization {
            id
          }
          publishingRequired
          counters {
            unreadProjectEventsExcludingOfferAcceptance
          }
          acceptedProjects: projects(stages: [ACCEPTED], archived: false, withUnreadEvents: true) {
            totalCount
          }
          launchedProjects: projects(stages: [LAUNCHED], archived: false, withUnreadEvents: true) {
            totalCount
          }
          contentSubmittedProjects: projects(
            stages: [CONTENT_SUBMITTED]
            archived: false
            withUnreadEvents: true
          ) {
            totalCount
          }
          publishingStartedProjects: projects(
            stages: [PUBLISHING_STARTED]
            archived: false
            withUnreadEvents: true
          ) {
            totalCount
          }
          completedProjects: projects(
            stages: [COMPLETED]
            archived: false
            withUnreadEvents: true
          ) {
            totalCount
          }
        }
        priceChangeRequest {
          id
        }
      }
      newEventEdge {
        node {
          id
          createdAt
          __typename
          actor {
            __typename
            name
            avatarUrl
            ... on Creator {
              review {
                type
              }
            }
          }
          ... on Message {
            text
            attachments {
              edges {
                node {
                  id
                  creative {
                    id
                  }
                  file {
                    duration
                    secureUrl
                    thumbnailUrl
                    type
                    originalFilename
                    filename
                    id
                    publicId
                    bcaTaggedUrl
                    bcaTaggingStatus
                    width
                    height
                    bytes
                    ... on Video {
                      transformations {
                        autoUrl
                        collageThumbnailUrl
                      }
                      processingStatus
                    }
                    ... on Image {
                      transformations {
                        collageThumbnailUrl
                      }
                    }
                  }
                }
              }
            }
          }
          ... on ContentReviewSubmission {
            reviewFiles: attachments {
              edges {
                node {
                  id
                  file {
                    ...MessageMedia_file
                  }
                }
              }
            }
          }
          ... on ContentReviewFail {
            text
          }
          ... on Deadline {
            draftDate
            publicationDate
          }
          ... on DeadlineChangeRequest {
            id
            draftDate
          }
          ... on PublicationDeadlineChange {
            id
            publicationDate
          }
          ... on PriceChange {
            price
            comment
            currency
          }
          ... on PriceChangeRequest {
            requestPrice: price
            requestCurrency: currency
          }
          ... on PriceChangeRequestAcceptance {
            request {
              currency
              price
            }
          }
          ... on ProjectArchivation {
            side
          }
          ... on ProjectArchivationCancellation {
            archivation {
              side
            }
          }
          ... on ExtraPayout {
            amount
            currency
          }
          ... on Reimbursement {
            amount
            currency
          }
          ... on PostPublication {
            id
            readByAdvertiser
            readByContractor
          }
        }
      }
    }
  }
`;

const lastInsertedEventMap: string[] = [];

const counterProjectStages = [
  'ACCEPTED',
  'LAUNCHED',
  'CONTENT_SUBMITTED',
  'PUBLISHING_STARTED',
  'COMPLETED',
];

export default () => {
  requestSubscription<ProjectEventCreatedSubscription>(environment, {
    subscription,
    variables: {},
    updater: (store) => {
      const event = store
        .getRootField('projectEventCreated')
        .getLinkedRecord('newEventEdge')
        .getLinkedRecord('node');
      const eventCreatedProject = store
        .getRootField('projectEventCreated')
        .getLinkedRecord('project');
      const projectId = eventCreatedProject.getDataID();
      const projectUnreadCounter = eventCreatedProject
        .getLinkedRecord('events')
        .getValue('unreadCount');
      const project = store.get(projectId);
      if (!project) return;
      const events = ConnectionHandler.getConnection(project, 'ProjectEventsList_events');
      const newEventId = event.getDataID();
      const isExistEventInProject = lastInsertedEventMap?.includes(newEventId);
      if (!isExistEventInProject) {
        lastInsertedEventMap.push(newEventId);
      }
      const campaignId = project.getLinkedRecord('campaign')?.getDataID();
      if (!campaignId) return;
      const campaign = store.get(String(campaignId));
      if (!campaign) return;
      const projectItems = ConnectionHandler.getConnection(
        campaign,
        'CampaignProjectsList_projects'
      );
      if (projectItems) {
        const projectItem = projectItems
          .getLinkedRecords('edges')
          ?.find((edge) => edge?.getLinkedRecord('node')?.getDataID() === projectId);
        if (projectItem) {
          const projectEvents = projectItem
            ?.getLinkedRecord('node')
            ?.getLinkedRecord('events(last:1)');
          if (!projectEvents) return;
          const edge = ConnectionHandler.createEdge(
            store,
            projectEvents,
            event,
            'ProjectEventEdge'
          );
          ConnectionHandler.insertEdgeBefore(projectEvents, edge);
        }
      }
      if (events) {
        const eventExists = Boolean(
          events
            .getLinkedRecords('edges')
            ?.find((edge) => edge.getLinkedRecord('node')?.getDataID() === newEventId)
        );
        if (eventExists) return;
        const edge = ConnectionHandler.createEdge(store, events, event, 'ProjectEventEdge');
        ConnectionHandler.insertEdgeAfter(events, edge);
      }

      const typeName = event.getValue('__typename');
      const eventAuthor = event.getLinkedRecord('actor').getValue('__typename');

      if (typeName !== OFFER && typeName !== OFFER_READ_MARK) {
        if (projectId) {
          project
            ?.getLinkedRecord('events', chatListFilters)
            ?.setValue(projectUnreadCounter, 'unreadCount');
          const campaignId = project?.getLinkedRecord('campaign')?.getDataID();
          if (campaignId) {
            const campaign = store.get(campaignId);
            if (!campaign) return;
            campaign
              ?.getOrCreateLinkedRecord('projectEvents', 'ProjectEventConnection')
              ?.setValue(projectUnreadCounter, 'unreadCount');
            const eventCreatedProjectCampaign = eventCreatedProject.getLinkedRecord('campaign');
            const unreadCounterExOffer = eventCreatedProjectCampaign
              .getLinkedRecord('counters')
              .getValue('unreadProjectEventsExcludingOfferAcceptance');
            campaign
              ?.getLinkedRecord('counters')
              ?.setValue(unreadCounterExOffer, 'unreadProjectEventsExcludingOfferAcceptance');

            const organizationId =
              project?.getLinkedRecord('campaign')?.getLinkedRecord('organization')?.getDataID() ||
              eventCreatedProjectCampaign?.getLinkedRecord('organization')?.getDataID();

            if (organizationId) {
              const organization = store.get(organizationId);
              if (!organization) return;
              const projectConnection = organization?.getLinkedRecord('projects', {
                stages: counterProjectStages,
                withUnreadEvents: true,
              });
              const unreadCounterCampaign = projectConnection?.getValue('totalCount');
              const unreadCounter = unreadCounterCampaign || unreadCounterExOffer;
              if (unreadCounter === 0 || projectUnreadCounter === 1) {
                const authorModerationStatus = event
                  .getLinkedRecord('actor')
                  ?.getLinkedRecord('review')
                  ?.getValue('type');
                if (
                  projectConnection &&
                  !isExistEventInProject &&
                  eventAuthor !== 'Brand' &&
                  authorModerationStatus === 'SUCCESS'
                ) {
                  const counter = Number(projectConnection.getValue('totalCount')) || 0;
                  projectConnection.setValue(counter + 1, 'totalCount');
                }
              }
            }
          }
        }
      }
      switch (typeName) {
        case PRICE_CHANGE_EVENT: {
          const price = event.getValue('price');
          project.setValue(price, 'price');
          break;
        }

        case DEADLINE_EVENT: {
          const draftDate = event.getValue('draftDate') as Primitive;
          const publicationDate = event.getValue('publicationDate') as Primitive;
          project.setValue(draftDate, 'draftDate');
          project.setValue(publicationDate, 'publicationDate');
          break;
        }

        // This will work if BE engeneers change ID of the DeadlineChangeRequest event
        // case DEADLINE_CHANGE_REQUEST: {
        //   const deadlineField = project.getLinkedRecord('deadlineChangeRequest');
        //   if (!deadlineField) {
        //     const request = store.create(event.getValue('id'), 'DeadlineChangeRequest');
        //     request.setValue(event.getValue('id'), 'id');
        //     request.setValue(event.getValue('draftDate') as string, 'draftDate');
        //     project.setLinkedRecord(request, 'deadlineChangeRequest');
        //   }
        // }

        default:
          break;
      }
    },
  });
};
