import { graphql, useMutation, UseMutationConfig } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';

import { AddCreatorToCustomListMutation } from 'GraphTypes/AddCreatorToCustomListMutation.graphql';
import { BaseMutationExecFn } from 'Types/utils';

const mutation = graphql`
  mutation AddCreatorToCustomListMutation($input: AddCreatorToCustomListInput!) {
    addCreatorToCustomList(input: $input) {
      __typename
      ... on AddCreatorToCustomListPayload {
        creator {
          id
          username
          customLists {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const useAddCreatorToCustomListMutation = (
  organizationId?: string,
  config?: UseMutationConfig<AddCreatorToCustomListMutation>
): [BaseMutationExecFn<AddCreatorToCustomListMutation>, boolean] => {
  const [addCreatorToCustomList, loading] = useMutation<AddCreatorToCustomListMutation>(mutation);

  const addCreatorToCustomListCaller: BaseMutationExecFn<AddCreatorToCustomListMutation> = (
    execConfig
  ) => {
    const { customListId, creatorId } = execConfig.variables.input;
    if (!organizationId) return;
    addCreatorToCustomList({
      ...config,
      ...execConfig,
      updater: (store) => {
        const updateOrganization = () => {
          const creators = store
            .get(organizationId)
            ?.getLinkedRecord('customLists')
            ?.getLinkedRecords('edges')
            ?.find((item) => item?.getLinkedRecord('node')?.getValue('id') === customListId)
            ?.getLinkedRecord('node')
            ?.getLinkedRecord('creators');
          if (creators) {
            const creatorRecord = store.get(creatorId);
            if (!creatorRecord) return;
            const edge = ConnectionHandler.createEdge(
              store,
              creators,
              creatorRecord,
              'CreatorEdge'
            );
            ConnectionHandler.insertEdgeAfter(creators, edge);
          }
        };

        const updateCurrentUser = () => {
          const root = store.getRoot();
          const customListEdges = root
            .getLinkedRecord('organization', { id: organizationId })
            ?.getLinkedRecord('customLists')
            ?.getLinkedRecords('edges');

          if (!customListEdges) return;

          customListEdges.forEach((customList, key) => {
            const id = customList?.getLinkedRecord('node')?.getValue('id');
            if (id && id === customListId) {
              const creatorRecord = store.get(creatorId);
              const customListNode = customList?.getLinkedRecord('node');
              if (!creatorRecord || !customListNode) return;
              const connectionId = `client:${customListId}:creators`;
              const connection = store.get(connectionId);
              const newConnection = connection || store.create(connectionId, 'CreatorConnection');
              const edge = ConnectionHandler.createEdge(
                store,
                newConnection,
                creatorRecord,
                'CreatorEdge'
              );
              ConnectionHandler.insertEdgeAfter(newConnection, edge);
              customList?.getLinkedRecord('node')?.setLinkedRecord(newConnection, 'creators');
            }
          });
        };

        updateOrganization();
        updateCurrentUser();
      },
    });
  };
  return [addCreatorToCustomListCaller, loading];
};
