import React, { useContext, useRef, useState } from 'react';
import classnames from 'classnames';
import { v4 as uuid } from 'uuid';

import styles from '../InviteOutreachCreators.pcss';
import { amplitude } from 'Helpers/amplitude';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { DISCOVERY_ROUTE } from 'Constants/general';

interface Props {
  organizationId?: string;
}

const CreatorsDiscoverySelection: React.FC<Props> = (props) => {
  const { organizationId } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const handleDiscoverClick = () => {
    // amplitude.sendEvent<410>({
    //   id: '410',
    //   category: 'campaign',
    //   name: 'outreach_attach_click',
    //   param: undefined,
    // });
    if (!organizationId) return;
    const newListId = uuid();
    const discoveryUrl = generatePath(DISCOVERY_ROUTE, { listId: newListId });
    navigate(discoveryUrl, {
      state: {
        backUrl: location.pathname,
      },
    });
    // createCustomList({
    //   variables: {
    //     input: {
    //       organizationId,
    //     },
    //   },
    //   onCompleted: (response) => {
    //     const typename = response.createCustomList?.__typename;
    //     if (typename === 'CreateCustomListPayload') {
    //       const listId = response.createCustomList?.customList?.id;
    //       if (!listId) {
    //         setError(InviteOutreachCreatorsError.FAILED_TO_CREATE_LIST);
    //         return;
    //       }

    //     } else {
    //       setError(InviteOutreachCreatorsError.FAILED_TO_CREATE_LIST);
    //     }
    //   },
    //   onError: () => {
    //     setError(InviteOutreachCreatorsError.FAILED_TO_CREATE_LIST);
    //   },
    // });
  };

  return (
    <>
      <div className={styles.attachTitle}>
        <Text type="md" msg="invite_outreach_creators_modal.step_1" />
      </div>
      <AlterButton onClick={handleDiscoverClick} text="Select creators from marketplace" />
    </>
  );
};

export default CreatorsDiscoverySelection;
