import React from 'react';
import classnames from 'classnames';

import styles from './Tab.pcss';

import Text, { Props as TextProps } from 'Components/ui/Text/Text';
import Icon, { Props as IconProps } from 'Components/ui/Icon/Icon';
import NewNotification from 'Atoms/NewNotification/NewNotification';
import Tag from 'Components/ui/Tag/Tag';

interface Props {
  id: string;
  isActive?: boolean;
  notificationActive?: boolean;
  isDisabled?: boolean;
  filled?: boolean;
  mute?: boolean;
  textData: TextProps;
  icon?: IconProps;
  count?: number | null;
  onClick?: (id: string) => void;
  tooltip?: JSX.Element;
  isNew?: boolean;
  isSimpleView?: boolean;
  betaTag?: boolean;
}

const Tab: React.FC<Props> = (props) => {
  const {
    id,
    isActive,
    notificationActive,
    isDisabled,
    textData,
    icon,
    count,
    filled,
    mute,
    onClick,
    tooltip,
    isSimpleView,
    betaTag,
  } = props;

  const handleClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  const clickHandle = !isActive && !isDisabled ? handleClick : undefined;

  return (
    <li
      className={classnames(styles.root, {
        [styles.isActive]: isActive,
        [styles.isDisabled]: isDisabled,
        [styles.filled]: filled,
      })}
      onClick={clickHandle}
    >
      {icon && <Icon size={24} {...icon} />}
      <div>
        {tooltip || null}
        {textData && (
          <Text type="md" {...textData} className={classnames({ [styles.disabled]: isDisabled })} />
        )}
        {betaTag && (
          <Tag msg="badge.beta" type="badge" needMargin={false} className={styles.beta} />
        )}
      </div>
      {((notificationActive && count) || (!notificationActive && (count || count === 0))) &&
        !isSimpleView && (
          <NewNotification
            count={count}
            isActive={(isActive || notificationActive) && !mute}
            isDisabled={isDisabled}
            className={styles.count}
          />
        )}
      {((notificationActive && count) || (!notificationActive && (count || count === 0))) &&
        isSimpleView && <Text type="label" text={count} className={styles.count} />}
    </li>
  );
};

export default Tab;
