/**
 * @generated SignedSource<<5922453d5148f677c5207e03802f286e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AddonId = "BRANDS" | "SEATS" | "WHITELISTING_LICENSES" | "%future added value";
export type PlanId = "ADVANCED" | "ADVANCED_2021_10" | "ADVANCED_2022_11" | "AGENCY_2024_03" | "BARTER" | "BASIC_OLD_PS" | "BRAND_2024_03" | "CONTENT_CREATION" | "ENTERPRISE" | "FREE" | "FREE_OLD_PS" | "FREE_TRIAL" | "FULLY_MANAGED" | "FULLY_MANAGED_1" | "FULLY_MANAGED_2" | "FULLY_MANAGED_TTCX_MMA" | "GROW" | "PAYOUT" | "PREMIUM" | "PREMIUM_PLUS" | "PUBLICIS" | "SCALE" | "SCALE_2021_06" | "SCALE_2021_10" | "SMARTLY_ENTERPRISE_2023_06" | "SMARTLY_ENTERPRISE_TRIAL_2023_06" | "TRIAL_2022_08" | "TRIAL_2024_03" | "TRIAL_MONTH" | "UGC_2022_08" | "UGC_ADS_2022_08" | "WILDJAM" | "%future added value";
export type SubscriptionInterval = "CUSTOM" | "MONTHLY" | "TRIMONTHLY" | "YEARLY" | "%future added value";
export type OrganizationTeamQuery$variables = Record<PropertyKey, never>;
export type OrganizationTeamQuery$data = {
  readonly currentUser: {
    readonly id: string;
    readonly organization: {
      readonly id: string;
      readonly members: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly advertiserProfile: {
              readonly firstName: string | null | undefined;
              readonly lastName: string | null | undefined;
            } | null | undefined;
            readonly email: string;
            readonly id: string;
          } | null | undefined;
        } | null | undefined> | null | undefined;
        readonly totalCount: number;
      } | null | undefined;
      readonly name: string;
      readonly subscription: {
        readonly addonItems: ReadonlyArray<{
          readonly addon: {
            readonly id: AddonId;
            readonly resourcesPerUnit: number;
          };
          readonly quantity: number;
        }>;
        readonly availableAddonIds: ReadonlyArray<AddonId>;
        readonly baseLimits: {
          readonly maxSeats: number | null | undefined;
        };
        readonly effectiveLimits: {
          readonly maxSeats: number | null | undefined;
        };
        readonly interval: SubscriptionInterval | null | undefined;
        readonly paused: boolean;
        readonly planId: PlanId;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type OrganizationTeamQuery = {
  response: OrganizationTeamQuery$data;
  variables: OrganizationTeamQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "planId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availableAddonIds",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "AddonItem",
  "kind": "LinkedField",
  "name": "addonItems",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Addon",
      "kind": "LinkedField",
      "name": "addon",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "resourcesPerUnit",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantity",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paused",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "interval",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "maxSeats",
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "SubscriptionLimits",
  "kind": "LinkedField",
  "name": "effectiveLimits",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "SubscriptionLimits",
  "kind": "LinkedField",
  "name": "baseLimits",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationTeamQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserConnection",
                "kind": "LinkedField",
                "name": "members",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AdvertiserProfile",
                            "kind": "LinkedField",
                            "name": "advertiserProfile",
                            "plural": false,
                            "selections": [
                              (v11/*: any*/),
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OrganizationTeamQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserConnection",
                "kind": "LinkedField",
                "name": "members",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AdvertiserProfile",
                            "kind": "LinkedField",
                            "name": "advertiserProfile",
                            "plural": false,
                            "selections": [
                              (v11/*: any*/),
                              (v12/*: any*/),
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5dc1f36bf47b35e423b0fa3a1e843e0f",
    "id": null,
    "metadata": {},
    "name": "OrganizationTeamQuery",
    "operationKind": "query",
    "text": "query OrganizationTeamQuery {\n  currentUser {\n    id\n    organization {\n      id\n      name\n      subscription {\n        planId\n        availableAddonIds\n        addonItems {\n          addon {\n            id\n            resourcesPerUnit\n          }\n          quantity\n        }\n        paused\n        interval\n        effectiveLimits {\n          maxSeats\n        }\n        baseLimits {\n          maxSeats\n        }\n        id\n      }\n      members {\n        edges {\n          node {\n            id\n            email\n            advertiserProfile {\n              lastName\n              firstName\n              id\n            }\n          }\n        }\n        totalCount\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "68b5c704d408b1d752cdf6be86efce4e";

export default node;
