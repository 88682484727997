import { InAppNotifications_Type } from 'GraphTypes/NotificationCenterQuery.graphql';

import { CheckboxItemType } from 'Components/ui/CheckboxSelect/CheckboxSelect';

export const getTypes = (types: InAppNotifications_Type[]): CheckboxItemType[] => {
  return [
    {
      id: 'CAMPAIGN_ACTIVATED',
      isChecked: types.includes('CAMPAIGN_ACTIVATED'),
      labelMsg: 'notification_center.type.campaign_activated',
      iconName: 'Rocket',
    },
    {
      id: 'DAILY_OFFER_ACCEPTANCES',
      isChecked: types.includes('DAILY_OFFER_ACCEPTANCES'),
      labelMsg: 'notification_center.type.daily_offer_acceptances',
      iconName: 'Offers',
    },
    // {
    //   id: 'NEW_MESSAGES',
    //   isChecked: types.includes('NEW_MESSAGES'),
    //   labelMsg: 'notification_center.type.new_messages',
    //   iconName: 'Chat',
    // },
    {
      id: 'CONTENT_REVIEW_SUBMITTED',
      isChecked: types.includes('CONTENT_REVIEW_SUBMITTED'),
      labelMsg: 'notification_center.type.content_review_submitted',
      iconName: 'Video-movies',
    },
    {
      id: 'CREATIVE_CREATED',
      isChecked: types.includes('CREATIVE_CREATED'),
      labelMsg: 'notification_center.type.creative_created',
      iconName: 'Attach-link',
    },
    {
      id: 'BCA_PERMISSION_REVOKED',
      isChecked: types.includes('BCA_PERMISSION_REVOKED'),
      labelMsg: 'notification_center.type.bca_permission_revoked',
      iconName: 'Warning-error',
    },
    {
      id: 'DRAFT_DEADLINE_OVERDUE',
      isChecked: types.includes('DRAFT_DEADLINE_OVERDUE'),
      labelMsg: 'notification_center.type.draft_deadline_overdue',
      iconName: 'Warning-error',
    },
    {
      id: 'ADVERTISER_UNRESPONSIVENESS',
      isChecked: types.includes('ADVERTISER_UNRESPONSIVENESS'),
      labelMsg: 'notification_center.type.advertiser_unresponsiveness',
      iconName: 'Support-sad',
    },
  ];
};
