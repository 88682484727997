import React, { useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import debounce from 'lodash/debounce';
import last from 'lodash/last';

import styles from './ModerationSettings.css';
import { visibleOpt, reviewedOpt, badges, employedOpt } from './data';

import updateCreatorProfile from 'Mutations/UpdateCreatorProfile.Mutation';
import updateContractorProfile from 'Mutations/UpdateContractorProfile.Mutation';
import markCreatorAsPassedReview from 'Mutations/MarkCreatorAsPassedReview.Mutation';
import Text from 'Atoms/Text/Text';
import Field from 'Molecules/Field/Field';
import { RUB } from 'Constants/general';
import { FAILED_REVIEW_REASON } from 'Constants/modals';
import { CreatorAdminPanelQuery$data } from 'GraphTypes/CreatorAdminPanelQuery.graphql';
import { ModerationSettings_creator$key } from 'GraphTypes/ModerationSettings_creator.graphql';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

interface Props {
  creator: ModerationSettings_creator$key;
  ethnicities: NonNullable<CreatorAdminPanelQuery$data>['ethnicities'];
  tags: NonNullable<CreatorAdminPanelQuery$data>['tags'];
  animals: NonNullable<CreatorAdminPanelQuery$data>['animals'];
  hairTypes: NonNullable<CreatorAdminPanelQuery$data>['hairTypes'];
  bodyTypes: NonNullable<CreatorAdminPanelQuery$data>['bodyTypes'];
  languages: NonNullable<CreatorAdminPanelQuery$data>['languages'];
}

const ModerationSettings: React.FC<Props> = (props) => {
  const { creator } = props;

  const data = useFragment(
    graphql`
      fragment ModerationSettings_creator on Creator {
        id
        profile {
          id
          badge
          searchable
          currency
          adminComment
          employed
          animals {
            id
            name
          }
          hairTypes {
            id
            name
          }
          bodyTypes {
            id
            name
          }
          tags {
            id
            name
          }
          ethnicity {
            id
          }
          languages {
            id
            name
          }
        }
        review {
          type
          reason
          source
        }
        ownership {
          owner {
            contractorProfile {
              id
              firstName
              languages {
                id
                name
              }
            }
          }
        }
      }
    `,
    creator
  );

  const { dispatch: modalDispatch } = useContext(ModalContext);

  const ethnicities = props.ethnicities;
  const tags = props.tags;
  const animals = props.animals;
  const hairTypes = props.hairTypes;
  const bodyTypes = props.bodyTypes;
  const languages = props.languages;

  const creatorId = data.id;
  const profile = data.profile;

  if (!profile) return null;

  const {
    id: profileId,
    currency,
    searchable,
    badge,
    hairTypes: creatorHairTypes,
    employed,
    animals: creatorAnimals,
    adminComment,
    ethnicity,
    languages: creatorLanguages,
    tags: creatorTags,
    bodyTypes: creatorBodyTypes,
  } = profile;

  const contractorId = data.ownership?.owner?.contractorProfile?.id;
  const contractorLanguages = data?.ownership?.owner?.contractorProfile?.languages;

  const ethnicityId = ethnicity?.id;

  if (!profileId) return null;

  const reviewType = data.review?.type;
  const reviewReason = data.review?.reason;
  const reviewSource = data.review?.source;

  const debounceUpdateCreatorProfile = debounce(updateCreatorProfile, 2000);

  const handleCreatorProfileChange = (data: any) => {
    debounceUpdateCreatorProfile({ id: profileId, ...data });
  };

  const handleSearchanbeChange = (data: any) => {
    const { searchable } = data;

    updateCreatorProfile({ id: profileId, searchable: searchable === 'VISIBLE' });
  };

  const handleEthnicityChange = (data: any) => {
    updateCreatorProfile({ id: profileId, ...data });
  };

  const handleJobChange = (data: any) => {
    const newEmployedVal =
      data.employed === 'HAS_JOB' ? true : data.employed === 'HAS_NO_JOB' ? false : null;
    updateCreatorProfile({ id: profileId, employed: newEmployedVal });
  };

  const handleReviewChange = (data: any) => {
    const { reviewedByAdmin } = data;

    if (reviewType === reviewedByAdmin) return;

    if (reviewedByAdmin === 'FAILURE') {
      modalDispatch({
        type: ModalTypes.SET_MODAL,
        payload: { name: FAILED_REVIEW_REASON, attach: { creatorId } },
      });
    } else if (reviewedByAdmin === 'SUCCESS') {
      markCreatorAsPassedReview({ creatorId });
    }
  };

  const handleBadgeChange = (data: any) => {
    const newBadgeData =
      data.badge === 'ABSENT'
        ? { badge: null, badgeSetAt: null }
        : { badge: data.badge, badgeSetAt: new Date() };
    updateCreatorProfile({ id: profileId, ...newBadgeData });
  };

  const handleAnimalsChange = (data: any) => {
    const newAnimalList =
      data?.animals?.map((item) => {
        return item.id;
      }) || [];
    updateCreatorProfile({ id: profileId, animalIds: newAnimalList });
  };

  const handleLanguagesChange = (data: any) => {
    const newLanguagesList =
      data?.languages?.map((item) => {
        return item.id;
      }) || [];
    updateContractorProfile({ id: contractorId, languageIds: newLanguagesList });
  };

  const handleBodyTypeChange = (data: any) => {
    const newBodyTypesList = data?.bodyTypes ? [data?.bodyTypes] : [];
    updateCreatorProfile({ id: profileId, bodyTypeIds: newBodyTypesList });
  };

  const handleHairTypesChange = (data: any) => {
    const newHairTypeIdList =
      data?.hairTypes?.map((item) => {
        return item.id;
      }) || [];
    updateCreatorProfile({ id: profileId, hairTypeIds: newHairTypeIdList });
  };

  const handleTagsChange = (data: any) => {
    const newTagsList =
      data?.tags?.map((item) => {
        return item.id;
      }) || [];
    updateCreatorProfile({ id: profileId, tagIds: newTagsList });
  };

  const ethnicitiesList = ethnicities.map((item) => {
    return { text: item.name, value: item.id, label: item.name };
  });

  const animalsList = creatorAnimals?.map((item) => {
    return { value: item.id, label: item.name };
  });

  const animalsOpt = animals.map((item) => {
    return { value: item.id, id: item.id, text: item.name, label: item.name };
  });

  const contractorLanguagesList = contractorLanguages?.map((item) => {
    return { value: item.id, label: item.name };
  });

  const creatorBodyType =
    Array.isArray(creatorBodyTypes) && creatorBodyTypes.length
      ? { value: last(creatorBodyTypes).id, label: last(creatorBodyTypes).name }
      : null;

  const creatorHairTypesList = creatorHairTypes?.map((item) => {
    return { value: item.id, label: item.name };
  });

  const bodyTypesList = bodyTypes?.map((item) => {
    return { value: item.id, id: item.id, text: item.name, label: item.name };
  });

  const hairTypesList = hairTypes?.map((item) => {
    return { value: item.id, id: item.id, text: item.name, label: item.name };
  });

  const languageList = languages.map((item) => {
    return { value: item.id, id: item.id, text: item.name, label: item.name };
  });

  const tagsOpts = tags.map((item) => {
    return { value: item.id, id: item.id, text: item.name, label: item.name };
  });

  const tagsList = creatorTags.map((item) => {
    return { value: item.id, label: item.name };
  });

  const employedValue = employed ? 'HAS_JOB' : employed === false ? 'HAS_NO_JOB' : undefined;

  return (
    <div className={styles.root}>
      <Text
        tag="h3"
        weight="700"
        msg="creator_statistic.settings.moderation.title"
        transform="uppercase"
        className={styles.title}
      />
      <div className={styles.row}>
        <Field
          name="searchable"
          title="creator.searchable"
          element="select"
          elementData={{
            options: visibleOpt,
            value: searchable ? 'VISIBLE' : 'HIDDEN',
            onChange: handleSearchanbeChange,
          }}
          className={styles.field}
        />
        <div className={styles.field}>
          <Field
            name="reviewedByAdmin"
            title="creator.is_reviewed"
            element="select"
            elementData={{
              options: reviewedOpt,
              placeholderMsg: 'creator.is_no_reviewed',
              value: reviewType,
              onChange: handleReviewChange,
            }}
          />
          <div className={styles.reason}>
            {reviewReason && (
              <Text
                size="sm"
                color="grayDog"
                msg={`failed_review_reason_modal.${reviewReason.toLowerCase()}`}
              />
            )}
            {reviewSource && (
              <Text
                size="sm"
                color="grayDog"
                msg={`failed_review_reason_modal.source.${reviewSource.toLowerCase()}`}
              />
            )}
          </div>
        </div>
        <Field
          name="badge"
          title="creator.badge"
          element="select"
          elementData={{
            options: badges,
            onChange: handleBadgeChange,
            value: badge ? badge : 'ABSENT',
          }}
          className={styles.field}
        />
      </div>
      {currency !== RUB && (
        <div className={styles.row}>
          <Field
            name="creatorEthnicityId"
            title="creator.ethnicity"
            element="select"
            elementData={{
              value: ethnicityId,
              options: ethnicitiesList,
              placeholderMsg: 'creator.ethnicity.placeholder',
              onChange: handleEthnicityChange,
            }}
          />
        </div>
      )}
      <div className={styles.row}>
        <Field
          name="employed"
          title="creator.job"
          element="select"
          elementData={{
            options: employedOpt,
            value: employedValue,
            onChange: handleJobChange,
          }}
          className={styles.field}
        />
      </div>
      <div className={styles.row}>
        <Field
          name="languages"
          title="creator.language"
          element="select"
          elementData={{
            isMulti: true,
            value: contractorLanguagesList,
            options: languageList,
            placeholderMsg: 'creator.language.placeholder',
            onChange: handleLanguagesChange,
          }}
        />
      </div>
      <div className={styles.row}>
        <Field
          name="bodyTypes"
          title="creator.body"
          element="select"
          elementData={{
            options: bodyTypesList,
            value: creatorBodyType?.value,
            placeholderMsg: 'creator.body.placeholder',
            onChange: handleBodyTypeChange,
          }}
        />
      </div>
      <div className={styles.row}>
        <Field
          name="hairTypes"
          title="creator.hair_type"
          element="select"
          elementData={{
            isMulti: true,
            value: creatorHairTypesList,
            options: hairTypesList,
            placeholderMsg: 'creator.hair_type.placeholder',
            onChange: handleHairTypesChange,
          }}
        />
      </div>
      <div className={styles.row}>
        <Field
          name="tags"
          title="creator.tags"
          element="select"
          elementData={{
            isMulti: true,
            value: tagsList,
            options: tagsOpts,
            placeholderMsg: 'creator.add_tags',
            onChange: handleTagsChange,
          }}
        />
      </div>
      <div className={styles.row}>
        <Field
          name="animals"
          title="creator.animals"
          element="select"
          elementData={{
            isMulti: true,
            value: animalsList,
            options: animalsOpt,
            placeholderMsg: 'creator.animals.placeholder',
            onChange: handleAnimalsChange,
          }}
        />
      </div>
      <Field
        name="adminComment"
        title="creator.admin_comments"
        element="input"
        elementData={{
          defaultValue: adminComment,
          multiple: true,
          textAreaData: { minRows: 4 },
          onChange: handleCreatorProfileChange,
        }}
      />
    </div>
  );
};

export default ModerationSettings;
