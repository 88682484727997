import React, { useState, useEffect } from 'react';
import remove from 'lodash/remove';

import styles from './NewNotifications.pcss';

import { amplitude } from 'Helpers/amplitude';
import { AWAITING_LIST } from 'Constants/general';
import { getCampaignTypeLink } from 'Util/links';
import Text from 'Components/ui/Text/Text';
import importCreatorsBySearchQueryOperationUpdated from 'Api/subscriptions/ImportCreatorsBySearchQueryOperationUpdated.Subscription';
import { ImportCreatorsBySearchQueryOperationUpdatedSubscription$data } from 'GraphTypes/ImportCreatorsBySearchQueryOperationUpdatedSubscription.graphql';
import campaignSubscriptionAutocreated from 'Api/subscriptions/CampaignSubscriptionAutocreated.Subscription';
import { CampaignSubscriptionAutocreatedSubscription$data } from 'GraphTypes/CampaignSubscriptionAutocreatedSubscription.graphql';
import { useUnsubscribeFromCampaignQueryMutation } from 'Mutations/UnsubscribeFromCampaign.Mutation';
import SmartLink from 'Atoms/SmartLink/SmartLink';

type Notification = {
  id: string;
  title: string;
  buttonMsg: string;
  link?: string;
  onClick?: () => void;
};

const NewNotifications = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    importCreatorsBySearchQueryOperationUpdated({ updater: handleImportUpdate });
  }, []);

  const handleImportUpdate = (
    data: ImportCreatorsBySearchQueryOperationUpdatedSubscription$data
  ) => {
    const campaignId = data.importCreatorsBySearchQueryOperationUpdated.campaign?.id || '';
    const state =
      data.importCreatorsBySearchQueryOperationUpdated.campaign
        ?.latestImportCreatorsBySearchQueryOperation?.state;

    if (state === 'SUCCESS') {
      setNotifications([
        ...notifications,
        {
          id: campaignId,
          title: 'creators_bulk_added.title',
          buttonMsg: 'creators_bulk_added.go_to',
          link: getCampaignTypeLink({ campaignId, path: AWAITING_LIST }),
        },
      ]);

      setTimeout(() => {
        removeNotification(campaignId);
      }, 5000);
    }
  };

  const [unsubscribeFromCampaign] = useUnsubscribeFromCampaignQueryMutation();

  const handleCampaignAutosubscriptionUpdate = (
    data: CampaignSubscriptionAutocreatedSubscription$data
  ) => {
    const campaignId = data.campaignSubscriptionAutocreated.campaign.id;

    amplitude.sendEvent<443>({
      id: '443',
      category: 'campaign_autosubscribe',
      name: 'notification_show',
      param: undefined,
    });

    setNotifications([
      ...notifications,
      {
        id: campaignId,
        title: 'notifications.campaign_autosubscribe.title',
        buttonMsg: 'notifications.campaign_autosubscribe.unsibscribe',
        onClick: () => {
          amplitude.sendEvent<444>({
            id: '444',
            category: 'campaign_autosubscribe',
            name: 'unsubscribe_click',
            param: undefined,
          });
          unsubscribeFromCampaign({
            variables: {
              input: {
                campaignId,
              },
            },
            updater: (store) => {
              const campaign = store.get(campaignId);
              campaign?.setValue(false, 'userSubscribed');
            },
          });
        },
      },
    ]);

    setTimeout(() => {
      removeNotification(campaignId);
    }, 10000);
  };

  campaignSubscriptionAutocreated({ updater: handleCampaignAutosubscriptionUpdate });

  const removeNotification = (id: string) => {
    const newList = [...notifications];
    remove(newList, (item) => {
      return item.id === id;
    });
    setNotifications(newList);
  };

  return (
    <div className={styles.root}>
      {notifications.map((item) => {
        const { id, title, buttonMsg, link, onClick } = item;

        const handleNotificationRemove = () => {
          removeNotification(id);
          onClick?.();
        };
        return (
          <div key={id} className={styles.notification}>
            <Text type="md" msg={title} className={styles.title} />
            <SmartLink path={link} onClick={handleNotificationRemove}>
              <Text type="md" msg={buttonMsg} className={styles.link} />
            </SmartLink>
          </div>
        );
      })}
    </div>
  );
};

export default NewNotifications;
