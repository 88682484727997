import React, { createContext, useReducer, PropsWithChildren, FC, Dispatch } from 'react';

type ReferencesContextValue = {
  queue: File[];
};

const initialValue: ReferencesContextValue = {
  queue: [],
};

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum Types {
  ADD_REFERENCE_TO_QUEUE = 'ADD_REFERENCE_TO_QUEUE',
  REMOVE_REFERENCE_TO_QUEUE = 'REMOVE_REFERENCE_TO_QUEUE',
  CLEAR_ALL_REFERENCE_FROM_QUEUE = 'CLEAR_ALL_REFERENCE_FROM_QUEUE',
}

type ReferencesPayload = {
  [Types.ADD_REFERENCE_TO_QUEUE]: {
    file: File;
  };
  [Types.REMOVE_REFERENCE_TO_QUEUE]: {
    file: File;
  };
  [Types.CLEAR_ALL_REFERENCE_FROM_QUEUE]: {};
};

export type ReferencesActions = ActionMap<ReferencesPayload>[keyof ActionMap<ReferencesPayload>];

type Reducer = (state: ReferencesContextValue, action: ReferencesActions) => ReferencesContextValue;
const reducer: Reducer = (state, action) => {
  switch (action.type) {
    case Types.ADD_REFERENCE_TO_QUEUE: {
      return {
        ...state,
        queue: [...state.queue, action.payload.file],
      };
    }
    case Types.REMOVE_REFERENCE_TO_QUEUE: {
      const fileForRemove = action.payload.file;
      const newList = state.queue.filter((item) => item !== fileForRemove);

      return {
        ...state,
        queue: newList,
      };
    }
    case Types.CLEAR_ALL_REFERENCE_FROM_QUEUE: {
      return {
        ...state,
        queue: [],
      };
    }
    default:
      return state;
  }
};

const ReferencesContext = createContext<{
  state: ReferencesContextValue;
  dispatch: Dispatch<ReferencesActions>;
}>({ state: initialValue, dispatch: () => null });

type ReferencesContextProviderType = FC<PropsWithChildren<{}>>;

const ReferencesContextProvider: ReferencesContextProviderType = (props) => {
  const { children } = props;

  const [state, dispatch] = useReducer<Reducer>(reducer, initialValue);

  return (
    <ReferencesContext.Provider value={{ state, dispatch }}>{children}</ReferencesContext.Provider>
  );
};

export { ReferencesContext, ReferencesContextProvider };
export type { ReferencesContextProviderType };
