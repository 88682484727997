/**
 * @generated SignedSource<<304e0a1d16bc6ba5ba50d432f482c1c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT" | "%future added value";
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CreatorsSearchWithParams_campaign$data = {
  readonly searchQuery: {
    readonly ageRanges: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly audienceAgeRangePercentage: number | null | undefined;
    readonly audienceCities: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly audienceCityPercentage: number | null | undefined;
    readonly audienceCountryPercentage: number | null | undefined;
    readonly audienceEthnicities: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly audienceGender: {
      readonly id: string;
      readonly name: string;
    } | null | undefined;
    readonly audienceGenderPercentage: number | null | undefined;
    readonly campaignsCompletionsCountFrom: number | null | undefined;
    readonly campaignsCompletionsCountTo: number | null | undefined;
    readonly categories: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly cities: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly countries: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly creatorAnimals: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly creatorBirthdateFrom: any | null | undefined;
    readonly creatorBirthdateTo: any | null | undefined;
    readonly creatorBodyTypes: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly creatorCountries: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly creatorEthnicities: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly creatorGender: {
      readonly id: string;
      readonly name: string;
    } | null | undefined;
    readonly creatorHairTypes: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly creatorLanguages: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly currency: Currency | null | undefined;
    readonly customLists: ReadonlyArray<{
      readonly id: string;
      readonly name: string | null | undefined;
    }> | null | undefined;
    readonly engagementRateFrom: number | null | undefined;
    readonly engagementRateTo: number | null | undefined;
    readonly excludeBadges: boolean | null | undefined;
    readonly excludeHiredMoreThanOnce: boolean | null | undefined;
    readonly exclusions: ReadonlyArray<{
      readonly advertiserProjectStages: ReadonlyArray<AdvertiserProjectStage> | null | undefined;
      readonly campaign: {
        readonly id: string;
        readonly name: string | null | undefined;
        readonly organization: {
          readonly name: string;
        } | null | undefined;
      };
    }> | null | undefined;
    readonly favorite: boolean | null | undefined;
    readonly followersFrom: number | null | undefined;
    readonly followersTo: number | null | undefined;
    readonly includeAmazonStorefrontLink: boolean | null | undefined;
    readonly onlyCreatorsWorkedWith: boolean | null | undefined;
    readonly postsReachFrom: number | null | undefined;
    readonly postsReachTo: number | null | undefined;
    readonly realFollowersFrom: number | null | undefined;
    readonly realFollowersTo: number | null | undefined;
    readonly reelsPlaysMedianFrom: number | null | undefined;
    readonly reelsPlaysMedianTo: number | null | undefined;
    readonly storiesReachFrom: number | null | undefined;
    readonly storiesReachTo: number | null | undefined;
    readonly strictlyByPrimaryCategory: boolean | null | undefined;
    readonly tags: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly viewsMedianFrom: number | null | undefined;
    readonly viewsMedianTo: number | null | undefined;
    readonly viewsPerPostFrom: number | null | undefined;
    readonly viewsPerPostTo: number | null | undefined;
    readonly withTiktokShop: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "CreatorsSearchWithParams_campaign";
};
export type CreatorsSearchWithParams_campaign$key = {
  readonly " $data"?: CreatorsSearchWithParams_campaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreatorsSearchWithParams_campaign">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreatorsSearchWithParams_campaign",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchQuery",
      "kind": "LinkedField",
      "name": "searchQuery",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchQueryExclusion",
          "kind": "LinkedField",
          "name": "exclusions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Campaign",
              "kind": "LinkedField",
              "name": "campaign",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Organization",
                  "kind": "LinkedField",
                  "name": "organization",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "advertiserProjectStages",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "excludeHiredMoreThanOnce",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "audienceCountryPercentage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "audienceGenderPercentage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "audienceAgeRangePercentage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "audienceCityPercentage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "onlyCreatorsWorkedWith",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomList",
          "kind": "LinkedField",
          "name": "customLists",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AgeRange",
          "kind": "LinkedField",
          "name": "ageRanges",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "City",
          "kind": "LinkedField",
          "name": "audienceCities",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Gender",
          "kind": "LinkedField",
          "name": "audienceGender",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Category",
          "kind": "LinkedField",
          "name": "categories",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "City",
          "kind": "LinkedField",
          "name": "cities",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "countries",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "creatorCountries",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Ethnicity",
          "kind": "LinkedField",
          "name": "creatorEthnicities",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Language",
          "kind": "LinkedField",
          "name": "creatorLanguages",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BodyType",
          "kind": "LinkedField",
          "name": "creatorBodyTypes",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Ethnicity",
          "kind": "LinkedField",
          "name": "audienceEthnicities",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Animal",
          "kind": "LinkedField",
          "name": "creatorAnimals",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HairType",
          "kind": "LinkedField",
          "name": "creatorHairTypes",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Tag",
          "kind": "LinkedField",
          "name": "tags",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creatorBirthdateFrom",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creatorBirthdateTo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "engagementRateFrom",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "engagementRateTo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "followersFrom",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "followersTo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "realFollowersFrom",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "realFollowersTo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewsPerPostFrom",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewsPerPostTo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewsMedianFrom",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewsMedianTo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "storiesReachFrom",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "storiesReachTo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postsReachFrom",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postsReachTo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reelsPlaysMedianFrom",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reelsPlaysMedianTo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "campaignsCompletionsCountFrom",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "campaignsCompletionsCountTo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Gender",
          "kind": "LinkedField",
          "name": "creatorGender",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "excludeBadges",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "strictlyByPrimaryCategory",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "includeAmazonStorefrontLink",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "favorite",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "withTiktokShop",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};
})();

(node as any).hash = "345ab7c1d14039b0018395ee55c7751f";

export default node;
