import React, { useState, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';

import styles from './TransferStatus.css';

import { createWithdrawalDate } from 'Util/dateCreator';
import confirmWithdrawal from 'Mutations/ConfirmWithdrawal.Mutation';
import failWithdrawal from 'Mutations/FailWithdrawal.Mutation';
import processWithdrawal from 'Mutations/ProcessWithdrawal.Mutation';
import Icon, { Props as IconProps } from 'Components/ui/Icon/Icon';
import Text from 'Atoms/Text/Text';
import Button from 'Atoms/Button/Button';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import TransactionComment from 'Modal/admin/TransactionComment/TransactionComment';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { TransferStatus_status$key } from 'GraphTypes/TransferStatus_status.graphql';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

const PROCESSING = 'PROCESSING';
const CANCELED = 'CANCELED';
const CONFIRMED = 'CONFIRMED';
const FAILED = 'FAILED';
const UNSENT = 'UNSENT';
const WIRE = 'WIRE';
const ZELLE = 'ZELLE';
const CARD = 'CARD';

interface Props {
  inModal?: boolean;
  status: TransferStatus_status$key;
  currentTransactionProvider?: any;
  setCurrentTransactionId?: (value: string) => void;
  setCurrentTransactionProcess?: (value: boolean) => void;
}

const TransferStatus: React.FC<Props> = (props) => {
  const {
    inModal,
    setCurrentTransactionId,
    setCurrentTransactionProcess,
    currentTransactionProvider,
    status,
  } = props;

  const { openDrawer } = useContext(DrawerContext);
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const data = useFragment(
    graphql`
      fragment TransferStatus_status on Payment_Withdrawal {
        id
        adminComment
        status
        statusDate
        paymentMethod {
          type
        }
        paymentAccount {
          id
        }
        lastError
      }
    `,
    status
  );
  const {
    id: transactionId,
    status: statusValue,
    statusDate,
    paymentMethod,
    paymentAccount,
    lastError,
    adminComment,
  } = data;
  const type = paymentMethod?.type;
  const accountId = paymentAccount.id;

  const [noFunds, setNoFunds] = useState(false);
  const [loading, setLoading] = useState(false);

  const commentCustomKey = `cancel-${transactionId}`;

  const handleConfirmClick = () => {
    setLoading(true);

    if (setCurrentTransactionProcess) setCurrentTransactionProcess(true);
    if (setCurrentTransactionId && transactionId) setCurrentTransactionId(transactionId);

    setTimeout(() => {
      // variable "currentTransactionProvider.current" doesnt have time to get value
      const paymentServiceProvider = currentTransactionProvider.current.toLowerCase() || null;

      const withdrawalData = {
        withdrawalId: transactionId,
        accountId,
        ...(!!paymentServiceProvider && type === CARD && { paymentServiceProvider }),
      };

      if (type === WIRE || type === ZELLE) {
        confirmWithdrawal({ ...withdrawalData }, handleConfirmDone, handleConfirmFail);
      } else {
        processWithdrawal({ ...withdrawalData }, handleConfirmDone, handleConfirmFail);
      }
    }, 0);
  };

  const handleConfirmDone = () => {
    if (setCurrentTransactionProcess) setCurrentTransactionProcess(false);
    setLoading(false);
  };

  const handleConfirmFail = () => {
    if (setCurrentTransactionProcess) setCurrentTransactionProcess(false);
    setLoading(false);
    setNoFunds(true);
  };

  const handleCancelDone = () => {
    if (setCurrentTransactionProcess) setCurrentTransactionProcess(false);
    modalDispatch({
      type: ModalTypes.TOGGLE_MODAL,
    });
  };

  const handleCancelClick = () => {
    if (setCurrentTransactionProcess) setCurrentTransactionProcess(true);
    if (setCurrentTransactionId && transactionId) setCurrentTransactionId(transactionId);
    openDrawer(`transaction-comment-${commentCustomKey}`);
  };

  const handleCancelWithdrawl = () => {
    failWithdrawal({ withdrawalId: transactionId, accountId }, handleCancelDone);
  };

  if (statusValue === UNSENT) {
    const size = inModal ? 'lg' : undefined;

    return (
      <div className={styles.actions}>
        <Tooltip
          id="noFunds"
          tooltipMsg={noFunds ? 'admin_withdrawals.insufficient_funds' : undefined}
          className={styles.button}
        >
          <Button
            size={size}
            msg="general.confirm"
            loading={loading}
            disabled={noFunds}
            className={noFunds ? styles.fullButton : styles.button}
            onClick={handleConfirmClick}
          />
        </Tooltip>
        <Button
          size={size}
          msg="general.cancel"
          color="normal"
          className={styles.button}
          loading={loading}
          onClick={handleCancelClick}
        />
        <TransactionComment
          id={transactionId}
          customKey={commentCustomKey}
          isWithdarwal
          comment={adminComment}
          callback={handleCancelWithdrawl}
        />
      </div>
    );
  }

  let iconData: IconProps;
  switch (statusValue) {
    case PROCESSING:
      iconData = { name: 'Time-clock', color: 'sun' };
      break;
    case CANCELED:
      iconData = { name: 'Close-small' };
      break;
    case FAILED:
      iconData = { name: 'Warning-error', color: 'red' };
      break;
    case CONFIRMED:
      iconData = { name: 'Check', color: 'green' };
      break;
    default:
      iconData = { name: 'Check', color: 'green' };
      break;
  }

  const statysTooltipData = statusValue === FAILED ? { tooltipText: lastError } : {};

  return (
    <div className={styles.statusWrap}>
      <div className={`${styles.iconWrap} ${styles[statusValue.toLowerCase()]}`}>
        <Icon name={iconData?.name} color={iconData?.color} />
      </div>
      <div>
        <Tooltip id="status" {...statysTooltipData}>
          <Text text={statusValue.toLowerCase()} transform="capitalize" />
        </Tooltip>
        {statusDate && <Text size="sm" color="grayDog" text={createWithdrawalDate(statusDate)} />}
      </div>
    </div>
  );
};

export default TransferStatus;
