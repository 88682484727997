import React from 'react';
import { createRoot } from 'react-dom/client';
import { isMobile, isTablet, isMobileOnly } from 'react-device-detect';

import { amplitude } from './helpers/amplitude';

import Root, { StorybookRoot } from 'Containers/Root/Root';
import 'Util/errorHandler';
import 'Util/sessionStorage';
import 'Styles/common';
import 'react-datepicker/dist/react-datepicker.css';

const RootComponent = (props) => <Root {...props} />;
const StorybookRootComponent = (props) => <StorybookRoot {...props} />;

const render = () => {
  const root = createRoot(document.getElementById('root'));
  root.render(<RootComponent />);
};

render();

if (isMobile) {
  document.body.classList.add('isMobile');
  if (isTablet) {
    document.body.classList.add('isTablet');
  }
  if (isMobileOnly) {
    document.body.classList.add('isMobileOnly');
  }
}

const entries = performance.getEntriesByType('navigation');
entries.forEach((entry) => {
  if (entry.type === 'reload') {
    amplitude.sendEvent({
      id: '372',
      category: 'system',
      name: 'page_reload',
    });
  }
});

export { RootComponent, render, StorybookRootComponent };
