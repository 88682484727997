import React from 'react';
import { graphql } from 'relay-runtime';
import { useLazyLoadQuery } from 'react-relay';

import { BookACallCalendarQuery as QueryType } from 'GraphTypes/BookACallCalendarQuery.graphql';

const BookACallCalendarQuery = graphql`
  query BookACallCalendarQuery {
    currentUser {
      email
      advertiserProfile {
        firstName
        lastName
      }
    }
  }
`;

const BookACallCalendar = () => {
  const data = useLazyLoadQuery<QueryType>(BookACallCalendarQuery, {});

  const email = data.currentUser?.email;
  const firstName = data.currentUser?.advertiserProfile?.firstName;
  const lastName = data.currentUser?.advertiserProfile?.lastName;

  return (
    <iframe
      width={500}
      height={640}
      src={`https://meetings.hubspot.com/leonardovoltani/insense-whitelisting-strategy-call?firstname=${firstName}&lastname=${lastName}&email=${email}&embed=true

`}
    />
  );
};

export default BookACallCalendar;
