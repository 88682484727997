import React, { useEffect, useContext } from 'react';
import { isMobileOnly } from 'react-device-detect';

import styles from './Modal.pcss';

import ModalContainer from 'Containers/ModalContainer/ModalContainer';
import { ModalContext } from 'Containers/ModalContainer/ModalContainerContext';

const Modal = () => {
  const { state: modalState } = useContext(ModalContext);
  const { opened } = modalState;

  useEffect(() => {
    if (opened) {
      document.body.classList.add('modalIsOpened');
    } else {
      document.body.classList.remove('modalIsOpened');
    }
  }, [opened]);

  if (!opened) return null;

  return (
    <div className={styles.root}>
      {!isMobileOnly && <div className={styles.backdrop} />}
      <div className={styles.content}>
        <ModalContainer />
      </div>
    </div>
  );
};

export default Modal;
