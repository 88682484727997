import React, { useRef, useEffect } from 'react';
import { PreloadedQuery, graphql, usePaginationFragment, usePreloadedQuery } from 'react-relay';

import InviteCreatorCard from './InviteCreatorCard/InviteCreatorCard';
import styles from './InviteSuggestionList.pcss';

import { InviteCreatorsQuery as QueryData } from '../InviteCreators';

import { InviteSuggestionList_inviteSuggestions$key } from 'GraphTypes/InviteSuggestionList_inviteSuggestions.graphql';
import { InviteCreatorsQuery } from 'GraphTypes/InviteCreatorsQuery.graphql';

const OFFSET = 70;
const COUNT = 20;

interface Props {
  campaignId: string;
  emptyView: JSX.Element;
  queryReference: PreloadedQuery<InviteCreatorsQuery>;
  onInvitationDone?: () => void;
}

const InviteSuggestionList: React.FC<Props> = (props) => {
  const { queryReference, campaignId, emptyView, onInvitationDone } = props;
  const queryData = usePreloadedQuery<InviteCreatorsQuery>(QueryData, queryReference);

  const listRef = useRef(null);

  const fragmentData = usePaginationFragment<
    InviteCreatorsQuery,
    InviteSuggestionList_inviteSuggestions$key
  >(
    graphql`
      fragment InviteSuggestionList_inviteSuggestions on Query
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 8 }
        after: { type: "String" }
        inviteSuggestionListId: { type: "ID!" }
      )
      @refetchable(queryName: "InviteSuggestionListQuery") {
        inviteSuggestionList(id: $inviteSuggestionListId) {
          inviteSuggestions(first: $first, after: $after)
            @connection(key: "InviteSuggestions_inviteSuggestions") {
            edges {
              node {
                id
                ...InviteCreatorCard_inviteSuggestion
              }
            }
            totalCount
          }
        }
      }
    `,
    queryData
  );

  useEffect(() => {
    if (!listRef?.current) {
      return;
    }
    const containerEl = listRef.current as HTMLDivElement;
    const scrollHandler = (e: Event) => {
      const target = e?.target as HTMLDivElement;
      if (!target) {
        return;
      }
      if (target.scrollHeight - target.offsetHeight - target.scrollTop < 10) {
        if (!fragmentData.isLoadingNext && fragmentData.hasNext) {
          fragmentData.loadNext(50);
        }
      }
    };
    if (containerEl) {
      containerEl.addEventListener('scroll', scrollHandler);
    }
    return () => {
      containerEl?.removeEventListener('scroll', scrollHandler);
    };
  }, [
    listRef?.current,
    fragmentData.isLoadingNext,
    fragmentData.loadNext,
    queryData,
    fragmentData.hasNext,
  ]);

  const data = fragmentData.data.inviteSuggestionList;

  const suggestions = Array.from(data?.inviteSuggestions.edges || []);

  if (suggestions.length === 0) return emptyView;

  return (
    <div className={styles.content} id="sugesstions-list" ref={listRef}>
      <div className={styles.list}>
        {suggestions.map((item) => {
          if (!item?.node) return null;

          return (
            <InviteCreatorCard
              key={item.node.id}
              campaignId={campaignId}
              inviteSuggestion={item.node}
              onInvitationDone={onInvitationDone}
            />
          );
        })}
      </div>
    </div>
  );
};

export default InviteSuggestionList;
