import { CreateOutreachBatchBySearchMutation } from 'GraphTypes/CreateOutreachBatchBySearchMutation.graphql';
import { graphql, useMutation } from 'react-relay';

import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const createOutreachBatchBySearchMutation = graphql`
  mutation CreateOutreachBatchBySearchMutation($input: CreateOutreachBatchBySearchInput!) {
    createOutreachBatchBySearch(input: $input) {
      __typename
      ... on CreateOutreachBatchBySearchPayload {
        batch {
          id
        }
      }
      ... on NotFoundError {
        message
      }
      ... on ValidationError {
        message
      }
    }
  }
`;

export const useCreateOutreachBatchBySearchMutation: BaseMutation<
  CreateOutreachBatchBySearchMutation
> = (config) => {
  const [createOutreachBatchBySearch, loading] = useMutation<CreateOutreachBatchBySearchMutation>(
    createOutreachBatchBySearchMutation
  );

  const createOutreachBatchBySearchCaller: BaseMutationExecFn<
    CreateOutreachBatchBySearchMutation
  > = (execConfig) => {
    createOutreachBatchBySearch({
      ...config,
      ...execConfig,
    });
  };
  return [createOutreachBatchBySearchCaller, loading];
};
