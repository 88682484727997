import { graphql, useMutation } from 'react-relay';

import { BaseMutation, BaseMutationExecFn } from 'Types/utils';
import { SendInviteTeammateLinkMutation } from 'GraphTypes/SendInviteTeammateLinkMutation.graphql';

const sendInviteTeammateLinkMutation = graphql`
  mutation SendInviteTeammateLinkMutation($input: SendInviteTeammateLinkInput!) {
    sendInviteTeammateLink(input: $input) {
      ... on EmptyPayload {
        clientMutationId
      }
    }
  }
`;

export const useSendInviteTeammateLinkMutation: BaseMutation<SendInviteTeammateLinkMutation> = (
  config
) => {
  const [sendInviteTeammateLink, loading] = useMutation<SendInviteTeammateLinkMutation>(
    sendInviteTeammateLinkMutation
  );
  const updateSendInviteTeammateLinkCaller: BaseMutationExecFn<SendInviteTeammateLinkMutation> = (
    execConfig
  ) => {
    sendInviteTeammateLink({ ...config, ...execConfig });
  };
  return [updateSendInviteTeammateLinkCaller, loading];
};
