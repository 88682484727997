import React, { Suspense, useCallback, useContext, useEffect, useRef } from 'react';
import { graphql, useFragment } from 'react-relay';
import { KeyType } from 'react-relay/relay-hooks/helpers';

import CreatorChat from '../../CreatorChat/CreatorChat';

import styles from './CreatorChatDrawers.pcss';

import { amplitude } from 'Helpers/amplitude';
import { normalizeFragmentRef } from 'Util/normalizeFragmentRef';
import { getProjectsLink } from 'Util/links';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import Drawer from 'Components/ui/Drawer/Drawer';
import Icon from 'Components/ui/Icon/Icon';
import ExtraPaymentDrawer from 'Modal/advertiser/ExtraPayment/ExtraPayment';
import ProjectNotesDrawer from 'Modal/advertiser/ProjectNotesDrawer/ProjectNotesDrawer';
import PriceChangeRequestDrawer from 'Modal/advertiser/PriceChangeRequest/PriceChangeRequest';
import RequestContentRevisionDrawer from 'Modal/advertiser/RequestContentRevision/RequestContentRevision';
import ArchiveProjectRequest from 'Modal/advertiser/ArchiveProjectRequest/ArchiveProjectRequest';
import ProjectCreatorRatingDrawer from 'Modal/advertiser/ProjectCreatorRating/ProjectCreatorRating';
import MetaAdsPromo from 'Modal/advertiser/MetaAdsPromo/MetaAdsPromo';
import TiktokAdsPromo from 'Modal/advertiser/TiktokAdsPromo/TiktokAdsPromo';
import SetDraftDeadline from 'Modal/advertiser/SetDraftDeadline/SetDraftDeadline';
import SetPublicationDate from 'Modal/advertiser/SetPublicationDate/SetPublicationDate';
import BcaRequestPermissions from 'Modal/advertiser/BcaRequestPermissions/BcaRequestPermissions';
import ReimbursementPayment from 'Modal/advertiser/ReimbursementPayment/ReimbursementPayment';
import FinishDealWrapperProvider from 'Molecules/FinishDealWrapper/FinishDealWrapperContext';
import {
  default as BcaPermissionModal,
  BcaPermissionModalProps,
} from 'Modal/advertiser/BcaPermissionModal/BcaPermissionModal';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import {
  CreatorChatDrawers_project$key,
  CreatorChatDrawers_project$data,
} from 'GraphTypes/CreatorChatDrawers_project.graphql';
import { USD, BARTER_TYPE } from 'Constants/general';
import ls from 'Util/localStorage';
import { useLocation } from 'react-router-dom';
import TiktokShopProductsListDrawer from 'Modal/advertiser/TiktokShopProductsList/TiktokShopProductsList';

interface Props {
  projectId: string;
  isAdmin: boolean;
  closeModal?: () => void;
  project: CreatorChatDrawers_project$key;
}

const CreatorChatDrawers: React.FC<Props> = (props) => {
  const { projectId, closeModal, project, isAdmin } = props;

  const { openDrawer, activeDrawerId } = useContext(DrawerContext);

  const presavedProjectId = ls.get('openCreatorChat');

  const fragment = useFragment<KeyType<CreatorChatDrawers_project$data>>(
    graphql`
      fragment CreatorChatDrawers_project on Project {
        id
        advertiserStage
        publicationEventExists
        adminComment
        comment
        price
        contentReview {
          status
        }
        campaign {
          id
          useCase
          type
          stage
          type
          platform
          paymentType
          publishingRequired
          organization {
            id
            paymentAccount {
              balance
              currency
            }
            subscription {
              transactionFee
            }
          }
        }
        bcaRequest {
          permission {
            id
          }
        }
        creator {
          id
          type
          ... on InstagramCreator {
            insightsAuthorized
            user {
              id
            }
          }
        }
      }
    `,
    normalizeFragmentRef<CreatorChatDrawers_project$key>(project)
  );

  const campaignId = fragment.campaign.id;
  const campaignType = fragment.campaign.type;
  const campaignStage = fragment.campaign.stage;
  const platform = fragment.campaign.platform;
  const creatorId = fragment.creator?.id;
  const creatorType = fragment.creator?.type;
  const insightsAuthorized = fragment.creator?.insightsAuthorized;
  const publishingRequired = fragment.campaign.publishingRequired;
  const bcaPermissionId = fragment.bcaRequest?.permission?.id;
  const price = fragment.price;
  const organizationId = fragment.campaign.organization?.id;
  const balance = fragment.campaign.organization?.paymentAccount?.balance;
  const transactionFee = fragment.campaign.organization?.subscription?.transactionFee;
  const adminComment = fragment.adminComment;
  const comment = fragment.comment;
  const advertiserStage = fragment.advertiserStage;
  const publicationEventExists = fragment.publicationEventExists;
  const hasSuccessReview = fragment.contentReview?.status === 'SUCCESS';
  const isBarter = fragment.campaign.paymentType === BARTER_TYPE;
  const isFreeOrganic =
    !price &&
    (fragment?.campaign?.useCase === 'AFFILIATE_CAMPAIGN' ||
      fragment?.campaign?.useCase === 'TIKTOK_SHOP');

  const needRequestLink = advertiserStage === 'PUBLISHING_STARTED' && !publicationEventExists;

  const projectsLink = getProjectsLink({
    projectId,
    campaignId: campaignStage === 'ACTIVE' ? campaignId : undefined,
    organizationId,
  });

  const handleBcaConnectionImpossible = useCallback<
    NonNullable<BcaPermissionModalProps['onBcaConnectionImpossible']>
  >(
    (reason) => {
      if (reason === 'maxLicensedCreatorsExceeded') {
        openDrawer('max-licensed-creators-exceeded');
      }
    },
    [openDrawer]
  );

  const handleProjectsClick = () => {
    amplitude.sendEvent<342>({
      id: '342',
      category: 'chat',
      name: 'open_all_chats_from_popup',
      param: {
        project_id: projectId,
      },
    });
  };

  const handleChatClose = () => {
    amplitude.sendEvent<343>({
      id: '343',
      category: 'chat',
      name: 'close_popup',
      param: {
        project_id: projectId,
        campaign_id: campaignId,
      },
    });
    ls.remove('openCreatorChat');
    closeModal?.();
  };

  const { pathname } = useLocation();
  useEffect(() => {
    const isRightUrl = !!pathname.match(/\/campaigns\/.*\/applicants.*/);
    if (!!presavedProjectId && projectId === presavedProjectId && isRightUrl) {
      openDrawer(`chat-modal-${presavedProjectId}`);
    }
  });

  return (
    <Suspense fallback={<div />}>
      <FinishDealWrapperProvider
        projectId={projectId}
        needRequestLink={needRequestLink}
        campaignType={campaignType}
        campaignPlatform={platform}
        creatorType={creatorType}
        insightsAuthorized={insightsAuthorized}
        selectSubmissionFiles={publishingRequired || hasSuccessReview}
        isBarter={isBarter}
        price={price}
        isFreeOrganic={isFreeOrganic}
      >
        <Drawer
          onClose={handleChatClose}
          rootKey={`chat-modal-${projectId}`}
          className={styles.drawer}
        >
          <CreatorChat projectId={projectId} />
          <div onClick={handleProjectsClick}>
            <SmartLink className={styles.openChats} path={projectsLink} onClick={closeModal}>
              <Icon name="Open-chat" />
            </SmartLink>
          </div>
        </Drawer>
      </FinishDealWrapperProvider>
      <BcaPermissionModal
        drawerProps={{ rootKey: `bca-permission-${bcaPermissionId}` }}
        bcaPermissionId={bcaPermissionId || ''}
        onBcaConnectionImpossible={handleBcaConnectionImpossible}
      />
      <ProjectNotesDrawer
        id={projectId}
        isAdmin={isAdmin}
        adminComment={adminComment}
        comment={comment}
      />
      <PriceChangeRequestDrawer
        projectId={projectId}
        projectPrice={price}
        currency={USD}
        balance={balance}
        transactionFee={transactionFee}
        source="popup-chat"
      />
      <RequestContentRevisionDrawer projectId={projectId} />
      <ArchiveProjectRequest
        projectId={projectId}
        creatorId={creatorId || ''}
        organizationId={organizationId || ''}
      />
      <SetDraftDeadline projectId={projectId} campaignType={campaignType} />
      <SetPublicationDate projectId={projectId} />
      <BcaRequestPermissions projectId={projectId} />
      <ProjectCreatorRatingDrawer creatorId={creatorId || ''} projectId={projectId} />
      <ReimbursementPayment projectId={projectId} balance={balance} />
      <ExtraPaymentDrawer projectId={projectId} balance={balance} />
      <TiktokShopProductsListDrawer projectId={projectId} campaignId={campaignId} />
      <MetaAdsPromo creatorId={creatorId} />
      <TiktokAdsPromo creatorId={creatorId} />
    </Suspense>
  );
};

export default CreatorChatDrawers;
