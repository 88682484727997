import React, { useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import styles from './NotificationsBell.pcss';

import { amplitude } from 'Helpers/amplitude';
import NotificationItem from 'AdvertiserPage/NotificationCenter/NotificationsList/NotificationItem/NotificationItem';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import Icon from 'Components/ui/Icon/Icon';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import { NOTIFICATIONS_CENTER } from 'Constants/general';
import { NotificationsBellQuery as QueryType } from 'GraphTypes/NotificationsBellQuery.graphql';
import Text from 'Components/ui/Text/Text';

const NotificationsBellQuery = graphql`
  query NotificationsBellQuery {
    currentUser {
      id
      unreadCount: inAppNotifications(first: 1, read: false) {
        totalCount
      }
      inAppNotifications(first: 3, read: false) {
        edges {
          node {
            id
            read
            ...NotificationItem_notification
          }
        }
      }
    }
  }
`;

const NotificationsBell = () => {
  const [opened, setOpened] = useState(false);
  const data = useLazyLoadQuery<QueryType>(NotificationsBellQuery, {});

  const list =
    data.currentUser?.inAppNotifications?.edges?.filter((item) => !item?.node?.read) || [];
  const isEmpty = !!(list.length === 0);

  const hasUnread = !!data.currentUser?.unreadCount?.totalCount;

  const notificationsList = list.map((item) => {
    if (!item?.node?.id) return null;

    const { id } = item.node;

    return (
      <DropdownItem key={id}>
        <NotificationItem notification={item.node} inDropdown />
      </DropdownItem>
    );
  });

  const bell = (
    <Tooltip
      id={opened ? undefined : 'notification_bell'}
      place="bottom"
      tooltipMsg="header.notifications.tooltip"
      delayShow={600}
    >
      <div className={styles.iconWrap}>
        <Icon name="Bell-notifications" />
        {hasUnread && <div className={styles.unreadMark} />}
      </div>
    </Tooltip>
  );

  const handleDropdownOpen = () => {
    amplitude.sendEvent<425>({
      id: '425',
      category: 'notification_bell',
      name: 'click',
      param: { has_unread: hasUnread },
    });
    setOpened(true);
  };

  const handleDropdownClose = () => {
    setOpened(false);
  };

  return (
    <div className={styles.root}>
      <Dropdown
        value={bell}
        showArrow={false}
        bordered={opened}
        onOpen={handleDropdownOpen}
        onClose={handleDropdownClose}
      >
        <DropdownGroup className={styles.dropdownGroup} labelMsg="header.notifications.list.label">
          <div className={styles.content}>
            {isEmpty ? (
              <Text type="md" msg="header.notifications.list.empty" className={styles.emptyTitle} />
            ) : (
              notificationsList
            )}
            <SmartLink path={NOTIFICATIONS_CENTER} className={styles.btnWrap}>
              <AlterButton type="grey" msg="header.notifications.go_to" className={styles.btn} />
            </SmartLink>
          </div>
        </DropdownGroup>
      </Dropdown>
    </div>
  );
};

export default NotificationsBell;
