import { graphql, useMutation, UseMutationConfig } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';

import { CreateCustomListMutation } from 'GraphTypes/CreateCustomListMutation.graphql';
import { BaseMutationExecFn } from 'Types/utils';

const mutation = graphql`
  mutation CreateCustomListMutation($input: CreateCustomListInput!) {
    createCustomList(input: $input) {
      __typename
      ... on CreateCustomListPayload {
        customList {
          name
          description
          authorName
          createdAt
          id
          creators {
            totalCount
            edges {
              node {
                ...CreatorDetails_creator
              }
            }
          }
        }
      }
    }
  }
`;

export const useCreateCustomListMutation = (
  config?: UseMutationConfig<CreateCustomListMutation>
): [BaseMutationExecFn<CreateCustomListMutation>, boolean] => {
  const [createOutreachBatchBySearch, loading] = useMutation<CreateCustomListMutation>(mutation);

  const createCustomListMutationCaller: BaseMutationExecFn<CreateCustomListMutation> = (
    execConfig
  ) => {
    const { organizationId } = execConfig.variables.input;
    if (!organizationId) return;
    createOutreachBatchBySearch({
      ...config,
      ...execConfig,
      updater: (store, response) => {
        const organization = store.get(organizationId);
        const currentUserCustomLists = store
          .getRoot()
          ?.getLinkedRecord('currentUser')
          ?.getLinkedRecord('organization')
          ?.getLinkedRecord('customLists');

        const responseData =
          response?.createCustomList?.__typename === 'CreateCustomListPayload' &&
          response?.createCustomList?.customList
            ? response.createCustomList.customList
            : null;

        if (currentUserCustomLists && responseData) {
          const record = responseData.id && store.get(responseData.id);
          if (record) {
            const edge = ConnectionHandler.createEdge(
              store,
              currentUserCustomLists,
              record,
              'CustomListEdge'
            );
            ConnectionHandler.insertEdgeBefore(currentUserCustomLists, edge);
          }
        }

        if (organization && responseData) {
          const customListsConnection = ConnectionHandler.getConnection(
            organization,
            'Organization_customLists'
          );

          if (customListsConnection) {
            const record = store.get(responseData.id || '');

            if (record) {
              const edge = ConnectionHandler.createEdge(
                store,
                customListsConnection,
                record,
                'CustomListEdge'
              );
              ConnectionHandler.insertEdgeBefore(customListsConnection, edge);
            }
          }
        }
      },
    });
  };
  return [createCustomListMutationCaller, loading];
};
