import React, { PropsWithChildren, useState } from 'react';

import {
  MessageAttachmentsContext,
  MessageAttachmentsContextType,
} from './MessageAttachmentsContext';

const MessageAttachmentsProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const [loadingAll, setLoadingAll] = useState(false);
  const [loadedCount, setLoadedCount] = useState(0);

  const handleFileUploaded = () => {
    if (loadingAll) {
      setLoadedCount((currentLoadedCount) => currentLoadedCount + 1);
    }
  };

  const contextValue: MessageAttachmentsContextType = {
    loadingAll,
    setLoadingAll,
    loadedCount,
    setLoadedCount,
    handleFileUploaded,
  };

  return (
    <MessageAttachmentsContext.Provider value={contextValue}>
      {children}
    </MessageAttachmentsContext.Provider>
  );
};

export default MessageAttachmentsProvider;
