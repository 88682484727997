type CreateURLSilently = (url: string | URL, base?: string | URL) => URL | null;
const createURLSilently: CreateURLSilently = (url, base) => {
  try {
    return new URL(url, base);
  } catch (e) {
    return null;
  }
};

export { createURLSilently };
