/**
 * @generated SignedSource<<96f8b68126be92a9356ba15d4519b667>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CampaignPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type InviteOutreachCreatorsQuery$variables = {
  campaignId: string;
};
export type InviteOutreachCreatorsQuery$data = {
  readonly campaign: {
    readonly platform: CampaignPlatform | null | undefined;
    readonly sampleOutreachTemplateVariables: ReadonlyArray<{
      readonly name: string;
      readonly value: string | null | undefined;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"GmailSettingsForOutreach_campaign">;
  } | null | undefined;
  readonly currentUser: {
    readonly organization: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"TemplateSelectorForOutreach_list">;
};
export type InviteOutreachCreatorsQuery = {
  response: InviteOutreachCreatorsQuery$data;
  variables: InviteOutreachCreatorsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "campaignId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "campaignId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "platform",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "TemplateVariable",
  "kind": "LinkedField",
  "name": "sampleOutreachTemplateVariables",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteOutreachCreatorsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "TemplateSelectorForOutreach_list"
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GmailSettingsForOutreach_campaign"
          },
          (v4/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InviteOutreachCreatorsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OutreachTemplateConnection",
        "kind": "LinkedField",
        "name": "outreachTemplates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OutreachTemplateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OutreachTemplate",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "locale",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "htmlBody",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subject",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GmailAuthorizationConnection",
                "kind": "LinkedField",
                "name": "gmailAuthorizations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GmailAuthorizationEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GmailAuthorization",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "reauthenticationRequired",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "availableEmails",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "availableNames",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v6/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a42a339bc3f596bc3ef5d74bff55ddde",
    "id": null,
    "metadata": {},
    "name": "InviteOutreachCreatorsQuery",
    "operationKind": "query",
    "text": "query InviteOutreachCreatorsQuery(\n  $campaignId: ID!\n) {\n  currentUser {\n    organization {\n      id\n    }\n    id\n  }\n  ...TemplateSelectorForOutreach_list\n  campaign(id: $campaignId) {\n    ...GmailSettingsForOutreach_campaign\n    platform\n    sampleOutreachTemplateVariables {\n      name\n      value\n    }\n    id\n  }\n}\n\nfragment GmailSettingsForOutreach_campaign on Campaign {\n  organization {\n    gmailAuthorizations {\n      edges {\n        node {\n          id\n          reauthenticationRequired\n          availableEmails\n          availableNames\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment TemplateSelectorForOutreach_list on Query {\n  outreachTemplates {\n    edges {\n      node {\n        id\n        name\n        locale\n        htmlBody\n        subject\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "82e74e5f85ba3bce6aedcc4459485adf";

export default node;
