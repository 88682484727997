import React from 'react';
import { graphql, PreloadedQuery, usePaginationFragment, usePreloadedQuery } from 'react-relay';

import styles from './NotificationsList.pcss';
import NotificationItem from './NotificationItem/NotificationItem';

import { NotificationCenterQuery } from '../NotificationCenter';

import LoaderHandlerWithHooks from 'Organisms/LoaderHandler/NewLoaderHandler';
import { NotificationCenterQuery as QueryType } from 'GraphTypes/NotificationCenterQuery.graphql';
import { NotificationsList_inAppNotifications$key } from 'GraphTypes/NotificationsList_inAppNotifications.graphql';
import { InAppNotifications_Type } from 'GraphTypes/NotificationCenterQuery.graphql';

const OFFSET = 70;
const COUNT = 20;

interface Props {
  read?: boolean;
  types?: InAppNotifications_Type[];
  queryReference: PreloadedQuery<QueryType>;
}

const NotificationsList: React.FC<Props> = (props) => {
  const { queryReference } = props;
  const queryData = usePreloadedQuery<QueryType>(NotificationCenterQuery, queryReference);

  const fragmentData = usePaginationFragment<QueryType, NotificationsList_inAppNotifications$key>(
    graphql`
      fragment NotificationsList_inAppNotifications on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 20 }
        cursor: { type: "String" }
        read: { type: Boolean }
        types: { type: "[InAppNotifications_Type!]" }
      )
      @refetchable(queryName: "NotificationsListPaginationList") {
        currentUser {
          inAppNotifications(first: $count, after: $cursor, read: $read, types: $types)
            @connection(key: "NotificationsList_inAppNotifications", filters: ["read", "types"]) {
            edges {
              node {
                id
                ...NotificationItem_notification
              }
            }
            totalCount
            pageInfo {
              hasNextPage
              endCursor
            }
          }
        }
      }
    `,
    queryData
  );

  const items = Array.from(fragmentData.data.currentUser?.inAppNotifications?.edges || []);

  return (
    <div>
      <LoaderHandlerWithHooks relay={fragmentData} offset={OFFSET} count={COUNT} items={items}>
        <div className={styles.list}>
          {items.map((item) => {
            const id = item?.node?.id;
            if (!id) return;
            return <NotificationItem key={id} notification={item?.node} />;
          })}
        </div>
      </LoaderHandlerWithHooks>
    </div>
  );
};

export default NotificationsList;
