import React, { useMemo, useRef } from 'react';
import { createPaginationContainer, graphql, RelayPaginationProp } from 'react-relay';
import classNames from 'classnames';

import Skeleton from '../../../components/Skeleton/Skeleton';
import CustomListDetails from '../../../components/CustomListDetails/CustomListDetails';
import CustomListFavoritesDetails from '../../../components/CustomListFavoritesDetails/CustomListFavoritesDetails';

import styles from './CustomLists.pcss';

import LoaderHandler from 'Organisms/LoaderHandler/LoaderHandler';
import { CustomLists_organization$data } from 'GraphTypes/CustomLists_organization.graphql';
import Text from 'Components/ui/Text/Text';

interface Props {
  relay: RelayPaginationProp;
  organization: CustomLists_organization$data;
}

const CustomLists: React.FC<Props> = (props) => {
  const { organization, relay } = props;

  const listRef = useRef<HTMLDivElement | null>(null);

  const customLists = organization?.customLists?.edges || [];
  const favoriteCreatorsTotalCount = organization?.favoriteCreators?.totalCount || 0;

  const list = useMemo(() => {
    const favorites = (
      <CustomListFavoritesDetails key="favorites" totalCount={favoriteCreatorsTotalCount} />
    );
    const customs = customLists.map((item, key) => {
      if (!item?.node) return null;
      return <CustomListDetails key={key} item={item.node} />;
    });
    return [favorites, customs];
  }, [customLists, favoriteCreatorsTotalCount]);

  return (
    <>
      <div className={styles.listHeader}>
        <Text
          type="md"
          className={styles.listHeaderText}
          msg="creators.dashboard.list.header.name"
        />
        <Text
          type="md"
          className={classNames(styles.listHeaderText, styles.left)}
          msg="creators.dashboard.list.header.creators"
        />
        <Text
          type="md"
          className={classNames(styles.listHeaderText, styles.left)}
          msg="creators.dashboard.list.header.description"
        />
        <Text
          type="md"
          className={classNames(styles.listHeaderText, styles.left)}
          msg="creators.dashboard.list.header.author"
        />
        <Text
          type="md"
          className={classNames(styles.listHeaderText, styles.left)}
          msg="creators.dashboard.list.header.created"
        />
        <div />
      </div>
      <LoaderHandler
        containerRef={listRef}
        relay={relay}
        count={20}
        offset={20}
        preloader={<Skeleton />}
      >
        <div ref={listRef} className={styles.list}>
          {list}
        </div>
      </LoaderHandler>
    </>
  );
};

export default createPaginationContainer(
  CustomLists,
  {
    organization: graphql`
      fragment CustomLists_organization on Organization
      @argumentDefinitions(count: { type: "Int", defaultValue: 18 }, cursor: { type: "String" }) {
        id
        customLists(first: $count, after: $cursor)
          @connection(key: "Organization_customLists", filters: []) {
          edges {
            node {
              ...CustomListDetails_item
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
        favoriteCreators {
          totalCount
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.organization.customLists;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        ...props,
      };
    },
    query: graphql`
      query CustomListsPaginationQuery($count: Int!, $cursor: String) {
        currentUser {
          organization {
            ...CustomLists_organization @arguments(count: $count, cursor: $cursor)
            favoriteCreators {
              totalCount
            }
          }
        }
      }
    `,
  }
);
