import React, { useContext } from 'react';
import track from 'react-tracking';

import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import InviteTeamMembers from 'Modal/advertiser/InviteTeamMembers/InviteTeamMembers';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

interface Props {
  organizationId?: string;
  organizationName?: string;
}

const TeammatesInvitation: React.FC<Props> = (props) => {
  const { organizationId, organizationName } = props;

  const { openDrawer } = useContext(DrawerContext);

  if (!organizationId) {
    return null;
  }

  const handleClick = () => {
    openDrawer(`invite-team-member-${organizationId}`);
  };

  return (
    <>
      <AlterButton bordered icon="Group-users" msg="advertisers.add_user" onClick={handleClick} />
      {organizationId && (
        <InviteTeamMembers
          drawerName={`invite-team-member-${organizationId}`}
          organizationName={organizationName}
          organizationId={organizationId}
        />
      )}
    </>
  );
};

export default track({ element: 'invite_teammate' })(TeammatesInvitation);
