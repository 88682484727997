import { v4 as uuid } from 'uuid';
import { fetchQuery, graphql } from 'relay-runtime';

import environment from 'Api/Environment';
import { identify } from 'Util/custify';
import ErrorHandler from 'Util/errorHandler';
import { getQueryLocationParams } from 'Util/encodeQueryData';
import { firebaseClient } from 'Containers/Auth0/Auth0Context/utils';
import { waitUntilUserInitialized } from 'Containers/Auth0/Auth0Context';
import createOrganization from 'Mutations/CreateOrganization.Mutation';
import assignUserToOrganization from 'Mutations/AssignUserToOrganization.Mutation';
import { AssignUserToOrganizationMutation$data } from 'GraphTypes/AssignUserToOrganizationMutation.graphql';
import { useSessionActionsQuery$data } from 'GraphTypes/useSessionActionsQuery.graphql';
import { CreateOrganizationMutation$data } from 'GraphTypes/CreateOrganizationMutation.graphql';
import projectEventCreatedSubscription from 'Subscriptions/ProjectEventCreated.Subscription';
import projectUnreadCountChanged from 'Subscriptions/ProjectUnreadCountChanged.Subscription';
import campaignSummaryUpdated from 'Subscriptions/CampaignSummaryUpdated.Subscription';
import organizationBalanceUpdated from 'Subscriptions/OrganizationBalanceUpdated.Subscription';
import fileProcessingStatusUpdated from 'Subscriptions/FileProcessingStatusUpdated.Subscription';
import fileBcaTaggingStatusUpdated from 'Subscriptions/FileBcaTaggingStatusUpdated.Subscription';
import organizationSubscriptionUpdated from 'Subscriptions/OrganizationSubscriptionUpdated.Subscription';
import shopifyOrderStateUpdatedSubscription from 'Api/subscriptions/ShopifyOrderStateUpdated.Subscription';
import inAppNotificationCreatedSubscription from 'Api/subscriptions/InAppNotificationCreated.Subscription';

interface AdvertiserSignupParams {
  email: string;
  userId: string;
  name: string;
  websiteUrl: string;
  affiliateReferralCode?: string;
  organizationId?: string;
  onOrganizationIdGenerated?: (organizationId: string) => void;
  onSuccess: () => void;
}

interface SessionActionsResult {
  initSubscription: () => void;
  advertiserSignup: (params: AdvertiserSignupParams) => void;
}

const query = graphql`
  query useSessionActionsQuery {
    currentUser {
      id
      type
      advertiserProfile {
        id
        firstName
      }
    }
  }
`;

type UseSessionActions = () => SessionActionsResult;
const useSessionActions: UseSessionActions = () => {
  const setSubscription = () => {
    projectEventCreatedSubscription();
    projectUnreadCountChanged();
    campaignSummaryUpdated();
    organizationBalanceUpdated();
    fileProcessingStatusUpdated();
    fileBcaTaggingStatusUpdated();
    organizationSubscriptionUpdated();
    shopifyOrderStateUpdatedSubscription();
    inAppNotificationCreatedSubscription();
  };

  const initSubscription = async () => {
    await waitUntilUserInitialized();
    const isAuthenticated = !!firebaseClient.authClient.currentUser;
    if (isAuthenticated) {
      try {
        fetchQuery(environment, query, {}).subscribe({
          next: (result: useSessionActionsQuery$data) => {
            const userType = result.currentUser?.type;
            if (userType && userType !== 'UNKNOWN') {
              setSubscription();
            }
          },
          error: () => {
            ErrorHandler.error('Crash while set subscriptions');
          },
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  const advertiserSignup = async (params: AdvertiserSignupParams) => {
    const {
      name,
      email,
      organizationId,
      userId,
      websiteUrl,
      onOrganizationIdGenerated,
      affiliateReferralCode,
      onSuccess,
    } = params;

    const queries = getQueryLocationParams();
    const referralToken = queries.referralToken ? queries.referralToken.toString() : undefined;

    try {
      setSubscription();
      if (organizationId) {
        await new Promise<AssignUserToOrganizationMutation$data>((resolve, reject) => {
          assignUserToOrganization({ organizationId, userId }, resolve, reject);
        });
      } else {
        const organizationId = uuid();
        onOrganizationIdGenerated?.(organizationId);
        const referrerCode = affiliateReferralCode
          ? { tapfiliateReferralCode: affiliateReferralCode }
          : null;
        await new Promise<CreateOrganizationMutation$data>((resolve, reject) => {
          createOrganization(
            { id: organizationId, websiteUrl, name, referralToken, ...referrerCode },
            resolve,
            reject
          );
        });
      }
      identify({
        userId,
        email,
        company_id: organizationId,
      });
      onSuccess?.();
    } catch (error) {
      ErrorHandler.error('Crash while signup as advertiser', { email, error });
    }
  };

  return { initSubscription, advertiserSignup };
};

export { useSessionActions };
