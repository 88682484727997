/**
 * @generated SignedSource<<73e9e96327297990a8bb933dbf751966>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SendInviteTeammateLinkInput = {
  clientMutationId?: string | null | undefined;
  email: string;
  organizationId: string;
};
export type SendInviteTeammateLinkMutation$variables = {
  input: SendInviteTeammateLinkInput;
};
export type SendInviteTeammateLinkMutation$data = {
  readonly sendInviteTeammateLink: {
    readonly clientMutationId?: string | null | undefined;
  } | null | undefined;
};
export type SendInviteTeammateLinkMutation = {
  response: SendInviteTeammateLinkMutation$data;
  variables: SendInviteTeammateLinkMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientMutationId",
      "storageKey": null
    }
  ],
  "type": "EmptyPayload",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendInviteTeammateLinkMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "sendInviteTeammateLink",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendInviteTeammateLinkMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "sendInviteTeammateLink",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d1a0a5a4ca94ad3b9306b07b1816ce79",
    "id": null,
    "metadata": {},
    "name": "SendInviteTeammateLinkMutation",
    "operationKind": "mutation",
    "text": "mutation SendInviteTeammateLinkMutation(\n  $input: SendInviteTeammateLinkInput!\n) {\n  sendInviteTeammateLink(input: $input) {\n    __typename\n    ... on EmptyPayload {\n      clientMutationId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "20f079bd78cf1b7621bcf945680f1de5";

export default node;
