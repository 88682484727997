import React, { useEffect, useState, useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';
import remove from 'lodash/remove';

import { Props } from './MediaCarouselContainer';
import styles from './MediaCarousel.pcss';

import { amplitude } from 'Helpers/amplitude';
import { downloadFile } from 'Util/downloadFile';
import TextButton from 'Components/ui/TextButton/TextButton';
import Spinner from 'Atoms/Spinner/Spinner';
import Icon from 'Components/ui/Icon/Icon';

const MediaCarousel: React.FC<Omit<Props, 'id'>> = (props) => {
  const { currentIndex = 0, message } = props;

  const [index, setIndex] = useState(currentIndex);
  const [uploadingList, setUploadingList] = useState<number[]>([]);

  const data = useFragment(
    graphql`
      fragment MediaCarousel_message on ProjectEvent {
        ... on ProductSeedingContentSubmission {
          creatives {
            totalCount
            edges {
              node {
                id
                createdAt
                file {
                  type
                  thumbnailUrl
                  filename
                  secureUrl
                  bcaTaggedUrl
                  originalFilename
                  ... on Video {
                    transformations {
                      autoUrl
                    }
                  }
                }
              }
            }
          }
        }
        ... on ContentReviewSubmission {
          reviewFiles: attachments {
            totalCount
            edges {
              node {
                id
                createdAt
                file {
                  type
                  thumbnailUrl
                  filename
                  secureUrl
                  bcaTaggedUrl
                  originalFilename
                  ... on Video {
                    transformations {
                      autoUrl
                    }
                  }
                }
              }
            }
          }
        }
        ... on Message {
          attachments {
            totalCount
            edges {
              node {
                id
                createdAt
                file {
                  type
                  thumbnailUrl
                  filename
                  secureUrl
                  bcaTaggedUrl
                  originalFilename
                  ... on Video {
                    transformations {
                      autoUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    message
  );

  useEffect(() => {
    setIndex(currentIndex);
  }, [currentIndex]);

  const attachments = data.attachments || data.reviewFiles || data.creatives;
  const totalCount = attachments?.totalCount;
  const multipleMedais = Number(attachments?.totalCount) > 1;

  const currentMedia = attachments?.edges ? attachments?.edges[index] : undefined;
  const file = currentMedia?.node?.file;
  const type = file?.type;
  const thumbnailUrl = file?.thumbnailUrl;
  const isVideo = type === 'VIDEO';

  const handlePrevClick = useCallback(() => {
    if (!multipleMedais) return;
    amplitude.sendEvent<439>({
      id: '439',
      category: 'attachments_drawer',
      name: 'control_click',
      param: { direction: 'previous' },
    });
    setIndex((index) => (index === 0 ? Number(totalCount) - 1 : index - 1));
  }, [index]);

  const handleNextClick = useCallback(() => {
    if (!multipleMedais) return;
    amplitude.sendEvent<439>({
      id: '439',
      category: 'attachments_drawer',
      name: 'control_click',
      param: { direction: 'next' },
    });
    setIndex((index) => (index === Number(totalCount) - 1 ? 0 : index + 1));
  }, [index]);

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'ArrowRight') {
      handleNextClick();
    } else if (e.key === 'ArrowLeft') {
      handlePrevClick();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', onKeyPress);

    return () => {
      window.removeEventListener('keyup', onKeyPress);
    };
  }, []);

  const handleUploadClick = useCallback(() => {
    const bcaTaggedUrl = file?.bcaTaggedUrl;
    const secureUrl = file?.secureUrl;
    const originalFilename = file?.originalFilename;
    const finalName = decodeURIComponent(bcaTaggedUrl || secureUrl || '');
    const filename = originalFilename || finalName?.split('/')?.pop()?.split('?')[0];
    const finalLink = bcaTaggedUrl || secureUrl;
    const handleLoaded = () => {
      const newList = [...uploadingList];
      remove(newList, (item) => {
        return item === index;
      });
      setUploadingList(newList);
    };
    setUploadingList([...uploadingList, index]);
    downloadFile(finalLink, filename, handleLoaded, handleLoaded);
  }, [index]);

  return (
    <div className={styles.carousel}>
      <div className={classnames(styles.content, { [styles.multiple]: multipleMedais })}>
        {multipleMedais && (
          <div className={classnames(styles.controlWrap, styles.leftControl)}>
            <Icon
              size={36}
              name="Arrow-small-left"
              className={styles.control}
              handleClick={handlePrevClick}
            />
          </div>
        )}
        <div className={styles.mediaWrap}>
          {isVideo && (
            <video
              controls
              src={file?.transformations?.autoUrl}
              poster={thumbnailUrl}
              className={styles.media}
            />
          )}
          {!isVideo && <img src={thumbnailUrl} className={styles.media} />}
        </div>
        {multipleMedais && (
          <div className={classnames(styles.controlWrap, styles.rightControl)}>
            <Icon
              size={36}
              name="Arrow-small-right"
              className={styles.control}
              handleClick={handleNextClick}
            />
          </div>
        )}
      </div>
      <footer className={styles.footer}>
        {!uploadingList.includes(index) && (
          <TextButton icon="Download-upload" color="white" onClick={handleUploadClick} />
        )}
        {uploadingList.includes(index) && <Spinner size="sm" color="white" />}
      </footer>
    </div>
  );
};

export default MediaCarousel;
