/**
 * @generated SignedSource<<20fdaa5884a565ade3718211340257b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CampaignUseCase = "AFFILIATE_CAMPAIGN" | "INFLUENCER_POSTS" | "META_PARTNERSHIP_ADS" | "PRODUCT_SEEDING" | "TIKTOK_SHOP" | "TIKTOK_SPARK_ADS" | "USER_GENERATED_CONTENT" | "%future added value";
export type InterestInUseCase_Button = "BOOK_CALL_BUTTON" | "FILL_BRIEF_BUTTON" | "%future added value";
export type InterestInUseCase_Source = "DASHBOARD_BANNER" | "PROJECT_COMPLETION" | "%future added value";
export type IndicateInterestInUseCaseInput = {
  button: InterestInUseCase_Button;
  clientMutationId?: string | null | undefined;
  creatorId?: string | null | undefined;
  source: InterestInUseCase_Source;
  useCase: CampaignUseCase;
};
export type IndicateInterestInUseCaseMutation$variables = {
  input: IndicateInterestInUseCaseInput;
};
export type IndicateInterestInUseCaseMutation$data = {
  readonly indicateInterestInUseCase: {
    readonly clientMutationId?: string | null | undefined;
  } | null | undefined;
};
export type IndicateInterestInUseCaseMutation = {
  response: IndicateInterestInUseCaseMutation$data;
  variables: IndicateInterestInUseCaseMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientMutationId",
      "storageKey": null
    }
  ],
  "type": "EmptyPayload",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "IndicateInterestInUseCaseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "indicateInterestInUseCase",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "IndicateInterestInUseCaseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "indicateInterestInUseCase",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "63a3fc7f4c7abac07cefeac3aa7b7be8",
    "id": null,
    "metadata": {},
    "name": "IndicateInterestInUseCaseMutation",
    "operationKind": "mutation",
    "text": "mutation IndicateInterestInUseCaseMutation(\n  $input: IndicateInterestInUseCaseInput!\n) {\n  indicateInterestInUseCase(input: $input) {\n    __typename\n    ... on EmptyPayload {\n      clientMutationId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e6a2bf8489de6bc988c36cd56b217e3d";

export default node;
