/**
 * @generated SignedSource<<126af9c1249b1984feb598b13bee5aa4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FileType = "IMAGE" | "OTHER" | "VIDEO" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MessageAttachments_message$data = {
  readonly attachments?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly createdAt: any;
        readonly file: {
          readonly bcaTaggedUrl: string | null | undefined;
          readonly bytes: number | null | undefined;
          readonly filename: string | null | undefined;
          readonly originalFilename: string | null | undefined;
          readonly secureUrl: string;
          readonly thumbnailUrl: string;
          readonly type: FileType;
          readonly " $fragmentSpreads": FragmentRefs<"MessageMedia_file">;
        };
        readonly id: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number;
  } | null | undefined;
  readonly id?: string;
  readonly " $fragmentSpreads": FragmentRefs<"MediaCarousel_message">;
  readonly " $fragmentType": "MessageAttachments_message";
};
export type MessageAttachments_message$key = {
  readonly " $data"?: MessageAttachments_message$data;
  readonly " $fragmentSpreads": FragmentRefs<"MessageAttachments_message">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MessageAttachments_message",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MediaCarousel_message"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "MessageAttachmentConnection",
          "kind": "LinkedField",
          "name": "attachments",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MessageAttachmentEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MessageAttachment",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "createdAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "file",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "type",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "bytes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "thumbnailUrl",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "filename",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "secureUrl",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "bcaTaggedUrl",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "originalFilename",
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "MessageMedia_file"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "Message",
      "abstractKey": null
    }
  ],
  "type": "ProjectEvent",
  "abstractKey": "__isProjectEvent"
};
})();

(node as any).hash = "e4af76d7151b8239285351ea7d3997b8";

export default node;
