import React, { createContext, useReducer, PropsWithChildren, FC, Dispatch } from 'react';

type ModalContextValue = {
  name: string;
  attach: any;
  opened: boolean;
  forceClose?: boolean;
};

const initialValue: ModalContextValue = {
  name: '',
  attach: null,
  opened: false,
  forceClose: false,
};

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum Types {
  SET_MODAL = 'SET_MODAL',
  TOGGLE_MODAL = 'TOGGLE_MODAL',
}

type ModalPayload = {
  [Types.SET_MODAL]: {
    name: string;
    attach?: any;
    forceClose?: boolean;
  };
  [Types.TOGGLE_MODAL]: undefined;
};

export type ModalActions = ActionMap<ModalPayload>[keyof ActionMap<ModalPayload>];

type Reducer = (state: ModalContextValue, action: ModalActions) => ModalContextValue;
const reducer: Reducer = (state, action) => {
  switch (action.type) {
    case Types.SET_MODAL: {
      return {
        ...state,
        opened: true,
        name: action.payload.name,
        attach: action.payload.attach,
        forceClose: action.payload.forceClose,
      };
    }
    case Types.TOGGLE_MODAL: {
      return {
        ...initialValue,
      };
    }
    default:
      return state;
  }
};

const ModalContext = createContext<{
  state: ModalContextValue;
  dispatch: Dispatch<ModalActions>;
}>({ state: initialValue, dispatch: () => null });

type ModalContextProviderType = FC<PropsWithChildren<{}>>;

const ModalContextProvider: ModalContextProviderType = (props) => {
  const { children } = props;

  const [state, dispatch] = useReducer<Reducer>(reducer, initialValue);

  return <ModalContext.Provider value={{ state, dispatch }}>{children}</ModalContext.Provider>;
};

export { ModalContext, ModalContextProvider };
export type { ModalContextProviderType };
