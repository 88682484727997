import React from 'react';

import styles from './RegistrationMark.css';

import Tooltip from 'Atoms/Tooltip/Tooltip';
import stubImg from 'Images/general/registration_mark.svg';

interface Props {
  size?: 'sm';
  tooltipKey?: string;
}

const RegistrationMark: React.FC<Props> = (props) => {
  const { size, tooltipKey } = props;

  return (
    <Tooltip
      id={`registartionMark_${tooltipKey || ''}`}
      tooltipMsg="general.registration_mark"
      place="top"
    >
      <img src={stubImg} className={`${styles.icon} ${size ? styles[size] : ''}`} />
    </Tooltip>
  );
};

export default RegistrationMark;
