import React, { useState, useEffect, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useIntl } from 'react-intl';
import classnames from 'classnames';

import { BriefContext } from '../../../../Brief.Context';
import Field from '../../components/Field/Field';
import RangeField from '../../components/RangeField/RangeField';
import SegmentedControls from '../../components/SegmentedControls/SegmentedControls';
import With from '../../components/With/With';
import Warning from '../../Warning/Warning';

import styles from './CreativeAsset.pcss';
import {
  getMediaTypesList,
  getPostingTypesList,
  getPlacementTypesList,
  getFormatTypesList,
  getPreparednessTypesList,
  getMusicRequiredList,
  getContentTypesList,
  getViewConditions,
  getDefaultDos,
  getDefaultVideoDuration,
  validateAsset,
} from './util';
import AssetPreview from './AssetPreview/AssetPreview';
import References from './References/References';

import { amplitude } from 'Helpers/amplitude';
import usePrevious from 'Hooks/usePrevious';
import updateBriefCreative from 'Mutations/UpdateBriefCreative.Mutation';
import cloneBriefCreative from 'Mutations/CloneBriefCreative.Mutation';
import { compareDates } from 'Util/dateCreator';
import Text from 'Atoms/Text/Text';
import Button from 'Atoms/Button/Button';
import { CREATIVE_CONTENT_TYPE_FAQ } from 'Constants/general';
import { modalName } from 'Types/modals';
import { CreativeAsset_briefCreative$key } from 'GraphTypes/CreativeAsset_briefCreative.graphql';
import {
  CampaignType,
  CampaignPlatform,
  CampaignObjective,
} from 'GraphTypes/CreativeAssets_campaign.graphql';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

interface Props {
  briefId: string;
  campaignId: string;
  briefCreative: CreativeAsset_briefCreative$key;
  campaignType?: CampaignType | null;
  campaignPlatform?: CampaignPlatform | null;
  campaignObjective?: CampaignObjective | null;
  defaultOpened?: boolean;
  postingRequired?: boolean;
  creativesWithPosting?: number;
  onOpenChange: (id: string) => void;
}

const CreativeAsset: React.FC<Props> = (props) => {
  const {
    briefCreative,
    briefId,
    campaignId,
    campaignType,
    campaignPlatform,
    campaignObjective,
    defaultOpened = false,
    postingRequired,
    creativesWithPosting,
    onOpenChange,
  } = props;

  const data = useFragment(
    graphql`
      fragment CreativeAsset_briefCreative on BriefCreative {
        id
        dos
        donts
        postCaption
        mediaType
        publishingRequired
        v2ContentPlacement
        contentFormat
        videoDurationMin
        videoDurationMax
        contentPreparedness
        contentMusic
        contentMusicDescription
        contentType
        v2ContentType
        contentAmount
        createdAt
        ...References_briefCreative
        ...AssetPreview_briefCreative
      }
    `,
    briefCreative
  );

  const previousObjective = usePrevious(campaignObjective);
  const previousPlatform = usePrevious(campaignPlatform);

  const [isOpened, setOpened] = useState(defaultOpened);

  const [briefState, briefDispatch] = useContext(BriefContext);
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const intl = useIntl();

  const briefCreativeId = data?.id;
  const dos = data?.dos;
  const donts = data?.donts;
  const postCaption = data?.postCaption;
  const mediaType = data?.mediaType;
  const publishingRequired = data?.publishingRequired;
  const v2ContentPlacement = data?.v2ContentPlacement;
  const contentFormat = data?.contentFormat;
  const videoDurationMin = data?.videoDurationMin;
  const videoDurationMax = data?.videoDurationMax;
  const contentPreparedness = data?.contentPreparedness;
  const contentMusic = data?.contentMusic;
  const contentMusicDescription = data?.contentMusicDescription;
  const v2ContentType = data?.v2ContentType;
  const contentAmount = data?.contentAmount;
  const createdAt = data?.createdAt;

  const previousContentFormat = usePrevious(contentFormat);
  const previousBriefCreative = usePrevious(data);

  const isNoPostingDisabled =
    postingRequired && publishingRequired ? creativesWithPosting === 1 : false;

  const postingReqiredWarning =
    postingRequired && !publishingRequired && creativesWithPosting === 0;

  useEffect(() => {
    const currentCreativeAssetValid = briefState.creativeAssets[briefCreativeId];
    if (currentCreativeAssetValid === undefined || isValid !== currentCreativeAssetValid) {
      briefDispatch({
        key: 'creativeAssets',
        value: { ...briefState.creativeAssets, [briefCreativeId]: isValid },
      });
    }
  }, [data]);

  useEffect(() => {
    if (compareDates(createdAt, '2022-05-20T15:02:00+03:00', true)) {
      return;
    }
    const prevDurationData =
      previousBriefCreative &&
      getDefaultVideoDuration({
        creative: previousBriefCreative,
        campaignPlatform: previousPlatform,
        newContentFormat: previousContentFormat,
      });

    if (
      (!videoDurationMin && !videoDurationMax) ||
      (prevDurationData?.videoDurationMin === videoDurationMin &&
        prevDurationData?.videoDurationMax === videoDurationMax)
    ) {
      const newDurationData = getDefaultVideoDuration({
        creative: data,
        campaignPlatform,
        newContentFormat: contentFormat,
      });
      if (!newDurationData) return;
      updateBriefCreative({ id: briefCreativeId, ...newDurationData });
    }
  }, [data, campaignPlatform]);

  useEffect(() => {
    const prevDosDefault = getDefaultDos({
      contentType: v2ContentType,
      campaignObjective: previousObjective,
      intl,
    });
    if (prevDosDefault === dos) {
      const newDosDefault = getDefaultDos({ contentType: v2ContentType, campaignObjective, intl });
      updateBriefCreative({ id: briefCreativeId, dos: newDosDefault });
    }
  }, [campaignObjective]);

  if (!data) return null;

  const mediaTypesList = getMediaTypesList();
  const postingTypesList = getPostingTypesList({
    campaignId,
    isNoPostingDisabled,
  });
  const placementTypesList = getPlacementTypesList();
  const formatTypesList = getFormatTypesList();
  const preparednessTypesList = getPreparednessTypesList({ campaignId });
  const musicRequiredList = getMusicRequiredList();
  const contentTypesList = getContentTypesList({ campaignId });

  const isValid = validateAsset({ creative: data, campaignPlatform, campaignType });

  const viewConditions = getViewConditions({
    creative: data,
    campaignType,
    campaignPlatform,
  });

  const handleCreativeChange = (data: any) => {
    if (data.donts) {
      amplitude.sendEvent({
        id: '84',
        category: 'brief',
        name: 'creative_assets_dont_fill',
        param: { campaignId },
      });
    }
    if (data.postCaption) {
      amplitude.sendEvent({
        id: '85',
        category: 'brief',
        name: 'creative_assets_caption_fill',
        param: { campaignId },
      });
    }
    if (data.contentPreparedness === 'RAW') {
      updateBriefCreative({
        id: briefCreativeId,
        contentMusic: false,
        contentMusicDescription: null,
        ...data,
      });

      return;
    }
    if (data.mediaType === 'PHOTO' && mediaType === 'VIDEO') {
      updateBriefCreative({
        id: briefCreativeId,
        videoDurationMin: null,
        videoDurationMax: null,
        v2ContentType: null,
        contentPreparedness: null,
        contentMusic: false,
        contentMusicDescription: null,
        ...data,
      });

      return;
    }
    updateBriefCreative({ id: briefCreativeId, ...data });
  };

  const handleVideoDurationChange = (data: {
    videoDurationMin?: string;
    videoDurationMax?: string;
  }) => {
    // eslint-disable-next-line no-prototype-builtins
    const newVideoDurationMin = data.hasOwnProperty('videoDurationMin')
      ? Number(data.videoDurationMin)
      : undefined;
    // eslint-disable-next-line no-prototype-builtins
    const newVideoDurationMax = data.hasOwnProperty('videoDurationMax')
      ? Number(data.videoDurationMax)
      : undefined;
    if (newVideoDurationMin === undefined && newVideoDurationMax === undefined) return;
    updateBriefCreative({
      id: briefCreativeId,
      videoDurationMin: newVideoDurationMin,
      videoDurationMax: newVideoDurationMax,
    });
  };

  const hanleContentTypeChange = (data: any) => {
    const newContentType = data.v2ContentType;
    const currentDos = getDefaultDos({ contentType: v2ContentType, campaignObjective, intl });
    if (currentDos === dos || !dos) {
      const newDosText = getDefaultDos({ contentType: newContentType, campaignObjective, intl });
      updateBriefCreative({ id: briefCreativeId, v2ContentType: newContentType, dos: newDosText });
    } else {
      updateBriefCreative({ id: briefCreativeId, v2ContentType: newContentType });
    }
  };

  const handleFormatChange = (data: any) => {
    const newFormat = data.contentFormat;
    updateBriefCreative({ id: briefCreativeId, contentFormat: newFormat });
  };

  const handleCreativeDelete = () => {
    briefDispatch({
      key: 'creativeAssets',
      value: { ...briefState.creativeAssets, [briefCreativeId]: null },
    });
  };

  const handleAssetDelete = () => {
    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: {
        name: modalName.DELETE_BRIEF_CREATIVE,
        attach: {
          briefCreativeId,
          briefId,
          callback: handleCreativeDelete,
        },
      },
    });
  };

  const handleAssetCopy = () => {
    cloneBriefCreative({ briefId, briefCreativeId });
  };

  const handleAssetOpen = () => {
    onOpenChange(briefCreativeId);
    setOpened(true);
  };

  const handleAssetSave = () => {
    onOpenChange(briefCreativeId);
    setOpened(false);
    amplitude.sendEvent({
      id: '82',
      category: 'brief',
      name: 'creative_asset_save',
      param: { campaignId },
    });
  };

  const assetContent = (
    <div>
      <Field
        title="brief_template.creative_asset.media_type.title"
        description="brief_template.creative_asset.media_type.descr"
      >
        <SegmentedControls
          id="mediaType"
          currentValue={mediaType}
          items={mediaTypesList}
          onChange={handleCreativeChange}
        />
      </Field>
      <With condition={viewConditions.amount}>
        <Field
          element="number"
          name="contentAmount"
          title="brief_template.creative_asset.amount.title"
          description="brief_template.creative_asset.amount.descr"
          elementData={{
            value: contentAmount,
            onChange: handleCreativeChange,
          }}
        />
      </With>
      <With condition={viewConditions.publishing}>
        <Field
          title="brief_template.creative_asset.posting.title"
          description="brief_template.creative_asset.posting.descr"
        >
          <>
            <SegmentedControls
              id="publishingRequired"
              currentValue={publishingRequired}
              items={postingTypesList}
              onChange={handleCreativeChange}
            />
            {isNoPostingDisabled && publishingRequired && (
              <Warning
                titleMsg="brief_template.creative_asset.posting.no_posting"
                className={styles.noPostingWarning}
              />
            )}
            {postingReqiredWarning && (
              <Warning
                titleMsg="brief_template.creative_asset.posting.no_posting_required"
                error
                className={styles.noPostingWarning}
              />
            )}
          </>
        </Field>
      </With>
      <With condition={viewConditions.contentPlacement}>
        <Field
          title="brief_template.creative_asset.placement.title"
          description="brief_template.creative_asset.placement.descr"
        >
          <SegmentedControls
            id="contentPlacement"
            currentValue={v2ContentPlacement}
            items={placementTypesList}
            onChange={handleCreativeChange}
          />
        </Field>
      </With>
      <With condition={viewConditions.format}>
        <Field
          title="brief_template.creative_asset.format.title"
          description="brief_template.creative_asset.format.descr"
        >
          <SegmentedControls
            id="contentFormat"
            currentValue={contentFormat}
            items={formatTypesList}
            onChange={handleFormatChange}
          />
        </Field>
      </With>
      <With condition={viewConditions.duration}>
        <RangeField
          title="brief_template.creative_asset.video_duration.title"
          description="brief_template.creative_asset.video_duration.descr"
          minFiedlData={{
            name: 'videoDurationMin',
            element: 'input',
            elementData: {
              defaultValue: videoDurationMin,
              type: 'number',
              additionalComponent: <Text color="grayDog" size="sm" text="sec" />,
              onBlur: handleVideoDurationChange,
            },
          }}
          maxFiedlData={{
            name: 'videoDurationMax',
            element: 'input',
            elementData: {
              defaultValue: videoDurationMax,
              type: 'number',
              additionalComponent: <Text color="grayDog" size="sm" text="sec" />,
              onBlur: handleVideoDurationChange,
            },
          }}
        />
      </With>
      <With condition={viewConditions.duration}>
        <Field
          title="brief_template.creative_asset.preparedness.title"
          description="brief_template.creative_asset.preparedness.descr"
        >
          <SegmentedControls
            id="contentPreparedness"
            currentValue={contentPreparedness}
            items={preparednessTypesList}
            onChange={handleCreativeChange}
          />
        </Field>
      </With>
      <With condition={viewConditions.music}>
        <Field
          title="brief_template.creative_asset.music.title"
          description="brief_template.creative_asset.music.descr"
        >
          <SegmentedControls
            id="contentMusic"
            currentValue={contentMusic}
            items={musicRequiredList}
            onChange={handleCreativeChange}
          />
        </Field>
      </With>
      <With condition={viewConditions.musicDetails}>
        <Field
          element="input"
          name="contentMusicDescription"
          title="brief_template.creative_asset.music_requirements.title"
          description="brief_template.creative_asset.music_requirements.descr"
          elementData={{
            multiple: true,
            defaultValue: contentMusicDescription,
            onBlur: handleCreativeChange,
            textAreaData: { minRows: 1, maxRows: 3 },
          }}
        />
      </With>
      <With condition={viewConditions.contentType}>
        <Field
          title="brief_template.creative_asset.content_type.title"
          description="brief_template.creative_asset.content_type.descr"
          descriptionLinkData={{
            msg: 'brief_template.field.read_more.ver_2',
            path: CREATIVE_CONTENT_TYPE_FAQ,
          }}
        >
          <SegmentedControls
            id="v2ContentType"
            currentValue={v2ContentType}
            items={contentTypesList}
            itemClassName={styles.contentType}
            onChange={hanleContentTypeChange}
          />
        </Field>
      </With>
      <With condition={viewConditions.dos}>
        <Field
          element="input"
          name="dos"
          title="brief_template.creative_asset.dos.title"
          description="brief_template.creative_asset.dos.descr"
          elementData={{
            multiple: true,
            defaultValue: dos,
            onBlur: handleCreativeChange,
            textAreaData: { minRows: 3, maxRows: 8 },
            dataTest: 'input:whatShouldCreatorsDo',
          }}
        />
      </With>
      <With condition={viewConditions.donts}>
        <Field
          element="input"
          name="donts"
          title="brief_template.creative_asset.donts.title"
          description="brief_template.creative_asset.donts.descr"
          elementData={{
            multiple: true,
            defaultValue: donts,
            onBlur: handleCreativeChange,
            textAreaData: { minRows: 1, maxRows: 3 },
          }}
          isOptional={true}
        />
      </With>
      <With condition={viewConditions.caption}>
        <Field
          element="input"
          name="postCaption"
          title="brief_template.creative_asset.caption.title"
          description="brief_template.creative_asset.caption.descr"
          elementData={{
            multiple: true,
            defaultValue: postCaption,
            onBlur: handleCreativeChange,
            textAreaData: { minRows: 1, maxRows: 3 },
          }}
          isOptional={true}
        />
      </With>
      <With condition={viewConditions.references}>
        <References briefCreative={data} campaignId={campaignId} />
      </With>
      <div className={styles.controls}>
        <Button color="normal" iconName="deleteIcon" size="lg" onClick={handleAssetDelete} />
        <Button color="normal" iconName="copy2" size="lg" onClick={handleAssetCopy} />
        <Button
          width="full"
          size="lg"
          msg="brief_template.creative_asset.save"
          dataTest="button:saveCreative"
          onClick={handleAssetSave}
        />
      </div>
    </div>
  );

  const previewContent = (
    <AssetPreview
      briefCreative={data}
      isValid={!!isValid}
      onAssetCopy={handleAssetCopy}
      onAssetOpen={handleAssetOpen}
      onAssetDelete={handleAssetDelete}
    />
  );

  return (
    <div
      className={classnames(styles.root, {
        [styles.opened]: isOpened,
        [styles.notValid]: !isOpened && !isValid,
      })}
    >
      <div className={styles.fullView}>{assetContent}</div>
      <div className={styles.preview}>{previewContent}</div>
    </div>
  );
};

export default CreativeAsset;
