import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { BrowserView, isTablet } from 'react-device-detect';
import track, { useTracking } from 'react-tracking';
import { QueryRenderer, graphql } from 'react-relay';

import User from '../User/User';
import Help from '../Help/Help';
import Balance from '../Balance/Balance';
import ContractorBanner from '../ContractorBanner/ContractorBanner';

import styles from './ContractorHeader.css';
import { createMenuList } from './util';

import environment from 'Api/Environment';
import Logo from 'Atoms/Logo/Logo';
import Text from 'Atoms/Text/Text';
import Avatar from 'Atoms/Avatar/Avatar';
import Icon from 'Atoms/Icon/Icon';
import { CONTRACTOR_RATING_INFO, DASHBOARD_ROUTE } from 'Constants/general';
import { ContractorHeaderQuery as QueryType } from 'GraphTypes/ContractorHeaderQuery.graphql';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import { Auth0Context } from 'Containers/Auth0/Auth0Context';
import { amplitude } from 'Helpers/amplitude';
import { modalName } from 'Types/modals';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

const ContractorHeaderQuery = graphql`
  query ContractorHeaderQuery {
    currentUser {
      email
      type
      rating {
        averageScore
      }
      contractorProfile {
        creatorAgent
        firstName
        lastName
      }
      paymentAccount {
        balance
        currency
      }
    }
  }
`;

const ContractorHeader = () => {
  const tracking = useTracking();
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const { logout } = useContext(Auth0Context);

  return (
    <QueryRenderer<QueryType>
      environment={environment}
      query={ContractorHeaderQuery}
      variables={{}}
      render={({ props: queryProps }) => {
        if (!queryProps) return null;

        const user = queryProps.currentUser;

        if (!user) return null;

        const email = user.email;
        const type = user.type;
        const balance = user.paymentAccount?.balance;
        const currency = user.paymentAccount?.currency;
        const averageScore = user.rating?.averageScore;

        const profile = user.contractorProfile;
        const isAgent = profile?.creatorAgent;

        const label =
          profile?.firstName && profile.lastName
            ? `${profile.firstName} ${profile.lastName}`
            : email;

        const menuList = createMenuList();

        const ava = <Avatar size="40" letters={label} dataTest="avatar:header:contextMenu" />;

        const getCallback = (item: any) => {
          const { id, callback } = item;
          switch (callback) {
            case 'logout':
              return () => {
                tracking.trackEvent({
                  element: 'main_menu',
                  event: 'clicked',
                  payload: { value: 'logout' },
                });
                logout();
              };
            case 'download_app_popup': {
              return () => {
                modalDispatch({
                  type: ModalTypes.SET_MODAL,
                  payload: { name: modalName.DOWNLOAD_APP, attach: {} },
                });
              };
            }

            default:
              return () => {
                tracking.trackEvent({
                  element: 'main_menu',
                  event: 'clicked',
                  payload: { value: id },
                });
              };
          }
        };

        const finalMenuList = () => {
          return menuList.map((item: any) => {
            const { callback } = item;
            if (callback) {
              const onClick = getCallback(item);

              return { ...item, onClick };
            }

            return { ...item };
          });
        };

        const createUserContent = () => {
          return (
            <div className={styles.userContent}>
              <div className={styles.userWrap}>
                {ava}
                <div className={styles.names}>
                  <Text
                    weight="500"
                    text={`${profile?.firstName || ''} ${profile?.lastName || ''}`}
                  />
                  <Text size="sm" color="grayDog" text={email} />
                </div>
              </div>
              {!isAgent && (
                <SmartLink
                  path={CONTRACTOR_RATING_INFO}
                  target="_blank"
                  className={styles.ratingWrap}
                >
                  <Text msg="menu.creator_rating" />
                  <div className={styles.rating}>
                    <Icon
                      size="sm"
                      name="starFull"
                      color={averageScore ? 'yellow' : undefined}
                      className={styles.ratingIcon}
                    />
                    <Text
                      size="sm"
                      weight="500"
                      text={averageScore ? averageScore.toFixed(2) : '–'}
                    />
                  </div>
                </SmartLink>
              )}
            </div>
          );
        };

        const onLogoClick = () => {
          amplitude.sendEvent({
            id: 237,
            category: 'pageview',
            name: 'logo_insense_click',
            param: {
              referral_url: DASHBOARD_ROUTE,
            },
          });
        };

        return (
          <>
            <ContractorBanner />

            <div className={styles.root}>
              <div className={styles.wrapperWithPadding}>
                <Link to={DASHBOARD_ROUTE} onClick={onLogoClick}>
                  <Logo />
                </Link>
                <div className={styles.wrapper}>
                  <div className={styles.item}>
                    <Help isContractor />
                  </div>

                  {!isTablet && (
                    <BrowserView className={styles.item}>
                      <Balance userType={type} balance={balance} currency={currency} />
                    </BrowserView>
                  )}
                  <User
                    label={label}
                    menuContent={createUserContent()}
                    menuList={finalMenuList()}
                  />
                </div>
              </div>
            </div>
          </>
        );
      }}
    />
  );
};

export default track()(ContractorHeader);
