import React, { useContext } from 'react';

import styles from './SearchCounter.pcss';

import { DiscoveryCreatorsContext } from 'Page/advertiser/Discovery/DiscoveryCreatorsContext';
import Text from 'Components/ui/Text/Text';

const DiscoverySearchCounter = () => {
  const { counter } = useContext(DiscoveryCreatorsContext);
  if (!counter.total) {
    return null;
  }
  return (
    <Text type="md" className={styles.root} text={`1-${counter.loaded} of ${counter.total}`} />
  );
};

export default DiscoverySearchCounter;
