import React, { PropsWithChildren, useEffect, useState } from 'react';

import {
  DiscoveryCreatorsContext,
  DiscoveryCreatorsContextType,
  DiscoveryCreatorsCounterData,
  DiscoverySelectedCreator,
} from './DiscoveryCreatorsContext';
import { INSTAGRAM, TIKTOK, CONTENT } from 'Constants/general';
import { useAddCreatorsToCustomListMutation } from 'Api/mutations/AddCreatorsToCustomList.Mutation';
import { DiscoverySearchSectionQuery$data } from 'GraphTypes/DiscoverySearchSectionQuery.graphql';
import { useRemoveCreatorFromCustomListMutation } from 'Api/mutations/RemoveCreatorFromCustomList.Mutation';

type Props = {
  listId: string;
  isCreating: boolean;
  backUrl?: string;
};

const defaultParams = {
  ageRanges: [],
  audienceGenderId: null,
  audienceGenderPercentage: null,
  audienceAgeRangePercentage: null,
  audienceCountryPercentage: null,
  audienceCityPercentage: null,
  audienceInterestPercentage: null,
  audienceEthnicities: [],
  textQuery: null,
  hashtags: [],
  cities: [],
  categories: [],
  tags: [],
  creatorEthnicities: [],
  audienceCities: [],
  creatorGenderId: null,
  countries: [],
  creatorCountries: [],
  categoryIds: [],
  creatorLanguages: [],
  creatorTypes: [INSTAGRAM, TIKTOK, CONTENT],
  audienceEthnicity: [],
  creatorEthnicity: [],
  interests: [],
  creatorBirthdateFrom: null,
  creatorBirthdateTo: null,
  followersFrom: null,
  followersTo: null,
  realFollowersFrom: null,
  realFollowersTo: null,
  postPriceFrom: null,
  postPriceTo: null,
  engagementRateFrom: null,
  engagementRateTo: null,
  viewsPerPostTo: null,
  viewsPerPostFrom: null,
  viewsMedianFrom: null,
  viewsMedianTo: null,
  storiesReachFrom: null,
  storiesReachTo: null,
  postsReachFrom: null,
  postsReachTo: null,
  reelsPlaysMedianFrom: null,
  reelsPlaysMedianTo: null,
  reviewedAtFrom: null,
  reviewedAtTo: null,
  campaignsCompletionsCountFrom: null,
  campaignsCompletionsCountTo: null,
  favorite: null,
  contentCreator: null,
  owned: null,
  onlyCreatorsWorkedWith: null,
  active: null,
  paidSocialEnabled: null,
  includeHidden: null,
  acceptsBarter: null,
  excludeBadges: null,
  strictlyByPrimaryCategory: null,
  includeAmazonStorefrontLink: null,
  includeAmazonStorefrontValue: [],
  withTiktokShop: null,
  withTiktokShopValue: [],
  employed: null,
  badges: [],
  creatorBadgeIds: [],
  exclusions: [],
  creatorIdsToExclude: [],
  creatorAnimals: [],
  creatorHairTypes: [],
  creatorBodyTypes: [],
  creatorTagIds: [],
  creatorLanguageIds: [],
  creatorAnimalIds: [],
  creatorHairTypeIds: [],
  currency: 'USD',
};

const defualtComputedParams = {
  ...defaultParams,
  // ...parseSearchQuery(searchQuery),
};

const DiscoveryCreatorsProvider: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, listId, isCreating, backUrl } = props;
  const [campaignId, setCampaignId] = useState(undefined);
  const [organizationId, setOrganizationId] = useState(undefined);
  const [admin, setAdminStatus] = useState(false);
  const [params, updateParams] = useState(defualtComputedParams);
  const [queryParams, updateQueryParams] = useState(defualtComputedParams);
  const [selectedCreators, setSelectedCreators] = useState<DiscoverySelectedCreator[]>([]);
  const [listData, setListData] = useState<DiscoverySearchSectionQuery$data['customList']>(null);
  const [counter, setCounterData] = useState<DiscoveryCreatorsCounterData>({
    loaded: null,
    total: null,
  });

  const isCreatingList = Boolean(!listData?.id);

  const [addCreatorsToCustomList, isImportCreatorsInProgress] =
    useAddCreatorsToCustomListMutation();

  const [removeCreatorFromCustomList] = useRemoveCreatorFromCustomListMutation(organizationId);

  useEffect(() => {
    updateQueryParams({
      ...parseSearchQuery(params),
    });
  }, [params]);

  useEffect(() => {
    if (listData?.creators?.totalCount) {
      const creators = listData.creators.edges?.map((creator) => creator?.node);
      if (creators && creators?.length) {
        setSelectedCreators(creators);
      }
    }
  }, [listData]);

  const addCreatorToList = (creator: DiscoverySelectedCreator) => {
    setSelectedCreators((creators) => {
      return [...creators, creator];
    });
  };

  const removeCreatorFromList = (creatorId: string) => {
    setSelectedCreators((creators) => {
      return creators.filter((creator) => creator.id !== creatorId);
    });
    if (!isCreatingList) {
      removeCreatorFromCustomList({
        variables: {
          input: {
            creatorId,
            customListId: listId,
          },
        },
      });
    }
  };

  const importCreatorsToCustomList = (callback) => {
    const creatorIds = selectedCreators.map((creator) => creator.id);
    addCreatorsToCustomList({
      variables: {
        input: {
          creatorIds,
          customListId: listId,
        },
      },
      onCompleted: (response, error) => {
        if (response.addCreatorsToCustomList?.__typename === 'AddCreatorsToCustomListPayload') {
          callback({
            success: true,
          });
        } else {
          callback({
            success: false,
          });
        }
      },
      onError: () => {
        callback({
          success: false,
        });
      },
    });
  };

  const contextValue: DiscoveryCreatorsContextType = {
    campaignId,
    params,
    admin,
    queryParams,
    updateParams,
    organizationId,
    setOrganizationId,
    setAdminStatus,
    selectedCreators,
    removeCreatorFromList,
    addCreatorToList,
    importCreatorsToCustomList,
    isImportCreatorsInProgress,
    listId,
    isCreatingList,
    listData,
    setListData,
    backUrl,
    setCounterData,
    counter,
  };
  return (
    <DiscoveryCreatorsContext.Provider value={contextValue}>
      {children}
    </DiscoveryCreatorsContext.Provider>
  );
};

export { DiscoveryCreatorsProvider };

const parseSearchQuery = (
  query: NonNullable<MediaplanQuery$data['campaign']>['searchQuery']
): SearchQueryInput => {
  const result = {
    ...query,
    creatorAnimalIds: query?.creatorAnimals?.map((item) => item.id) || [],
    ageRangeIds: query?.ageRanges?.map((item) => item?.id) || [],
    audienceCitiesIds: query?.audienceCities?.map((item) => item?.id) || [],
    creatorCountryIds: query?.creatorCountries?.map((item) => item?.id) || [],
    audienceEthnicityIds: query?.audienceEthnicities?.map((item) => item?.id) || [],
    creatorLanguageIds: query?.creatorLanguages?.map((item) => item?.id) || [],
    categoryIds: query?.categories?.map((item) => item?.id) || [],
    creatorEthnicityIds: query?.creatorEthnicities?.map((item) => item?.id) || [],
    interestIds: query?.interests?.map((item) => item?.id) || [],
    creatorBodyTypeIds: query?.creatorBodyTypes?.map((item) => item?.id) || [],
    creatorHairTypeIds: query?.creatorHairTypes?.map((item) => item?.id) || [],
    creatorTagIds: query?.tags?.map((item) => item?.id) || [],
    creatorGenderId: query?.creatorGender?.id,
    audienceGenderId: query?.audienceGender?.id,
    customListIds: query?.customLists?.map((item) => item?.id) || [],
    cityIds: query?.cities?.map((item) => item?.id) || [],
    countryIds: query?.countries?.map((item) => item?.id) || [],
  };
  delete result.audienceEthnicity;
  delete result.creatorEthnicity;
  delete result.includeAmazonStorefrontValue;
  delete result.withTiktokShopValue;
  delete result.badges;
  delete result.creatorAnimals;
  delete result.ageRanges;
  delete result.audienceCities;
  delete result.creatorCountries;
  delete result.creatorGender;
  delete result.audienceEthnicities;
  delete result.creatorLanguages;
  delete result.categories;
  delete result.creatorEthnicities;
  delete result.interests;
  delete result.creatorBodyTypes;
  delete result.creatorHairTypes;
  delete result.audienceGender;
  delete result.tags;
  delete result.cities;
  delete result.customLists;
  delete result.countries;
  return result;
};
