import React, { Suspense, useContext } from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import track from 'react-tracking';
import { matchRoutes, useLocation } from 'react-router-dom';
import classnames from 'classnames';

import styles from './Header.pcss';
import ContractorHeader from './ContractorHeader/ContractorHeader';
import AdvertiserHeader from './AdvertiserHeader/AdvertiserHeader';
import UnknownUserHeader from './UnknownUserHeader/UnknownUserHeader';

import ls from 'Util/localStorage';
import environment from 'Api/Environment';
import { HeaderQuery as QueryType, UserType } from 'GraphTypes/HeaderQuery.graphql';
import { ADVERTISER, CONTRACTOR, UNKNOWN } from 'Constants/general';
import { Auth0Context } from 'Containers/Auth0/Auth0Context';

const HeaderQuery = graphql`
  query HeaderQuery {
    currentUser {
      email
      type
      advertiserProfile {
        firstName
        lastName
      }
      contractorProfile {
        firstName
        lastName
      }
    }
  }
`;

interface Props {
  className?: string;
  showStaticHeader?: boolean;
  classes?: {
    root?: string;
    wrap?: string;
  };
}

const routesForExclusion = [{ path: '/campaigns/:id/brief' }];

const Header: React.FC<Props> = (props) => {
  const { className, classes, showStaticHeader } = props;
  const location = useLocation();
  const match = matchRoutes(routesForExclusion, location);
  const { user } = useContext(Auth0Context);

  return (
    <QueryRenderer<QueryType>
      environment={environment}
      query={HeaderQuery}
      variables={{}}
      fetchPolicy="store-and-network"
      render={({ props: queryProps }) => {
        if (!queryProps) return null;

        const currentUser = queryProps.currentUser;

        if (!currentUser) return null;

        const { type } = currentUser;

        if (user?.email) {
          ls.set('email', user.email);
        }

        ls.set('userType', type);

        const headers: Record<string, React.ReactNode> = {
          STATIC: (
            <div className={styles.content}>
              <UnknownUserHeader hideUserMenu />
            </div>
          ),
          [ADVERTISER]: (
            <Suspense fallback={null}>
              <div className={styles.content}>
                <AdvertiserHeader />
              </div>
            </Suspense>
          ),
          [UNKNOWN]: (
            <div className={styles.content}>
              <UnknownUserHeader />
            </div>
          ),
          [CONTRACTOR]: (
            <div className={styles.contractorHeaderContent}>
              <ContractorHeader />
            </div>
          ),
        };

        return (
          <header
            className={classnames(styles.root, className, classes?.root, {
              // [styles.hidden]: !!match,
              [styles.contractor]: Boolean(type === CONTRACTOR),
            })}
          >
            <div className={classnames(styles.wrap, classes?.wrap)}>
              {showStaticHeader ? headers.STATIC : headers[type]}
            </div>
          </header>
        );
      }}
    />
  );
};

export default track({
  section: 'header',
})(Header);
