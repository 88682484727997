import React, { useState, Suspense } from 'react';

import { SubscriptionParams } from 'Page/advertiser/BillingPlans/BillingPlans';
import ConfirmNewPlan from 'Modal/advertiser/ConfirmNewPlan/ConfirmNewPlan';
import ChangeAddonsDrawer from 'Modal/advertiser/ChangeAddonsDrawer/ChangeAddonsDrawer';
import { IconName } from 'Components/ui/types';
import { AddonSettings } from 'Page/advertiser/BillingPlans/data';
import { SubscriptionLimits_organization$data } from 'GraphTypes/SubscriptionLimits_organization.graphql';
import { BrandsLimitQuery$data } from 'GraphTypes/BrandsLimitQuery.graphql';
import { OrganizationTeamQuery$data } from 'GraphTypes/OrganizationTeamQuery.graphql';
import { WlSlotsBanner_campaign$data } from 'GraphTypes/WlSlotsBanner_campaign.graphql';

interface Props {
  limitExcceded?: boolean;
  addons: AddonSettings[];
  totalCount?: number | null;
  maxCount?: number | null;
  type: 'maxActiveBrands' | 'maxSeats' | 'maxBcaConnections';
  successCallback?: () => void;
  successActionTitle?: string;
  disabledManage?: boolean;
  subscriptionData:
    | SubscriptionLimits_organization$data['subscription']
    | NonNullable<NonNullable<BrandsLimitQuery$data['currentUser']>['organization']>['subscription']
    | NonNullable<
        NonNullable<OrganizationTeamQuery$data['currentUser']>['organization']
      >['subscription']
    | NonNullable<WlSlotsBanner_campaign$data['organization']>['subscription'];
  defaultLimit?: number | null;
}

export const iconManageMap = {
  maxActiveBrands: 'Planet',
  maxSeats: 'Profile',
  maxBcaConnections: 'Paid-ads',
};

const Limit: React.FC<Props> = (props) => {
  const {
    type,
    addons,
    maxCount,
    subscriptionData,
    disabledManage = false,
    defaultLimit,
    limitExcceded,
    successCallback,
    successActionTitle,
  } = props;

  const [buyPlanDrawerVisible, setBuyPlanDrawerVisible] = useState(false);
  const [paramsForDrawer, setParamsForDrawer] = useState<SubscriptionParams>();

  const handleBuyPlanDrawerClose = () => {
    setBuyPlanDrawerVisible(false);
  };

  const handleParamsChange = (params: SubscriptionParams) => {
    setParamsForDrawer(params);
    setBuyPlanDrawerVisible(true);
  };

  const handleSuccess = () => {
    successCallback?.();
    setBuyPlanDrawerVisible(false);
  };

  return (
    <>
      <ChangeAddonsDrawer
        icon={iconManageMap[type] as IconName}
        type={type}
        addons={addons}
        limitExcceded={limitExcceded}
        disabledManage={disabledManage || maxCount === null}
        defaultValue={maxCount === null ? 0 : maxCount}
        defaultLimit={defaultLimit}
        subscriptionData={subscriptionData}
        onParamsChange={handleParamsChange}
      />
      {paramsForDrawer && buyPlanDrawerVisible ? (
        <Suspense fallback={null}>
          <ConfirmNewPlan
            {...paramsForDrawer}
            opened={buyPlanDrawerVisible}
            onClose={handleBuyPlanDrawerClose}
            successCallback={handleSuccess}
            successActionTitle={successActionTitle}
          />
        </Suspense>
      ) : null}
    </>
  );
};

export default Limit;
