import React from 'react';

import { InviteOutreachCreatorsProvider } from 'Modal/admin/InviteOutreachCreators/InviteOutreachCreatorsProvider';
import InviteOutreachCreators from 'Modal/admin/InviteOutreachCreators/InviteOutreachCreators';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { OUTREACH_LIST_ROUTE } from 'Constants/general';

const CreateOutreachBatch: React.FC = () => {
  const { id: campaignId = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const handleUploadCsv = () => {
    const outreachUrl = generatePath(OUTREACH_LIST_ROUTE, { id: campaignId });
    navigate(outreachUrl);
  };

  return (
    <InviteOutreachCreatorsProvider handleUploadCsv={handleUploadCsv}>
      <InviteOutreachCreators />
    </InviteOutreachCreatorsProvider>
  );
};

export default CreateOutreachBatch;
