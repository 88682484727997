import React, { PropsWithChildren, useContext } from 'react';

import ConnectFb from 'Molecules/ConnectFb/ConnectFb';
import { modalName } from 'Types/modals';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

interface Props {
  projectId: string;
  onCheckingConnectionPossibility?: () => boolean;
  disabled?: boolean;
}

const ActivatePs: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, onCheckingConnectionPossibility, projectId, disabled } = props;

  const { dispatch: modalDispatch } = useContext(ModalContext);

  const handleConnectDone = (socialIdentityId: string) => {
    if (disabled) return;
    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: { name: modalName.ACTIVATE_PS, attach: { socialIdentityId, projectId } },
    });
  };

  return (
    <ConnectFb
      callback={handleConnectDone}
      onCheckingConnectionPossibility={onCheckingConnectionPossibility}
    >
      {children}
    </ConnectFb>
  );
};

export default ActivatePs;
