import React, { MouseEvent, Suspense, useEffect, useState } from 'react';

import styles from './Outreach.pcss';
import InviteOutreachCreators from './InviteOutreachCreators/InviteOutreachCreators';
import { graphql, useLazyLoadQuery, useQueryLoader } from 'react-relay';

import OutreachBatchesControls from './OutreachBatchesControls/OutreachBatchesControls';
import OutreachTable from './OutreachTable/OutreachTable';

import { amplitude } from 'Helpers/amplitude';
import { CampaignPlatform } from 'GraphTypes/CampaignQuery.graphql';
import CreatorsStub from 'AdvertiserPage/Campaign/Creators/CreatorsList/CreatorsStub/CreatorsStub';
import { OutreachBatchesQuery } from 'GraphTypes/OutreachBatchesQuery.graphql';
import { OutreachPreviewQuery } from 'GraphTypes/OutreachPreviewQuery.graphql';
import { useNavigate } from 'react-router-dom';
import { getCampaignOutreachBatchLink } from 'Util/links';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import Icon from 'Components/ui/Icon/Icon';
import Text from 'Components/ui/Text/Text';
import OutreachDashboard from './OutreachDashboard/OutreachDashboard';
import OutreachIntegrations from './OutreachIntegrations/OutreachIntegrations';

interface Props {
  campaignId: string;
  platform?: CampaignPlatform | null;
}

enum OutreachCampaignTabs {
  DASHBOARD,
  CREATORS,
  TEMPLATES,
  INTEGRATIONS,
}

const Outreach: React.FC<Props> = (props) => {
  const { campaignId } = props;

  const [activeTab, setActiveTab] = useState(OutreachCampaignTabs.DASHBOARD);

  const handleSelectTab = (e: MouseEvent<HTMLDivElement>) => {
    const tabIndex = Number(e.currentTarget.getAttribute('data-tab'));
    setActiveTab(tabIndex);
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <OutreachDashboard campaignId={campaignId} />
      </div>
    </div>
  );
};

export default Outreach;
