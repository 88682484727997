import React, { Suspense, PropsWithChildren } from 'react';

import styles from './Content.pcss';

const Content: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;

  return (
    <main id="content" className={styles.root}>
      <Suspense fallback={null}>{children}</Suspense>
    </main>
  );
};

export default Content;
