import React, { useContext } from 'react';

import ls from 'Util/localStorage';
import { amplitude } from 'Helpers/amplitude';
import Text from 'Components/ui/Text/Text';
import RadioButton from 'Components/ui/RadioButton/RadioButton';

import styles from '../InviteOutreachCreators.pcss';
import { InviteOutreachCreatorsContext } from '../InviteOutreachCreatorsContext';
import { OutreachTransport } from 'GraphTypes/ImportOutreachBatchQuery.graphql';

const availableProviders: OutreachTransport[] = ['GMAIL', 'SENDGRID'];

interface Props {}

const ProviderSelector: React.FC<Props> = () => {
  const { transport, setTransportProvider } = useContext(InviteOutreachCreatorsContext);

  return (
    <>
      <Text type="md" msg="invite_outreach_creators_modal.step_3" className={styles.subtitle} />
      <div className={styles.templates}>
        {availableProviders?.map((item) => {
          if (item === 'SENDGRID' && !ls.get('admin')) return null;
          const isActive = transport === item;

          const handleProviderSelect = () => {
            setTransportProvider(item);
            amplitude.sendEvent<442>({
              id: '442',
              category: 'campaign',
              name: 'outreach_select_provider',
              param: {
                provider: item,
              },
            });
          };

          return (
            <div className={styles.template} key={item} onClick={handleProviderSelect}>
              <div className={styles.templateDetails}>
                <div>
                  <RadioButton checked={isActive} />
                </div>
                <Text
                  type="md"
                  msg={`invite_outreach_creators_modal.transport.${item.toLowerCase()}`}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ProviderSelector;
