import debounce from 'lodash/debounce';

export const downloadFile = (url, filename, callback, failCallback, progressCallback) => {
  const request = new XMLHttpRequest();
  request.open('GET', url, true);
  request.responseType = 'blob';
  if (failCallback) {
    request.upload.addEventListener('error', failCallback, false);
  }
  request.onload = () => {
    const blob = request.response;
    const link = document.createElement('a');
    document.body.appendChild(link);
    const blobLink = window.URL.createObjectURL(blob);
    link.href = blobLink;
    link.download = filename;
    link.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(blobLink);
      document.body.removeChild(link);
    }, 0);
    if (callback) {
      callback();
    }
  };
  request.onprogress = (e) => {
    if (progressCallback) {
      progressCallback(e.loaded, e.total);
    }
  };
  request.send();
};

export const downloadFiles = (url, filename, callback, failCallback) => {
  var a = document.createElement('a');
  a.href = url;
  a.target = '_parent';
  a.download = filename;
  document.documentElement.appendChild(a);
  a.click();
  a.parentNode.removeChild(a);
};
