import { TIKTOK_SHOP_AUTH_API } from 'Constants/general';
import ErrorHandler from 'Util/errorHandler';
import { useAuthorizeTiktokShopUserMutation } from 'Api/mutations/AuthorizeTiktokShopUser.Mutation';
import { AuthorizeTiktokShopUserMutation$data } from 'GraphTypes/AuthorizeTiktokShopUserMutation.graphql';

const { CONNECT_CREATOR_URL, CONNECT_URL } = TIKTOK_SHOP_AUTH_API;

enum TiktokShopPostMessageTypeEnum {
  AuthorizeTiktokShop = 'authorizeTiktokShop',
}

type AuthType = 'CREATOR' | 'ADVERTISER';

type GetAuthUrl = (type: AuthType) => URL;
const getAuthUrl: GetAuthUrl = (type) => {
  const url = new URL(type === 'ADVERTISER' ? CONNECT_URL : CONNECT_CREATOR_URL);
  let state = `redirect_uri=${location.origin}/oauth/tiktok/authShop`;
  if (type === 'ADVERTISER') {
    url.searchParams.set('service_id', process.env.TIKTOK_SHOP_APP_ID);
  } else {
    url.searchParams.set('app_key', process.env.TIKTOK_SHOP_CREATOR_APP_KEY);
  }
  url.searchParams.set('state', state);
  return url;
};

type UseTikTokShopAuth = (type?: AuthType) => HookResult;
const useTikTokShopAuth: UseTikTokShopAuth = (type = 'ADVERTISER') => {
  const [authorizeTiktokShopUser] = useAuthorizeTiktokShopUserMutation();

  const authorize: HookResult['authorize'] = () => {
    return new Promise<TiktokShopAuthDataResponse | null>((resolve) => {
      const url = getAuthUrl(type);
      const handler: EventHandler = (e) => {
        const data: TiktokShopAuthPostMessageData = e.data;
        if (data.postMessageType === TiktokShopPostMessageTypeEnum.AuthorizeTiktokShop) {
          const params: TiktokShopAuthPostMessageData['params'] = e?.data?.params;

          const handleError = async (errorMessage?: string) => {
            ErrorHandler.error('Tiktok Shop authorize error occurred', {
              errorMessage,
              isParamExists: {
                code: Boolean(params.code),
              },
            });
          };

          window.removeEventListener('message', handler, false);
          if (e.data.error || !params.code) {
            handleError();
            resolve({ ...e.data, error: true });
          } else {
            authorizeTiktokShopUser({
              variables: {
                input: {
                  code: params.code,
                },
              },
              onCompleted: (response) => {
                resolve({ data: response.authorizeTiktokShopUser, error: false });
              },
              onError: () => {
                resolve({ error: true });
              },
            });
          }
        }
      };
      window.addEventListener('message', handler, false);

      const width = 800;
      const height = 600;
      const left = (window.screen.width - width) / 2;
      const top = (window.screen.height - height) / 2.5;
      const winOptions = `width=${width},height=${height},left=${left},top=${top}`;
      window.open(url, 'auth', winOptions);

      return;
    });
  };

  return {
    authorize,
  };
};
export { useTikTokShopAuth, TiktokShopPostMessageTypeEnum };

// types

type EventHandler = Parameters<typeof window.addEventListener<'message'>>[1];

export type TiktokShopAuthPostMessageData = {
  error: boolean;
  postMessageType: TiktokShopPostMessageTypeEnum;
  params: {
    code: string;
  };
};

export type TiktokShopAuthDataResponse = {
  error: boolean;
  data?: AuthorizeTiktokShopUserMutation$data['authorizeTiktokShopUser'] | null;
};

type HookResult = {
  authorize: () => Promise<TiktokShopAuthDataResponse | null>;
};
