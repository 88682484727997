import React, { useMemo, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { graphql, useFragment } from 'react-relay';

import styles from './MessageAttachments.pcss';
import MessageMedia from './MessageMedia/MessageMedia';
import { MessageAttachmentsContext } from '../MessageAttachments/MessageAttachmentsContext';

import { amplitude } from 'Helpers/amplitude';
import { timeChatFormat } from 'Util/dateCreator';
import { createBytesSize } from 'Util/numberFormatter';
import {
  MessageAttachments_message$data,
  MessageAttachments_message$key,
} from 'GraphTypes/MessageAttachments_message.graphql';
import Text from 'Components/ui/Text/Text';
import TextButton from 'Components/ui/TextButton/TextButton';
import MediaCarouselContainer from 'Modal/advertiser/MediaCarousel/MediaCarouselContainer';
import MediaCarousel from 'Modal/advertiser/MediaCarousel/MediaCarousel';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import Icon from 'Components/ui/Icon/Icon';

export interface Props {
  message: MessageAttachments_message$key;
  isOwner: boolean;
  withText?: boolean;
  isRead: boolean;
  actionAble: boolean;
  inPopup?: boolean;
  createdAt: string;
  projectId: string;
}
const MessageAttachments: React.FC<Props> = (props) => {
  const { message, isOwner, actionAble, createdAt, isRead, withText, projectId, inPopup } = props;

  const [currentMediaIndex, setCurrenMediaIndex] = useState<number | undefined>();
  const [mediaCarouselOpen, setMediaCarouselOpen] = useState(false);

  const { openDrawer } = useContext(DrawerContext);
  const { setLoadingAll, loadedCount, setLoadedCount, loadingAll } =
    useContext(MessageAttachmentsContext);

  const data = useFragment(
    graphql`
      fragment MessageAttachments_message on ProjectEvent {
        ...MediaCarousel_message
        ... on Message {
          id
          attachments {
            totalCount
            edges {
              node {
                id
                createdAt
                file {
                  type
                  bytes
                  thumbnailUrl
                  filename
                  secureUrl
                  bcaTaggedUrl
                  originalFilename
                  ...MessageMedia_file
                }
              }
            }
          }
        }
      }
    `,
    message
  );

  const totalCount = data.attachments?.totalCount;

  useEffect(() => {
    if (totalCount && loadedCount === totalCount) {
      setLoadedCount(0);
      setLoadingAll(false);
    }
  }, [loadedCount]);

  if (!data) return null;

  const { attachments, id: nodeId } = data;
  const list = attachments?.edges;

  const mediasLength = list?.length || 0;

  if (mediasLength === 0 || !list) return null;

  const fullSize = useMemo(() => {
    const size =
      list.reduce((acc, current) => {
        return acc + (current?.node?.file.bytes || 0);
      }, 0) || 0;
    return createBytesSize(size);
  }, [attachments?.edges]);

  const downloadLabel =
    mediasLength > 1
      ? 'chat.messages.message.download_original_all'
      : 'chat.messages.message.download_original';

  const handleDownload = () => {
    if (!attachments?.edges) return;
    setLoadingAll(true);
    amplitude.sendEvent<436>({
      id: '436',
      category: 'chat',
      name: 'download_all_click',
      param: { project_id: projectId },
    });
  };

  const handleMediaOpen = (currentIndex: number) => {
    setCurrenMediaIndex(currentIndex);
    amplitude.sendEvent<438>({
      id: '438',
      category: 'attachments_drawer',
      name: 'show',
      param: { multiple: Number(totalCount) > 1 },
    });
    if (inPopup) {
      setMediaCarouselOpen(true);
    } else {
      openDrawer(`message-attachments-${nodeId}`);
    }
  };

  const handleCarouselClose = () => {
    setMediaCarouselOpen(false);
  };

  return (
    <div className={styles.root}>
      <div className={styles.medias}>
        {attachments?.edges?.map((item, index) => {
          if (!item?.node) return null;

          const attachmentId = item.node.id;

          return (
            <MessageMedia
              index={index}
              id={attachmentId}
              key={attachmentId}
              file={item.node.file}
              isRead={isRead}
              isOwner={isOwner}
              createdAt={createdAt}
              actionAble={actionAble}
              mediasLength={mediasLength}
              projectId={projectId}
              onMediaOpen={handleMediaOpen}
            />
          );
        })}
      </div>
      <div className={styles.footer}>
        {!isOwner && !loadingAll && mediasLength > 0 && attachments?.edges && (
          <TextButton
            size="xs"
            msg={downloadLabel}
            icon="Download-upload"
            color="grey"
            msgValues={{ size: fullSize }}
            onClick={handleDownload}
          />
        )}
        {loadingAll && (
          <TextButton
            size="xs"
            color="grey"
            loading={true}
            msg="chat.messages.message.downloading"
          />
        )}
        {!isOwner && !withText && (
          <Text type="label" text={timeChatFormat(createdAt)} className={styles.date} />
        )}
      </div>
      {nodeId && !inPopup && (
        <MediaCarouselContainer id={nodeId} message={data} currentIndex={currentMediaIndex} />
      )}
      {inPopup &&
        mediaCarouselOpen &&
        createPortal(
          <div className={styles.carousel}>
            <Icon
              name="Close-small"
              className={styles.carouselCloseBtn}
              handleClick={handleCarouselClose}
            />
            <MediaCarousel message={data} currentIndex={currentMediaIndex} />
          </div>,
          document.body
        )}
    </div>
  );
};

export default MessageAttachments;

export type MessageAttachmentType = NonNullable<
  NonNullable<NonNullable<MessageAttachments_message$data['attachments']>['edges']>[0]
>['node'];

export type MessageAttachmentFileType = NonNullable<MessageAttachmentType>['file'];
