import React, { useContext } from 'react';

import Field from '../../components/Field/Field';
import { BriefContext } from '../../../../Brief.Context';

import styles from './ProductImage.pcss';

import { useCampaignActions } from 'Hooks/useCampaignActions';
import { amplitude } from 'Helpers/amplitude';
import Button from 'Atoms/Button/Button';
import Text from 'Components/ui/Text/Text';
import FileUploader from 'Atoms/FileUploader/FileUploader';
import { modalName } from 'Types/modals';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

interface Props {
  campaignId: string;
  coverUrl?: string;
}

type ImageData = {
  src: string;
  width: number;
  height: number;
};

const ProductImage: React.FC<Props> = (props) => {
  const { campaignId, coverUrl } = props;

  const [briefState] = useContext(BriefContext);
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const { showErrors, shownElementsWithPossibleError } = briefState;

  const { addNewCampaignCover } = useCampaignActions();

  const handleCropClose = () => {
    modalDispatch({
      type: ModalTypes.TOGGLE_MODAL,
    });
  };

  const handleFileCrop = (imageData: ImageData) => {
    addNewCampaignCover({ imageData, campaignId, onSuccess: handleCropClose });
    amplitude.sendEvent({
      id: '80',
      category: 'brief',
      name: 'product_image_load_success',
      param: { campaignId },
    });
  };

  const handleCoverChoose = (files: FileList[]) => {
    if (!files || files.length === 0) return;
    const fileSrc = URL.createObjectURL(files[0]);
    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: {
        name: modalName.IMAGE_CROPPER,
        attach: {
          fileSrc,
          callback: handleFileCrop,
          actionMsg: 'brief_template.field.product.image.add',
        },
      },
    });
  };

  const error = showErrors && !coverUrl && shownElementsWithPossibleError?.includes('cover');

  return (
    <div className={styles.imageWrap}>
      <div
        className={styles.image}
        style={coverUrl ? { backgroundImage: `url(${coverUrl})` } : undefined}
      />
      <Field
        title="brief_template.field.product.image.title"
        description="brief_template.field.product.image.descr"
      >
        <div className={styles.uploaderWrap}>
          <Button
            leftIconName="download"
            msg="brief_template.field.product.image.upload"
            color="normal"
            className={error ? styles.uploadError : ''}
          />
          <FileUploader className={styles.uploader} onChange={handleCoverChoose} />
        </div>
        {error && (
          <Text
            type="md"
            msg="brief_template.field.product.image.upload_error"
            className={styles.errorText}
          />
        )}
      </Field>
    </div>
  );
};

export default ProductImage;
