import React, { useContext } from 'react';

import styles from './Preloader.css';

import { LayoutContext } from 'Templates/Layout/Layout.Context';
import { ModalContext } from 'Containers/ModalContainer/ModalContainerContext';

const PreloaderMain = () => {
  const { state: layoutState } = useContext(LayoutContext);
  const { state: modalState } = useContext(ModalContext);

  const showModal = modalState.opened;

  const isShown = layoutState.showPreloader ? styles.show : '';
  const position = showModal || !layoutState.showHeader ? styles.top : '';

  return (
    <div className={`${styles.root} ${isShown} ${position}`}>
      <div className={styles.barContainer}>
        <div className={styles.bar} />
        <div className={styles.bar} />
        <div className={styles.bar} />
        <div className={styles.bar} />
      </div>
    </div>
  );
};

export default PreloaderMain;
