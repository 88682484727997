import React, { useContext, useRef, useState } from 'react';
import classnames from 'classnames';

import styles from '../InviteOutreachCreators.pcss';
import { InviteOutreachCreatorsContext } from '../InviteOutreachCreatorsContext';

import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Input from 'Components/ui/Input/Input';
import TextButton from 'Components/ui/TextButton/TextButton';
import CreatorsPlatformSelector from '../CreatorsPlatformSelector/CreatorsPlatformSelector';

interface Props {}

type UserList = {
  handle: string;
  email: string;
}[];

const CreatorsManualUploader: React.FC<Props> = (props) => {
  const [userList, setUserList] = useState<UserList>([
    {
      handle: '',
      email: '',
    },
  ]);

  const {
    rowMetrics,
    rowErrors,
    setShowRowErrors,
    handleFileReset,
    handleParseComplete,
    isUserNeedToChangePlatform,
  } = useContext(InviteOutreachCreatorsContext);
  const showPlatformSelect = useRef(isUserNeedToChangePlatform);

  const handleAddManuallyClick = () => {
    setUserList((list) => [
      ...list,
      {
        handle: '',
        email: '',
      },
    ]);
  };

  const handleChangeHandle = (index: number, handle: string) => {
    const creators = [...userList];
    creators[index].handle = handle;
    setUserList(creators);
  };

  const handleChangeEmail = (index: number, email: string) => {
    const creators = [...userList];
    creators[index].email = email;
    setUserList(creators);
  };

  const handleRemoveCreator = (index: number) => {
    const creators = [...userList];
    setUserList(creators.filter((_, idx) => index !== idx));
  };

  const handleCompleteList = () => {
    handleParseComplete({
      data: userList.map((creator) => [`${creator.handle};${creator.email}`]),
      errors: [],
      meta: null,
    });
  };

  const handleResetList = () => {
    handleFileReset();
  };

  const handleErrorsShow = () => {
    setShowRowErrors(true);
  };

  const notEmptyList = Number(rowMetrics?.parsedCount) > 0;

  return (
    <>
      <div className={styles.attachTitle}>
        <Text type="md" msg="invite_outreach_creators_modal.step_1" />
      </div>
      {!rowMetrics ? (
        <div className={styles.listWrapper}>
          {userList.map((creator, idx) => (
            <div className={styles.listItem} key={idx}>
              <Input
                bordered
                placeholder="Username"
                hideCloseIcon
                value={creator.handle}
                onChange={(e) => handleChangeHandle(idx, e.target.value)}
              />
              <Input
                bordered
                placeholder="Email"
                hideCloseIcon
                value={creator.email}
                onChange={(e) => handleChangeEmail(idx, e.target.value)}
              />
              <div className={styles.removeItem} onClick={() => handleRemoveCreator(idx)}>
                <Icon name="Close-small" color="grey" />
              </div>
            </div>
          ))}
          <TextButton
            icon="Add-plus"
            msg="invite_outreach_creators_modal.add_to_list"
            className={styles.listAddButton}
            onClick={handleAddManuallyClick}
          />
          <div className={styles.buttonWrapper}>
            <AlterButton
              type="black"
              icon="Check"
              disabled={userList.length === 0}
              className={styles.listCompleteButton}
              msg="invite_outreach_creators_modal.list_complete"
              onClick={handleCompleteList}
            />
          </div>
        </div>
      ) : (
        <>
          <div className={styles.importHeader}>
            {Number(rowMetrics?.totalCount) > 0 && (
              <div className={classnames(styles.importInfo, { [styles.error]: !notEmptyList })}>
                <Icon name={notEmptyList ? 'Check' : 'Warning-error'} />
                <Text
                  type="md"
                  msg="invite_outreach_creators_modal.attach.not_full_list"
                  formatValues={{
                    totalCount: rowMetrics?.totalCount,
                    parsedCount: rowMetrics?.parsedCount,
                  }}
                />
              </div>
            )}
            {(rowErrors || [])?.length > 0 && (
              <Text
                type="md"
                msg="invite_outreach_creators_modal.check_errors"
                className={styles.showErrors}
                handleClick={handleErrorsShow}
              />
            )}
          </div>
          {(rowErrors || [])?.length > 0 && (
            <AlterButton
              type="black"
              icon="Arrow-small-left"
              msg="invite_outreach_creators_modal.return_to_list"
              className={styles.listCompleteButton}
              onClick={handleResetList}
            />
          )}
          {showPlatformSelect.current && <CreatorsPlatformSelector />}
        </>
      )}
    </>
  );
};

export default CreatorsManualUploader;
