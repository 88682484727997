import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import styles from './DiscoveryHeader.pcss';

import { getCampaignCreatorsLink } from 'Util/links';
import BackButton from 'Molecules/BackButton/BackButton';
import { DiscoveryCreatorsContext } from '../../DiscoveryCreatorsContext';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

const Header = () => {
  const navigate = useNavigate();
  const { listData, counter, backUrl, listId } = useContext(DiscoveryCreatorsContext);

  const handleBackClick = () => {
    const navState = {
      state: {
        fromDiscovery: true,
        listId,
      },
    };
    if (backUrl) {
      navigate(backUrl, navState);
    } else {
      navigate(-1);
    }
  };

  if (counter.total === null) {
    return null;
  }

  return (
    <div className={styles.root}>
      <AlterButton type="grey" icon="Arrow-big-left" onClick={handleBackClick} />
      <Text type="d2" text={listData?.name || 'New list'} />
    </div>
  );
};

export default Header;
