import { commitMutation, graphql } from 'react-relay';

import {
  CreateOrganizationInviteMutation,
  CreateOrganizationInviteMutation$data,
  CreateOrganizationInviteInput,
} from 'GraphTypes/CreateOrganizationInviteMutation.graphql';
import environment from 'Api/Environment';

const mutation = graphql`
  mutation CreateOrganizationInviteMutation($input: CreateOrganizationInviteInput!) {
    createOrganizationInvite(input: $input) {
      organizationInvite {
        id
        signupUrl
        token
      }
    }
  }
`;

type FnType = (data: object) => void;

export default (
  data: CreateOrganizationInviteInput,
  resolve?: (response: CreateOrganizationInviteMutation$data) => void,
  reject?: FnType
) => {
  const variables = {
    input: {
      ...data,
    },
  };

  commitMutation<CreateOrganizationInviteMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors) {
        reject?.(errors);
        return;
      }
      resolve?.(response);
    },
    onError: (error) => {
      reject?.(error);
    },
  });
};
