import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { graphql, useFragment } from 'react-relay';
import compact from 'lodash/compact';

import styles from './Creative.pcss';
import { createStatsData } from './data';

import { amplitude } from 'Helpers/amplitude';
import { getProjectsLink } from 'Util/links';
import { getCreatorData } from 'Util/creatorData';
import Icon from 'Components/ui/Icon/Icon';
import Text from 'Components/ui/Text/Text';
import Image from 'Components/ui/Image/Image';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { Creative_creative$key } from 'GraphTypes/Creative_creative.graphql';
import Tag from 'Components/ui/Tag/Tag';
import { CREATIVE_PREVIEW } from 'Constants/modals';
import { MEDIA } from 'Constants/general';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

interface Props {
  className?: string;
  creative: Creative_creative$key;
  hasHeader?: boolean;
  showUser?: boolean;
  canShowFullPreview?: boolean;
  canChatWithCreators?: boolean;
  admin?: boolean;
  accent?: string;
}

const Creative: React.FC<Props> = (props) => {
  const { accent, creative, admin } = props;

  const navigate = useNavigate();
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const data = useFragment(
    graphql`
      fragment Creative_creative on Creative {
        id
        adminFavorite
        postPublication {
          instagramInsightsMedia {
            finalStoryInsightsCollected
            impressions
            reach
            engagement
            likeCount
            commentCount
            savedCount
            shareCount
            placement
            permalink
            playCount
          }
          tiktokInsightsMedia {
            embedUrl
            engagementRate
            videoViews
            comments
            likes
            shares
          }
        }
        file {
          id
          type
          height
          width
          secureUrl
          ... on Image {
            transformations {
              postThumbnailUrl
            }
          }
          ... on Video {
            transformations {
              autoUrl
              postThumbnailUrl
            }
          }
        }
        project {
          id
          showcasingPermitted
          creator {
            id
            username
            type
            profile {
              profilePictureFile {
                url
                ... on Image {
                  transformations {
                    collageThumbnailUrl
                  }
                }
              }
            }
            ... on InstagramCreator {
              user {
                profilePictureUrl
                followedByCount
              }
            }
            ... on TiktokCreator {
              user {
                name
                avatar {
                  secureUrl
                }
              }
            }
          }
          campaign {
            id
            organization {
              id
              currency
            }
            stage
          }
        }
      }
    `,
    creative
  );

  if (!data) return null;

  const project = data.project;
  const projectId = project?.id;
  const campaignStage = project?.campaign.stage;
  const campaignId = project?.campaign.id;
  const creator = project?.creator;
  const showcasingPermitted = project?.showcasingPermitted;
  const file = data.file;
  const organizationId = data.project?.campaign.organization?.id;

  const { avaSrc, username } = getCreatorData(creator);

  const mediaUrl = file?.transformations?.postThumbnailUrl || file?.secureUrl;

  const statData = createStatsData({ creative: data });
  const instagramInsightsMedia = data.postPublication?.instagramInsightsMedia;
  const tiktokInsightsMedia = data.postPublication?.tiktokInsightsMedia;

  const list = compact([
    instagramInsightsMedia
      ? {
          msg: 'creative.open_instagram',
          onClick: () => {
            amplitude.sendEvent<339>({
              id: '339',
              category: 'campaign',
              name: 'perfomance_open_instagram',
              param: { campaign_id: campaignId || '', project_id: projectId || '' },
            });
            if (instagramInsightsMedia?.permalink) {
              window.open(instagramInsightsMedia.permalink);
            }
          },
        }
      : null,
    tiktokInsightsMedia
      ? {
          msg: 'creative.open_tiktok',
          onClick: () => {
            amplitude.sendEvent<338>({
              id: '338',
              category: 'campaign',
              name: 'perfomance_open_tiktok',
              param: { campaign_id: campaignId || '', project_id: projectId || '' },
            });
            if (tiktokInsightsMedia?.embedUrl) {
              window.open(tiktokInsightsMedia.embedUrl);
            }
          },
        }
      : null,
    {
      msg: 'creative.open_chat',
      onClick: () => {
        amplitude.sendEvent<337>({
          id: '337',
          category: 'campaign',
          name: 'perfomance_open_chat',
          param: { campaign_id: campaignId || '', project_id: projectId || '' },
        });
        const projectLink = getProjectsLink({
          projectId,
          campaignId: campaignStage === 'ACTIVE' ? campaignId : undefined,
          organizationId: admin ? organizationId : undefined,
        });
        navigate(projectLink);
      },
    },
  ]);

  const handleContentClick = () => {
    amplitude.sendEvent<340>({
      id: '340',
      category: 'campaign',
      name: 'contenct_click',
      param: { campaign_id: campaignId || '' },
    });
    const fileData = tiktokInsightsMedia ? { tikTokUrl: tiktokInsightsMedia.embedUrl } : file;

    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: {
        name: CREATIVE_PREVIEW,
        attach: {
          projectId,
          list: [
            {
              ...fileData,
              typename: MEDIA,
              data,
            },
          ],
        },
      },
    });
  };

  return (
    <div className={classnames(styles.root, { [styles.accent]: !!accent })}>
      {accent && (
        <div className={styles.badge}>
          <Icon name="Lamp-spark" />
          <Text text={accent} type="label" />
        </div>
      )}
      <div className={styles.header}>
        <Image src={avaSrc} size={40} type="round" className={styles.avatar} />
        <div>
          <Text type="md" text={username} />
          {instagramInsightsMedia?.placement && (
            <Text
              msg={`creative.subtitle.${instagramInsightsMedia.placement.toLowerCase()}`}
              type="label"
              className={styles.placement}
            />
          )}
        </div>
        <Dropdown
          showArrow={false}
          className={styles.dots}
          value={<Icon name={'Dots-menu'} size={24} />}
        >
          <DropdownGroup className={styles.dropdownMenu}>
            {list.map((item) => (
              <DropdownItem key={item.msg}>
                <AlterButton msg={item.msg} onClick={item.onClick} fluid />
              </DropdownItem>
            ))}
          </DropdownGroup>
        </Dropdown>
      </div>
      <div className={styles.imgWrap}>
        <div
          className={styles.img}
          style={{ backgroundImage: `url(${mediaUrl}` }}
          onClick={handleContentClick}
        />
        {admin && showcasingPermitted && (
          <Tag
            msg="creative.open_sharing"
            color="purple"
            needMargin={false}
            className={styles.openSharing}
          />
        )}
      </div>
      <div className={styles.footer}>
        {statData.length > 0 &&
          statData?.map((item) => {
            return (
              <Tooltip
                id={item.icon}
                tooltipMsg={item.tooltipMsg}
                key={item.icon}
                className={styles.tagWrap}
                place="top"
              >
                <div className={styles.tag} key={item.icon}>
                  <Icon size={16} name={item.icon} />
                  <Text type="label" text={item.value} />
                </div>
              </Tooltip>
            );
          })}
      </div>
    </div>
  );
};

const CreativeItem = Creative;

export default CreativeItem;
