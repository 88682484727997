import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Cell from '../Cell';

import styles from './Row.pcss';

import modsClasses from 'Util/modsClasses.js';
import SimpleLink from 'Components/SimpleLink';

const MODS = ['background', 'height'];

class Row extends Component {
  constructor(props) {
    super();

    if (!props.hoverResponsive) return;

    this.state = {
      hover: false,
    };

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  onMouseEnter() {
    this.setState({ hover: true });
  }

  onMouseLeave() {
    this.setState({ hover: false });
  }

  createCells() {
    const { cells, hoverResponsive } = this.props;

    const hover = hoverResponsive ? { hover: this.state.hover } : {};

    return cells.map((item) => {
      const { id, width, style } = item;

      return (
        <div key={id} style={{ width, ...style }} className={styles.item}>
          <Cell {...item} {...hover} />
        </div>
      );
    });
  }

  createContent() {
    const { className, presetStyle, style, stylename = '', hoverResponsive } = this.props;

    const cells = this.createCells();
    const classes = presetStyle ? modsClasses(MODS, presetStyle, styles) : className;

    let handlers = {};

    if (hoverResponsive) {
      handlers = { onMouseEnter: this.onMouseEnter, onMouseLeave: this.onMouseLeave };
    }

    return (
      <div className={`${classes} ${styles.root} ${styles[stylename]}`} style={style} {...handlers}>
        {cells}
      </div>
    );
  }

  render() {
    const { innerLink, outerLink } = this.props;
    const content = this.createContent();

    if (innerLink) {
      return (
        <Link to={innerLink} className={styles.link}>
          {' '}
          {content}
        </Link>
      );
    }

    if (outerLink) {
      return (
        <SimpleLink href={outerLink} target="_blank" className={styles.link}>
          {content}
        </SimpleLink>
      );
    }

    return content;
  }
}

export default Row;
