import React, { PropsWithChildren } from 'react';

import Modal from 'Templates/Layout/Modal/Modal';
import { HistoryReferrerListener } from 'Hooks/useHistoryReferrer';

const PublicRoute: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;

  return (
    <HistoryReferrerListener>
      <div>
        <main>{children}</main>
        <Modal />
      </div>
    </HistoryReferrerListener>
  );
};

export default PublicRoute;
