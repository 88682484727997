import { useMemo } from 'react';
import { graphql, useSubscription } from 'react-relay';
import { RecordSourceSelectorProxy } from 'relay-runtime';

import { CampaignSubscriptionAutocreatedSubscription$data } from 'GraphTypes/CampaignSubscriptionAutocreatedSubscription.graphql';

interface Props {
  updater: (data: CampaignSubscriptionAutocreatedSubscription$data) => void;
}

const useСampaignSubscriptionAutocreatedSubscription = (props: Props): void => {
  const config = useMemo(
    () => ({
      subscription: graphql`
        subscription CampaignSubscriptionAutocreatedSubscription {
          campaignSubscriptionAutocreated {
            campaign {
              id
              userSubscribed
            }
          }
        }
      `,
      variables: {},
      updater: (
        store: RecordSourceSelectorProxy,
        data: CampaignSubscriptionAutocreatedSubscription$data
      ) => {
        props.updater(data);
      },
    }),
    []
  );

  return useSubscription(config);
};

export default useСampaignSubscriptionAutocreatedSubscription;
