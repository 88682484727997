import React from 'react';

import styles from './UploadMediaplanFail.pcss';

import Drawer from 'Components/ui/Drawer/Drawer';
import Text from 'Components/ui/Text/Text';

const UploadMediaplanFail = () => {
  return (
    <Drawer rootKey="upload_csv_failed" className={styles.drawer}>
      <div className={styles.drawer}>
        <div className={styles.content}>
          <Text type="d2" msg="upload_mediaplan_fail_modal.title" className={styles.title} />
          <Text type="md" msg="upload_mediaplan_fail_modal.descr" />
        </div>
      </div>
    </Drawer>
  );
};

export default UploadMediaplanFail;
