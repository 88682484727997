import React, { useCallback } from 'react';
import track, { useTracking } from 'react-tracking';
import { useLazyLoadQuery, graphql } from 'react-relay';
import classnames from 'classnames';
import xor from 'lodash/xor';

import styles from './ProjectsFilters.pcss';
import StagesFilter from './StagesFilter/StagesFilter';
import ScreeningQuestionsFilter from './ScreeningQuestionsFilter/ScreeningQuestionsFilter';
import InvitedFilter from './InvitedFilter/InvitedFilter';

import { amplitude } from 'Helpers/amplitude';
import IconNew from 'Components/ui/Icon/Icon';
import Image from 'Components/ui/Image/Image';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import {
  ProjectsFiltersQuery as QueryType,
  AdvertiserProjectStage,
} from 'GraphTypes/ProjectsFiltersQuery.graphql';
import badgeRisingImg from 'Images/icons/badge-rising.svg';
import badgeTopRatedImg from 'Images/icons/badge-top-rated.svg';
import badgeResponsiveImg from 'Images/icons/badge-responsive.svg';
import badgeUGCExpertImg from 'Images/icons/badge-UGC-premium.svg';
import thumbsUpFilledBlueImg from 'Images/icons/thumbs-up-filled.svg';
import thumbsUpImg from 'Images/icons/thumbs-up-accept.svg';
import timerImg from 'Images/icons/timer-return.svg';
import DueDateFilter from 'AdvertiserPage/Campaign/Creators/ProjectsFilters/DueDateFilter/DueDateFilter';

const TOP_RATED = 'TOP_RATED';
const HIGH_POTENTIAL = 'HIGH_POTENTIAL';
const HIGHLY_RESPONSIVE = 'HIGHLY_RESPONSIVE';
const UGC_EXPERT = 'UGC_EXPERT';

const ProjectsFiltersQuery = graphql`
  query ProjectsFiltersQuery(
    $campaignId: ID!
    $archived: Boolean
    $stages: [AdvertiserProjectStage!]
  ) {
    campaign(id: $campaignId) {
      outreachedCount: projects(
        archived: $archived
        stages: $stages
        orderBy: LAST_ADDED
        outreach: true
      ) {
        totalCount
      }
      topRatedCount: projects(
        archived: $archived
        badges: [TOP_RATED]
        stages: $stages
        orderBy: LAST_ADDED
      ) {
        totalCount
      }
      highPotentialCount: projects(
        archived: $archived
        badges: [HIGH_POTENTIAL]
        stages: $stages
        orderBy: LAST_ADDED
      ) {
        totalCount
      }
      ugcExpertCount: projects(
        archived: $archived
        badges: [UGC_EXPERT]
        stages: $stages
        orderBy: LAST_ADDED
      ) {
        totalCount
      }
      highlyResponsiveCount: projects(
        archived: $archived
        badges: [HIGHLY_RESPONSIVE]
        stages: $stages
        orderBy: LAST_ADDED
      ) {
        totalCount
      }
      ...ScreeningQuestionsFilter_campaign
    }
  }
`;

interface Props {
  publishingRequired: boolean;
  stages: string[];
  defaultStages: string[];
  badges: string[];
  projectStage?: string;
  screeningQuestionIds?: string[];
  currency?: string;
  campaignId: string;
  archived: boolean;
  isAdminView: boolean;
  withPreviousCollaborations?: boolean | null;
  isLongTermCampaign: boolean;
  shortlisted: boolean | null;
  canFilterCreatorsByBadge: boolean | null;
  invitedByOrganizationMember?: boolean | null;
  outreach?: boolean | null;
  onFilterChange: (data: object) => void;
  section: string;
}

const ProjectsFilters: React.FC<Props> = (props) => {
  const {
    shortlisted,
    defaultStages,
    projectStage,
    badges,
    campaignId,
    currency,
    archived,
    withPreviousCollaborations,
    canFilterCreatorsByBadge,
    onFilterChange,
    isLongTermCampaign,
    screeningQuestionIds,
    invitedByOrganizationMember,
    outreach,
    section,
    publishingRequired,
    isAdminView,
  } = props;

  const tracking = useTracking();

  const data = useLazyLoadQuery<QueryType>(ProjectsFiltersQuery, {
    campaignId,
    archived,
    stages: defaultStages as AdvertiserProjectStage[],
  });

  const handleProjectStageChange = (value?: string) => {
    const stages = value ? value : defaultStages;
    onFilterChange({ stages });
  };

  const handleShortlistedToggle = useCallback(() => {
    const newShortlisted = shortlisted ? null : true;

    tracking.trackEvent({
      element: 'shortlisted_filter',
      event: 'click',
      payload: {
        value: newShortlisted,
      },
    });

    amplitude.sendEvent<190>({
      id: '190',
      category: 'campaign',
      name: 'campaign_shortlist_filter',
      param: undefined,
    });

    onFilterChange({ shortlisted: newShortlisted });
  }, [shortlisted]);

  const handleTopRateBadgeToggle = () => {
    const newBadges = xor(badges, [TOP_RATED]);
    onFilterChange({ badges: newBadges });
    sendFilterEvent('Top rated');
  };

  const handleHighPotentialBadgeToggle = () => {
    const newBadges = xor(badges, [HIGH_POTENTIAL]);
    onFilterChange({ badges: newBadges });
    sendFilterEvent('High potential');
  };

  const handleHighlyResponsiveBadgeToggle = () => {
    const newBadges = xor(badges, [HIGHLY_RESPONSIVE]);
    onFilterChange({ badges: newBadges });
    sendFilterEvent('Highly responsive');
  };

  const handleUgcExpertBadgeToggle = () => {
    const newBadges = xor(badges, [UGC_EXPERT]);
    onFilterChange({ badges: newBadges });
    sendFilterEvent('Expert badge');
  };

  const handleQuestionChange = (newScreeningQuestionIds?: string[]) => {
    onFilterChange({ screeningQuestionIds: newScreeningQuestionIds });
  };

  const handleInvitedToggle = () => {
    amplitude.sendEvent<365>({
      id: '365',
      category: 'campaign',
      name: 'invited_filter_click',
      param: undefined,
    });
    onFilterChange({
      invitedByOrganizationMember: invitedByOrganizationMember ? null : true,
    });
  };

  const sendFilterEvent = (badge: string) => {
    amplitude.sendEvent({
      id: '250',
      category: 'campaign',
      name: 'campaign_badge_filter',
      param: {
        badge_name: badge,
      },
    });
  };

  const handleCollabsClick = () => {
    amplitude.sendEvent<363>({
      id: '363',
      category: 'campaign',
      name: 'previous_collabs_filter',
      param: undefined,
    });
    onFilterChange({ withPreviousCollaborations: withPreviousCollaborations ? null : true });
  };

  const handleInvitedParamsChange = (data: {
    invitedByOrganizationMember?: boolean | null;
    outreach?: boolean | null;
  }) => {
    onFilterChange({
      invitedByOrganizationMember: data.invitedByOrganizationMember,
      outreach: data.outreach,
    });
  };

  const isHired = section === 'creators';

  if (!data) return null;

  const topRatedCount = Number(data.campaign?.topRatedCount.totalCount);
  const outreachedCount = Number(data.campaign?.outreachedCount.totalCount);
  const highPotentialCount = Number(data.campaign?.highPotentialCount.totalCount);
  const highlyResponsiveCount = Number(data.campaign?.highlyResponsiveCount.totalCount);
  const ugcExpertCount = Number(data.campaign?.ugcExpertCount.totalCount);
  return (
    <div className={styles.root}>
      {defaultStages.length > 1 && !isLongTermCampaign && (
        <StagesFilter
          stages={defaultStages}
          projectStage={projectStage}
          onProjectStageChange={handleProjectStageChange}
        />
      )}
      <Tooltip place="top" id="shortlisted" tooltipMsg="creators.filter.shortlisted">
        <AlterButton
          rightElement={<Image src={shortlisted ? thumbsUpFilledBlueImg : thumbsUpImg} size={24} />}
          hover={false}
          className={classnames({
            [styles.active]: shortlisted,
          })}
          onClick={handleShortlistedToggle}
        />
      </Tooltip>
      {!!(highPotentialCount > 0) && (
        <Tooltip place="top" id="high_potential" tooltipMsg="creators.filter.high_potential">
          <AlterButton
            rightElement={
              badges.includes(HIGH_POTENTIAL) ? (
                <Image src={badgeRisingImg} size={24} />
              ) : (
                <IconNew name="Badge-rising-stroke" />
              )
            }
            className={classnames({
              [styles.active]: badges.includes(HIGH_POTENTIAL),
            })}
            disabled={!canFilterCreatorsByBadge}
            hover={false}
            onClick={handleHighPotentialBadgeToggle}
          />
        </Tooltip>
      )}
      {!!(topRatedCount > 0) && (
        <Tooltip place="top" id="top_rated" tooltipMsg="creators.filter.top_rated">
          <AlterButton
            rightElement={
              badges.includes(TOP_RATED) ? (
                <Image src={badgeTopRatedImg} size={24} />
              ) : (
                <IconNew name="Badge-top-ratad-stroke" />
              )
            }
            className={classnames({
              [styles.active]: badges.includes(TOP_RATED),
            })}
            hover={false}
            disabled={!canFilterCreatorsByBadge}
            onClick={handleTopRateBadgeToggle}
          />
        </Tooltip>
      )}
      {!!(highlyResponsiveCount > 0) && (
        <Tooltip place="top" id="highly_responsive" tooltipMsg="creators.filter.highly_responsive">
          <AlterButton
            rightElement={
              badges.includes(HIGHLY_RESPONSIVE) ? (
                <Image src={badgeResponsiveImg} size={24} />
              ) : (
                <IconNew name="Badge-responsive-stroke" />
              )
            }
            className={classnames({
              [styles.active]: badges.includes(HIGHLY_RESPONSIVE),
            })}
            hover={false}
            disabled={!canFilterCreatorsByBadge}
            onClick={handleHighlyResponsiveBadgeToggle}
          />
        </Tooltip>
      )}
      {!!(ugcExpertCount > 0) && (
        <Tooltip place="top" id="ugc_expert" tooltipMsg="creators.filter.ugc_expert">
          <AlterButton
            rightElement={
              badges.includes(UGC_EXPERT) ? (
                <Image src={badgeUGCExpertImg} size={24} />
              ) : (
                <IconNew name="Badge-UGC-premium-stroke" />
              )
            }
            className={classnames({ [styles.active]: badges.includes(UGC_EXPERT) })}
            hover={false}
            disabled={!canFilterCreatorsByBadge}
            onClick={handleUgcExpertBadgeToggle}
          />
        </Tooltip>
      )}
      {projectStage === 'ACCEPTED' && (
        <Tooltip place="top" id="collabs" tooltipMsg="creators.filter.previous_collabs">
          <AlterButton
            rightElement={
              withPreviousCollaborations ? (
                <Image src={timerImg} size={24} />
              ) : (
                <IconNew name="timer-return" />
              )
            }
            hover={false}
            onClick={handleCollabsClick}
          />
        </Tooltip>
      )}
      {projectStage === 'ACCEPTED' && !isAdminView && (
        <Tooltip place="top" id="invited" tooltipMsg="creators.filter.invited">
          <AlterButton
            rightElement={<IconNew name="target-bullseye" />}
            hover={false}
            onClick={handleInvitedToggle}
            className={classnames({ [styles.active]: !!invitedByOrganizationMember })}
          />
        </Tooltip>
      )}
      {(projectStage === 'ACCEPTED' || section === 'awaiting-list') && isAdminView && (
        <Tooltip place="top" id="invited" tooltipMsg="creators.filter.invited">
          <InvitedFilter
            invitedByOrganizationMember={invitedByOrganizationMember}
            outreach={outreach}
            onInvitedParamsChange={handleInvitedParamsChange}
          />
        </Tooltip>
      )}
      {isHired && (
        <DueDateFilter
          place="campaign"
          onChange={onFilterChange}
          publishingRequired={publishingRequired}
        />
      )}
      {data.campaign && projectStage === 'ACCEPTED' && (
        <ScreeningQuestionsFilter
          campaign={data.campaign}
          screeningQuestionIds={screeningQuestionIds}
          onQuestionsChange={handleQuestionChange}
        />
      )}
    </div>
  );
};

export default track()(ProjectsFilters);
