import { graphql, useMutation } from 'react-relay';

import { MarkAllInAppNotificationsAsReadMutation } from 'GraphTypes/MarkAllInAppNotificationsAsReadMutation.graphql';
import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const MarkAllInAppNotificationsAsReadMutation = graphql`
  mutation MarkAllInAppNotificationsAsReadMutation(
    $input: Notifications_InAppNotifications_MarkAllAsReadInput!
  ) {
    markAllInAppNotificationsAsRead(input: $input) {
      notifications {
        read
      }
    }
  }
`;

export const useMarkAllInAppNotificationsAsReadMutation: BaseMutation<
  MarkAllInAppNotificationsAsReadMutation
> = (config) => {
  const [markAllInAppNotificationsAsReadQuery, loading] =
    useMutation<MarkAllInAppNotificationsAsReadMutation>(MarkAllInAppNotificationsAsReadMutation);

  const markAllInAppNotificationsAsReadCaller: BaseMutationExecFn<
    MarkAllInAppNotificationsAsReadMutation
  > = (execConfig) => {
    markAllInAppNotificationsAsReadQuery({
      ...config,
      ...execConfig,
    });
  };
  return [markAllInAppNotificationsAsReadCaller, loading];
};
