import { graphql, requestSubscription } from 'react-relay';

import environment from 'Api/Environment';

const subscription = graphql`
  subscription InAppNotificationCreatedSubscription {
    inAppNotificationCreated {
      inAppNotification {
        id
        ...NotificationItem_notification
      }
      user {
        unreadCount: inAppNotifications(first: 1, read: false) {
          totalCount
        }
        inAppNotifications(first: 3, read: false) {
          edges {
            node {
              id
              ...NotificationItem_notification
            }
          }
        }
      }
    }
  }
`;

export default () => {
  requestSubscription(environment, {
    subscription,
    variables: {},
  });
};
