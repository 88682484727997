import React, { FC, InputHTMLAttributes } from 'react';
import classnames from 'classnames';

import type { Props as TextInputPropsType } from '../ui/Input/Input';
import type { ISelectCheckboxProps } from '../ui/CheckboxSelect/CheckboxSelect';
import CheckboxSelect from '../ui/CheckboxSelect/CheckboxSelect';

import styles from './ProgressiveInput.pcss';

import Input from 'Components/ui/Input/Input';
import Textarea, { Props as TextareaPros } from 'Components/ui/Textarea/Textarea';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { IRadioDropdownProps, RadioDropdown } from 'Components/ui/RadioDropdown/RadioDropdown';
import RangeInput, { IRangeInputProps } from 'Components/ui/RangeInput/RangeInput';
import RangeRadio, { IRangeRadioProps } from 'Components/ui/RangeRadio/RangeRadio';
import { NullableType } from 'Types/common';

type ProgressiveInputType =
  | 'input'
  | 'checkboxSelect'
  | 'range'
  | 'radio'
  | 'rangeSelect'
  | 'textarea';
type InputPropsType =
  | TextInputPropsType
  | ISelectCheckboxProps
  | IRadioDropdownProps
  | IRangeInputProps
  | IRangeRadioProps;
type TooltipType = { id: string } & (
  | {
      tooltip: string;
      tooltipMsg?: string;
    }
  | { tooltip?: string; tooltipMsg: string }
);

interface Props {
  type: ProgressiveInputType;
  inputProps: InputPropsType & InputHTMLAttributes<HTMLInputElement>;
  tooltip?: TooltipType;
  titleMsg?: string;
  title?: string;
  className?: string;
  isDirty?: boolean;
  shrinkIfDirty?: boolean;
  errorMsg?: NullableType<string>;
  errorMsgValue?: { [key: string]: string | number | JSX.Element };
  accent?: boolean;
  titleIcon?: React.ReactNode;
  hideTitle?: boolean;
  disabled?: boolean;
}

const ProgressiveInput: FC<Props> = ({
  className,
  type,
  titleMsg,
  tooltip,
  inputProps,
  title,
  isDirty = false,
  shrinkIfDirty,
  errorMsg,
  errorMsgValue,
  accent,
  titleIcon = null,
  hideTitle = false,
  disabled,
}) => {
  return (
    <div className={classnames(styles.root, className)}>
      {!hideTitle && (titleMsg || title) && (
        <div className={styles.titleContainer}>
          <Text
            msg={titleMsg}
            text={title}
            className={classnames({
              [styles.dirtyText]: isDirty,
              [styles.accentTitle]: !isDirty && accent,
            })}
            type="md"
          />
          {titleIcon}
          {tooltip && (
            <Tooltip
              id={tooltip?.id}
              tooltipMsg={tooltip?.tooltipMsg}
              tooltipText={tooltip?.tooltip}
              place="top"
            >
              <Icon name="Info" className={styles.tooltipIcon} />
            </Tooltip>
          )}
        </div>
      )}
      {type === 'input' && (
        <Input
          {...(inputProps as InputPropsType)}
          bordered
          disabled={disabled}
          inputClassName={classnames(styles.input, inputProps.className, {
            [styles.dirtyBorder]: isDirty,
          })}
        />
      )}
      {type === 'textarea' && (
        <Textarea {...(inputProps as TextareaPros)} bordered disabled={disabled} />
      )}
      {type === 'checkboxSelect' && (
        <CheckboxSelect
          {...(inputProps as ISelectCheckboxProps)}
          isDirty={isDirty}
          accent={accent}
        />
      )}
      {type === 'radio' && (
        <RadioDropdown {...(inputProps as IRadioDropdownProps)} isDirty={isDirty} accent={accent} />
      )}
      {type === 'range' && (
        <RangeInput
          {...(inputProps as IRangeInputProps)}
          isDirty={isDirty}
          shrinkIfDirty={shrinkIfDirty}
        />
      )}
      {type === 'rangeSelect' && (
        <RangeRadio {...(inputProps as IRangeRadioProps)} isDirty={isDirty} />
      )}
      {!!errorMsg && (
        <Text msg={errorMsg} formatValues={errorMsgValue} className={styles.error} type="md" />
      )}
    </div>
  );
};
export default ProgressiveInput;
