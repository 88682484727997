import React, { useContext } from 'react';
import { graphql, useFragment } from 'react-relay';

import styles from './ProjectStatus.pcss';

import Text from 'Atoms/Text/Text';
import TextButton from 'Atoms/TextButton/TextButton';
import { modalName } from 'Types/modals';
import { statusNotHiredData, statusHiredData } from 'Constants/messageModal';
import { ProjectStatusChat_project$key } from 'GraphTypes/ProjectStatusChat_project.graphql';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

interface Props {
  project: ProjectStatusChat_project$key;
}

const ProjectStatus: React.FC<Props> = (props) => {
  const { project } = props;

  const projectData = useFragment(
    graphql`
      fragment ProjectStatusChat_project on Project {
        completion {
          id
        }
        launch {
          id
        }
        brief {
          ... on V2Brief {
            productShipmentRequired
          }
        }
        productShipmentCompletion {
          id
        }
        productShipment {
          id
          status {
            state
          }
        }
      }
    `,
    project
  );

  const { dispatch: modalDispatch } = useContext(ModalContext);

  const completion = projectData.completion?.id;
  const launch = projectData.launch?.id;

  let data: { msgData?: object; style: string; title: string };

  if (completion) {
    data = { style: styles.completed, title: 'chat_creator.project_status.completed' };
  } else if (launch) {
    data = {
      style: styles.hired,
      title: 'chat_creator.project_status.hired',
      msgData: statusHiredData,
    };
  } else {
    data = {
      style: styles.notHired,
      title: 'chat_creator.project_status.notHired',
      msgData: statusNotHiredData,
    };
  }

  const handleStatusClick = () => {
    const msgData = data?.msgData || {};
    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: { name: modalName.MESSAGE, attach: { ...msgData, closeOnClick: true } },
    });
  };

  return (
    <div className={`${styles.projectStatus} ${data.style}`}>
      <div className={styles.statusTitleWrap}>
        <Text color="white" msg={data.title} />
      </div>
      {!completion && (
        <TextButton
          theme="dark"
          color="normal"
          msg="general.read_more"
          onClick={handleStatusClick}
        />
      )}
    </div>
  );
};

export default ProjectStatus;
