import React, { useState } from 'react';
import { graphql, useFragment } from 'react-relay';

import CreatorDetails from '../../../../components/CreatorDetails/CreatorDetails';

import styles from './BlockedCreator.pcss';

import { amplitude } from 'Helpers/amplitude';
import unblockCreator from 'Mutations/UnblockCreator.Mutation';
import { getCreatorProfileLink } from 'Util/links';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import { BlockedCreator_creator$key } from 'GraphTypes/BlockedCreator_creator.graphql';
import Icon from 'Components/ui/Icon/Icon';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import BlockedUsername from 'Molecules/BlockedUsername/BlockedUsername';

interface Props {
  organizationId?: string;
  isBlockedView?: boolean;
  creator: BlockedCreator_creator$key;
  showUnlockCreatorBanner?: () => void;
}

const BlockedCreator: React.FC<Props> = (props) => {
  const { creator, organizationId, isBlockedView, showUnlockCreatorBanner } = props;

  const data = useFragment(
    graphql`
      fragment BlockedCreator_creator on Creator {
        id
        profile {
          badges {
            name
          }
          countries {
            name
          }
          profilePictureFile {
            url
            ... on Image {
              transformations {
                collageThumbnailUrl
              }
            }
          }
        }
        ...CreatorDetails_creator
        type
        username
        ... on YoutubeCreator {
          channel {
            id
            name
          }
        }
        ... on TiktokCreator {
          user {
            name
          }
        }
      }
    `,
    creator
  );
  const creatorId = data.id;

  const [isRemoving, setRemoving] = useState(false);

  const handleCreatorUnblock = () => {
    amplitude.sendEvent({
      id: '160',
      category: 'blocklist',
      name: 'creator_blocklist_removed_dash',
      param: {
        creatorId,
      },
    });
    setRemoving(true);
    unblockCreator({ creatorId, organizationId });
  };

  return (
    <div className={styles.root}>
      <CreatorDetails
        creator={data}
        isBlockedView={isBlockedView}
        showUnlockCreatorBanner={showUnlockCreatorBanner}
      />
      <div className={styles.controls}>
        <Tooltip
          id="blocklist_view_button_tooltip"
          tooltipMsg="creators.dashboard.blocklist.creator.view_profile"
          place="top-start"
          className={styles.tooltip}
        >
          <SmartLink target="_blank" path={getCreatorProfileLink({ creatorId })}>
            <AlterButton icon="Profile" type="grey" />
          </SmartLink>
        </Tooltip>
        <Tooltip
          id="blocklist_remove_button"
          tooltipMsg="creators.dashboard.blocklist.creator.remove_button.tooltip"
          place="top-start"
          className={styles.tooltip}
        >
          <AlterButton
            rightElement={<Icon name="Trash-delele" size={24} />}
            type="grey"
            loading={isRemoving}
            onClick={handleCreatorUnblock}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default BlockedCreator;
