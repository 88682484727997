import React, { useContext } from 'react';

import styles from './SaveListButton.pcss';

import {
  DiscoveryCreatorsContext,
  DiscoveryCreatorsImportCallback,
} from 'Page/advertiser/Discovery/DiscoveryCreatorsContext';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { CustomListCreateDrawer } from 'Modal/advertiser/CustomListCreate';
import { CreateCustomListMutation$data } from 'GraphTypes/CreateCustomListMutation.graphql';

const DiscoverySaveListButton = () => {
  const {
    organizationId,
    listId,
    setListData,
    isCreatingList,
    importCreatorsToCustomList,
    isImportCreatorsInProgress,
    selectedCreators,
  } = useContext(DiscoveryCreatorsContext);

  const { openDrawer } = useContext(DrawerContext);

  const handleAddNewCreators = () => {};

  const createList = () => {
    openDrawer('custom-list-create');
  };
  const addCreatorsToList = () => {
    importCreatorsToCustomList(handleAddNewCreators);
  };

  const handleImportCreators = (data: DiscoveryCreatorsImportCallback) => {
    if (data.success) {
      //
    } else {
      ///
    }
  };

  const onCustomListSuccessfulCreated = (response: CreateCustomListMutation$data) => {
    importCreatorsToCustomList(handleImportCreators);
    if (response.createCustomList?.__typename === 'CreateCustomListPayload') {
      setListData(response.createCustomList.customList);
    }
  };

  return (
    <div className={styles.root}>
      {isCreatingList ? (
        <AlterButton
          type={!selectedCreators.length ? 'white' : 'pink'}
          icon="Star"
          fluid
          text="Create list"
          disabled={!selectedCreators.length}
          loading={isImportCreatorsInProgress}
          counter={selectedCreators.length}
          onClick={createList}
        />
      ) : (
        <AlterButton
          type={!selectedCreators.length ? 'white' : 'pink'}
          icon="Star"
          fluid
          text="Update list"
          disabled={!selectedCreators.length}
          loading={isImportCreatorsInProgress}
          counter={selectedCreators.length}
          onClick={addCreatorsToList}
        />
      )}
      {organizationId && (
        <CustomListCreateDrawer
          listId={listId}
          organizationId={organizationId}
          onCustomListSuccessfulCreated={onCustomListSuccessfulCreated}
        />
      )}
    </div>
  );
};

export default DiscoverySaveListButton;
