import React, { Fragment } from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import track from 'react-tracking';

import styles from './MediaplanSection.pcss';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import MediaplanItems from './MediaplanItems/MediaplanItems';

import environment from 'Api/Environment';
import {
  MediaplanSectionQuery as QueryType,
  AdvertiserProjectStage,
} from 'GraphTypes/MediaplanSectionQuery.graphql';

const MediaplanSectionQuery = graphql`
  query MediaplanSectionQuery(
    $campaignId: ID!
    $archived: Boolean
    $stages: [AdvertiserProjectStage!]
    $createdByAdmin: Boolean
  ) {
    campaign(id: $campaignId) {
      ...MediaplanItems_campaign
        @arguments(archived: $archived, stages: $stages, createdByAdmin: $createdByAdmin)
      ...Actions_campaign
    }
    currentUser {
      admin
      organization {
        currency
        newClient
        churned
        subscription {
          canExportMediaplan
          canImportMediaplan
          canViewCreatorPrices
          canManageLonglists
          paused
        }
      }
      permissions
    }
  }
`;

interface Props {
  className: string;
  campaignId: string;
  admin?: boolean;
  hasSubmission?: boolean;
  showUnlockCreatorBanner: () => void;
}

const MediaplanSection: React.FC<Props> = (props) => {
  const { className, campaignId, admin, hasSubmission, showUnlockCreatorBanner } = props;
  const stages = hasSubmission ? ['UNSENT' as AdvertiserProjectStage] : undefined;

  const showCreatedByAdmin = !!admin;

  return (
    <div className={`${styles.root} ${className}`}>
      <QueryRenderer<QueryType>
        environment={environment}
        query={MediaplanSectionQuery}
        variables={{ campaignId, archived: false, stages, createdByAdmin: showCreatedByAdmin }}
        render={({ props: queryProps }) => {
          if (!queryProps) return null;

          const { campaign, currentUser } = queryProps;
          const admin = currentUser?.admin;
          const permissions = currentUser?.permissions;
          const currency = currentUser?.organization?.currency;
          const canImportMediaplan = currentUser?.organization?.subscription?.canImportMediaplan;
          const canViewCreatorPrices =
            currentUser?.organization?.subscription?.canViewCreatorPrices;
          const canManageLonglists = currentUser?.organization?.subscription?.canManageLonglists;

          const isChurned = currentUser?.organization?.churned;
          const isNewClient = currentUser?.organization?.newClient;
          const isPlanPaused = Boolean(currentUser?.organization?.subscription?.paused);

          const isBlockedView = !admin && (isChurned || isNewClient || isPlanPaused);

          return (
            <Fragment>
              <Header
                admin={admin}
                hasSubmission={hasSubmission}
                className={styles.header}
                campaignId={campaignId}
              />
              <MediaplanItems
                className={styles.mediaplanItems}
                campaign={campaign}
                campaignId={campaignId}
                stages={stages}
                admin={admin}
                createdByAdmin={showCreatedByAdmin}
                canViewCreatorPrices={canViewCreatorPrices}
                isBlockedView={isBlockedView}
                showUnlockCreatorBanner={showUnlockCreatorBanner}
              />

              <Footer
                admin={admin}
                hasSubmission={hasSubmission}
                currency={currency}
                className={styles.footer}
                campaign={campaign}
                canImportMediaplan={canImportMediaplan}
                canManageLonglists={canManageLonglists}
                permissions={permissions}
              />
            </Fragment>
          );
        }}
      />
    </div>
  );
};

export default track({ section: 'mediaplan' })(MediaplanSection);
