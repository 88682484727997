import React, { useContext, useEffect, useRef } from 'react';

import styles from './DiscoverySearchSection.pcss';
import Header from './DiscoveryHeader/DiscoveryHeader';
import DiscoverySearchContent from './DiscoverySearchContent/DiscoverySearchContent';

import { DiscoverySearchSectionQuery as QueryType } from 'GraphTypes/DiscoverySearchSectionQuery.graphql';

import UpButton from 'Molecules/UpButton/UpButton';
import { graphql, PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { DiscoveryCreatorsContext } from '../DiscoveryCreatorsContext';

interface Props {}

export const DiscoveryQuery = graphql`
  query DiscoverySearchSectionQuery($listId: ID!) {
    currentUser {
      admin
      organization {
        id
        currency
      }
    }
    customList(id: $listId) {
      id
      name
      creators {
        totalCount
        edges {
          node {
            __typename
            id
            username
            profile {
              profilePictureFile {
                ... on Image {
                  transformations {
                    collageThumbnailUrl
                  }
                }
              }
            }
            ... on InstagramCreator {
              user {
                profilePictureUrl
              }
            }
            ... on YoutubeCreator {
              channel {
                id
                thumbnailUrl
                name
              }
            }
            ... on ContentCreator {
              avatarUrl
            }
            ... on TiktokCreator {
              user {
                avatar {
                  secureUrl
                }
                name
              }
            }
          }
        }
      }
    }
  }
`;

const DiscoverySearchSection = (props: Props) => {
  const {} = props;
  const mediaplanEl = useRef(null);

  const [queryReference, loadQuery] = useQueryLoader<QueryType>(DiscoveryQuery);
  const { listId } = useContext(DiscoveryCreatorsContext);

  useEffect(() => {
    loadQuery({
      listId,
    });
  }, []);

  const handleUpClick = () => {
    mediaplanEl.current.scrollTop = 0;
  };

  if (!queryReference) {
    return null;
  }

  return (
    <div ref={mediaplanEl} id="resultsContainer">
      <UpButton className={styles.up} containerId="resultsContainer" onClick={handleUpClick} />
      <div className={styles.root}>
        <Header className={styles.header} />{' '}
        <DiscoverySearchContent queryReference={queryReference} />
      </div>
    </div>
  );
};

export default DiscoverySearchSection;
