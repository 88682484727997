import React from 'react';
import camelCase from 'lodash/camelCase';

import styles from './CreatorBadge.pcss';
import { getBadgeData } from './util';

import Text from 'Atoms/Text/Text';
import { TextType } from 'Types/common';
import { CreatorBadgeEnum } from 'Types/enums';
import Tooltip from 'Atoms/Tooltip/Tooltip';

interface Props {
  className?: string;
  badge?: string;
  withText?: boolean;
  withIcon?: boolean;
  textData?: TextType;
  iconClassName?: string;
  withTooltip?: boolean;
  tooltipId?: string;
}

const CreatorBadge: React.FC<Props> = (props) => {
  const {
    className,
    badge,
    textData,
    withText = true,
    withIcon = true,
    iconClassName,
    withTooltip,
    tooltipId,
  } = props;

  if (!badge) return null;

  const { icon, label, tooltip } = getBadgeData(badge as CreatorBadgeEnum);

  const badgeLayout = (
    <div className={`${styles[camelCase(badge)]} ${styles.root} ${className}`}>
      {withIcon && icon && <img src={icon} className={iconClassName} />}
      {withText && <Text msg={label} className={withIcon ? styles.title : ''} {...textData} />}
    </div>
  );

  if (withTooltip) {
    return (
      <Tooltip tooltipMsg={tooltip} id={tooltipId} place="top">
        {badgeLayout}
      </Tooltip>
    );
  }
  return badgeLayout;
};

export default CreatorBadge;
