/**
 * @generated SignedSource<<16d3a809b833ef84a191bb15ea2d5cc8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT" | "%future added value";
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type OutreachTransport = "GMAIL" | "SENDGRID" | "%future added value";
export type CreateOutreachBatchBySearchInput = {
  activateAfterCompletion?: boolean | null | undefined;
  campaignId: string;
  clientMutationId?: string | null | undefined;
  gmailAuthorizationId?: string | null | undefined;
  id?: string | null | undefined;
  limit: number;
  searchQuery: SearchQueryInput;
  senderEmail?: string | null | undefined;
  senderName?: string | null | undefined;
  templateId: string;
  transport: OutreachTransport;
};
export type SearchQueryInput = {
  acceptsBarter?: boolean | null | undefined;
  active?: boolean | null | undefined;
  ageRangeIds?: ReadonlyArray<string> | null | undefined;
  audienceAgeRangePercentage?: number | null | undefined;
  audienceCitiesIds?: ReadonlyArray<string> | null | undefined;
  audienceCityPercentage?: number | null | undefined;
  audienceCountryPercentage?: number | null | undefined;
  audienceEthnicityIds?: ReadonlyArray<string> | null | undefined;
  audienceGenderId?: string | null | undefined;
  audienceGenderPercentage?: number | null | undefined;
  audienceInterestPercentage?: number | null | undefined;
  businessAccountEnabled?: boolean | null | undefined;
  campaignId?: string | null | undefined;
  campaignsCompletionsCountFrom?: number | null | undefined;
  campaignsCompletionsCountTo?: number | null | undefined;
  categoryIds?: ReadonlyArray<string> | null | undefined;
  cityIds?: ReadonlyArray<string> | null | undefined;
  contentCreator?: boolean | null | undefined;
  countryIds?: ReadonlyArray<string> | null | undefined;
  creatorAnimalIds?: ReadonlyArray<string> | null | undefined;
  creatorBadgeIds?: ReadonlyArray<string> | null | undefined;
  creatorBirthdateFrom?: any | null | undefined;
  creatorBirthdateTo?: any | null | undefined;
  creatorBodyTypeIds?: ReadonlyArray<string> | null | undefined;
  creatorCountryIds?: ReadonlyArray<string> | null | undefined;
  creatorEthnicityIds?: ReadonlyArray<string> | null | undefined;
  creatorGenderId?: string | null | undefined;
  creatorHairTypeIds?: ReadonlyArray<string> | null | undefined;
  creatorIdsToExclude?: ReadonlyArray<string> | null | undefined;
  creatorLanguageIds?: ReadonlyArray<string> | null | undefined;
  creatorTagIds?: ReadonlyArray<string> | null | undefined;
  creatorTypes?: ReadonlyArray<CreatorType> | null | undefined;
  currency?: Currency | null | undefined;
  customListIds?: ReadonlyArray<string> | null | undefined;
  employed?: boolean | null | undefined;
  engagementRateFrom?: number | null | undefined;
  engagementRateTo?: number | null | undefined;
  excludeBadges?: boolean | null | undefined;
  excludeCreatorsWorkedWith?: boolean | null | undefined;
  excludeHiredMoreThanOnce?: boolean | null | undefined;
  exclusions?: ReadonlyArray<SearchQueryExclusionParams> | null | undefined;
  favorite?: boolean | null | undefined;
  followersFrom?: number | null | undefined;
  followersTo?: number | null | undefined;
  hashtags?: ReadonlyArray<string> | null | undefined;
  includeAmazonStorefrontLink?: boolean | null | undefined;
  includeHidden?: boolean | null | undefined;
  interestIds?: ReadonlyArray<string> | null | undefined;
  limit?: number | null | undefined;
  onlyCreatorsWorkedWith?: boolean | null | undefined;
  owned?: boolean | null | undefined;
  paidSocialEnabled?: boolean | null | undefined;
  postPriceFrom?: number | null | undefined;
  postPriceTo?: number | null | undefined;
  postsReachFrom?: number | null | undefined;
  postsReachTo?: number | null | undefined;
  realFollowersFrom?: number | null | undefined;
  realFollowersTo?: number | null | undefined;
  reelsPlaysMedianFrom?: number | null | undefined;
  reelsPlaysMedianTo?: number | null | undefined;
  reviewedAtFrom?: any | null | undefined;
  reviewedAtTo?: any | null | undefined;
  storiesReachFrom?: number | null | undefined;
  storiesReachTo?: number | null | undefined;
  strictlyByPrimaryCategory?: boolean | null | undefined;
  textQuery?: string | null | undefined;
  viewsMedianFrom?: number | null | undefined;
  viewsMedianTo?: number | null | undefined;
  viewsPerPostFrom?: number | null | undefined;
  viewsPerPostTo?: number | null | undefined;
  withTiktokShop?: boolean | null | undefined;
};
export type SearchQueryExclusionParams = {
  advertiserProjectStages?: ReadonlyArray<AdvertiserProjectStage> | null | undefined;
  campaignId: string;
};
export type CreateOutreachBatchBySearchMutation$variables = {
  input: CreateOutreachBatchBySearchInput;
};
export type CreateOutreachBatchBySearchMutation$data = {
  readonly createOutreachBatchBySearch: {
    readonly __typename: "CreateOutreachBatchBySearchPayload";
    readonly batch: {
      readonly id: string;
    };
  } | {
    readonly __typename: "NotFoundError";
    readonly message: string;
  } | {
    readonly __typename: "ValidationError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type CreateOutreachBatchBySearchMutation = {
  response: CreateOutreachBatchBySearchMutation$data;
  variables: CreateOutreachBatchBySearchMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "message",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "createOutreachBatchBySearch",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OutreachBatch",
            "kind": "LinkedField",
            "name": "batch",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "CreateOutreachBatchBySearchPayload",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v1/*: any*/),
        "type": "NotFoundError",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v1/*: any*/),
        "type": "ValidationError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateOutreachBatchBySearchMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateOutreachBatchBySearchMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "bdff5ecd1b41bb95ce7da2f64a2b9c3f",
    "id": null,
    "metadata": {},
    "name": "CreateOutreachBatchBySearchMutation",
    "operationKind": "mutation",
    "text": "mutation CreateOutreachBatchBySearchMutation(\n  $input: CreateOutreachBatchBySearchInput!\n) {\n  createOutreachBatchBySearch(input: $input) {\n    __typename\n    ... on CreateOutreachBatchBySearchPayload {\n      batch {\n        id\n      }\n    }\n    ... on NotFoundError {\n      message\n    }\n    ... on ValidationError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "833cba3bca50b249bd9e73287cd460b8";

export default node;
