/**
 * @generated SignedSource<<e431b0c8d3cc3e81bb1ced7790142db5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Notifications_InAppNotifications_MarkAllAsReadInput = {
  clientMutationId?: string | null | undefined;
  upToTimestamp: any;
};
export type MarkAllInAppNotificationsAsReadMutation$variables = {
  input: Notifications_InAppNotifications_MarkAllAsReadInput;
};
export type MarkAllInAppNotificationsAsReadMutation$data = {
  readonly markAllInAppNotificationsAsRead: {
    readonly notifications: ReadonlyArray<{
      readonly read: boolean;
    }> | null | undefined;
  } | null | undefined;
};
export type MarkAllInAppNotificationsAsReadMutation = {
  response: MarkAllInAppNotificationsAsReadMutation$data;
  variables: MarkAllInAppNotificationsAsReadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "read",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MarkAllInAppNotificationsAsReadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Notifications_InAppNotifications_MarkAllAsReadPayload",
        "kind": "LinkedField",
        "name": "markAllInAppNotificationsAsRead",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "notifications",
            "plural": true,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MarkAllInAppNotificationsAsReadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Notifications_InAppNotifications_MarkAllAsReadPayload",
        "kind": "LinkedField",
        "name": "markAllInAppNotificationsAsRead",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "notifications",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f0694a7ca3434405210f94daf95f3f11",
    "id": null,
    "metadata": {},
    "name": "MarkAllInAppNotificationsAsReadMutation",
    "operationKind": "mutation",
    "text": "mutation MarkAllInAppNotificationsAsReadMutation(\n  $input: Notifications_InAppNotifications_MarkAllAsReadInput!\n) {\n  markAllInAppNotificationsAsRead(input: $input) {\n    notifications {\n      __typename\n      read\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dd8566bd303ce768b8f544d736e827f9";

export default node;
