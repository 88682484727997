import { graphql, useMutation } from 'react-relay';

import { MarkInAppNotificationsAsReadMutation } from 'GraphTypes/MarkInAppNotificationsAsReadMutation.graphql';
import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const MarkInAppNotificationsAsReadMutation = graphql`
  mutation MarkInAppNotificationsAsReadMutation(
    $input: Notifications_InAppNotifications_MarkAsReadInput!
  ) {
    markInAppNotificationsAsRead(input: $input) {
      clientMutationId
    }
  }
`;

export const useMarkInAppNotificationsAsReadMutation: BaseMutation<
  MarkInAppNotificationsAsReadMutation
> = (config) => {
  const [markInAppNotificationsAsReadQuery, loading] =
    useMutation<MarkInAppNotificationsAsReadMutation>(MarkInAppNotificationsAsReadMutation);

  const markInAppNotificationsAsReadCaller: BaseMutationExecFn<
    MarkInAppNotificationsAsReadMutation
  > = (execConfig) => {
    markInAppNotificationsAsReadQuery({
      ...config,
      ...execConfig,
    });
  };
  return [markInAppNotificationsAsReadCaller, loading];
};
