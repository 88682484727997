import React, { useState, useEffect, PropsWithChildren, MutableRefObject } from 'react';
import { usePaginationFragmentHookType } from 'react-relay/relay-hooks/usePaginationFragment';
import { OperationType } from 'relay-runtime';

import styles from './LoaderHandler.css';

import Spinner from 'Atoms/Spinner/Spinner';

const FOOTER_OFFSET = 250;

interface Props {
  loadButton?: JSX.Element;
  startLoadButton?: JSX.Element;
  additionalControls?: JSX.Element;
  reverse?: boolean;
  blocked?: boolean;
  offset?: number;
  className?: string;
  preloader?: JSX.Element;
  hideLoader?: boolean;
  count: number;
  containerId?: string;
  relay: usePaginationFragmentHookType<OperationType, any, unknown>;
  items: Array<unknown>;
  containerRef?: MutableRefObject<HTMLDivElement | null>;
}

const LoaderHandlerWithHooks: React.FC<PropsWithChildren<Props>> = (props) => {
  const {
    className,
    loadButton,
    startLoadButton,
    offset = FOOTER_OFFSET,
    reverse,
    preloader,
    hideLoader,
    children,
    relay,
    count,
    blocked,
    containerId,
    additionalControls,
    items,
    containerRef,
  } = props;

  const hasMore = reverse ? relay.hasPrevious : relay.hasNext;
  const loadMore = reverse ? relay.loadPrevious : relay.loadNext;
  const loading = relay.isLoadingNext || relay.isLoadingPrevious;

  const [showStartLoadButton, setShowStartLoadButton] = useState(!!startLoadButton);
  const [listItems, setListItems] = useState(items);
  const [scrollPosition, setScrollPosition] = useState(0);

  let container = containerId ? document.getElementById(containerId) : document;
  if (containerRef) {
    container = containerRef.current;
  }
  const scrollContainer = containerId
    ? document.getElementById(containerId)
    : document.documentElement;

  useEffect(() => {
    if (!container || blocked) {
      return;
    }

    if (!startLoadButton && !loadButton && !additionalControls) {
      initScrollListener();
    }

    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, [container, relay, listItems]);

  useEffect(() => {
    if (items.length > listItems.length) {
      setListItems(items);
      if (scrollContainer) {
        scrollContainer.scrollTop = scrollContainer.scrollHeight - scrollPosition - 72;
      }
    }
  }, [items, listItems, scrollPosition]);

  useEffect(() => {
    if (scrollContainer) {
      setScrollPosition(scrollContainer.scrollHeight - (scrollContainer?.scrollTop || 0));
    }
  }, [items]);

  const initScrollListener = () => {
    container?.addEventListener('scroll', handleScroll);
  };

  const handleScroll = () => {
    const scrollTop = Number(scrollContainer?.scrollTop);
    const scrollHeight = Number(scrollContainer?.scrollHeight);
    const clientHeight = Number(scrollContainer?.clientHeight);

    if (reverse) {
      if (scrollTop < offset) {
        handleLoadMore();
      }

      return;
    }
    if (scrollTop > scrollHeight - clientHeight - offset) {
      handleLoadMore();
    }
  };

  const handleLoadMore = () => {
    if (!hasMore || loading) {
      return;
    }
    loadMore(count);
  };

  const handleStartLoadClick = () => {
    handleLoadMore();
    initScrollListener();
    setShowStartLoadButton(false);
  };

  const handleLoadClick = () => {
    handleLoadMore();
  };

  const preloaderEl = preloader ? preloader : <Spinner className={styles.preloader} />;
  return (
    <div className={className}>
      {reverse && loading && !hideLoader && preloaderEl}
      {children}
      {!reverse && loading && !hideLoader && preloaderEl}
      {showStartLoadButton && hasMore && (
        <div onClick={handleStartLoadClick} className={styles.startLoadButton}>
          {startLoadButton}
        </div>
      )}
      {loadButton && !loading && hasMore && <div onClick={handleLoadClick}>{loadButton}</div>}
      {additionalControls &&
        !loading &&
        React.cloneElement(additionalControls, {
          hasMore: hasMore,
          onClickNext: handleLoadClick,
        })}
    </div>
  );
};

export default LoaderHandlerWithHooks;
