import React from 'react';

import styles from './MediaCarousel.pcss';
import MediaCarousel from './MediaCarousel';

import Drawer from 'Components/ui/Drawer/Drawer';
import { MediaCarousel_message$key } from 'GraphTypes/MediaCarousel_message.graphql';

export interface Props {
  id: string;
  currentIndex?: number;
  message: MediaCarousel_message$key;
}

const MediaCarouselContainer: React.FC<Props> = (props) => {
  const { id } = props;
  return (
    <Drawer
      rootKey={`message-attachments-${id}`}
      backdropClassName={styles.backdrop}
      className={styles.container}
      fullView
      position="center"
      theme="dark"
    >
      <MediaCarousel {...props} />
    </Drawer>
  );
};

export default MediaCarouselContainer;
