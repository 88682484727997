/**
 * @generated SignedSource<<898619304eeff6ddd460dc0dad623172>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BookACallCalendarQuery$variables = Record<PropertyKey, never>;
export type BookACallCalendarQuery$data = {
  readonly currentUser: {
    readonly advertiserProfile: {
      readonly firstName: string | null | undefined;
      readonly lastName: string | null | undefined;
    } | null | undefined;
    readonly email: string;
  } | null | undefined;
};
export type BookACallCalendarQuery = {
  response: BookACallCalendarQuery$data;
  variables: BookACallCalendarQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BookACallCalendarQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AdvertiserProfile",
            "kind": "LinkedField",
            "name": "advertiserProfile",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BookACallCalendarQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AdvertiserProfile",
            "kind": "LinkedField",
            "name": "advertiserProfile",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "46360a8696a22cfcb233908c337308e6",
    "id": null,
    "metadata": {},
    "name": "BookACallCalendarQuery",
    "operationKind": "query",
    "text": "query BookACallCalendarQuery {\n  currentUser {\n    email\n    advertiserProfile {\n      firstName\n      lastName\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "81fa2d56f49d77c90012b1dc2ec770dd";

export default node;
