import React, { useState } from 'react';
import { createPaginationContainer, graphql, RelayPaginationProp } from 'react-relay';

import Skeleton from '../../../components/Skeleton/Skeleton';

import styles from './Collaborations.pcss';
import Collaboration from './Collaboration/Collaboration';
import CreatorChats from './CreatorChats/CreatorChats';
import { Projects } from './util';

import Drawer from 'Components/ui/Drawer/Drawer';
import Text from 'Components/ui/Text/Text';
import IconNew from 'Components/ui/Icon/Icon';
import EmptySearchRequest from 'Molecules/EmptySearchRequest/EmptySearchRequest';
import LoaderHandler from 'Organisms/LoaderHandler/LoaderHandler';
import type { Collaborations_organization } from 'GraphTypes/Collaborations_organization.graphql';

const ITEMS_COUNT = 20;
const FOOTER_OFFSET = 279;

interface Props {
  relay: RelayPaginationProp;
  organization: Collaborations_organization;
  textQuery?: string;
  campaignIds?: string[];
  brandIds?: string[];
  onParamsClear: () => void;
}

const Collaborations: React.FC<Props> = (props) => {
  const { organization, relay, campaignIds, brandIds, textQuery, onParamsClear } = props;

  const [isChatsOpened, setChatsOpened] = useState(false);
  const [chatDetails, setChatDetails] = useState<Projects | undefined>(undefined);

  const pastCollaborations = organization.pastCollaborations?.edges;

  const isRequestEmpty = !brandIds?.length && !campaignIds?.length && !textQuery;

  if (!pastCollaborations?.length && !isRequestEmpty) {
    return <EmptySearchRequest onParamsClear={onParamsClear} />;
  }

  if (!pastCollaborations?.length) {
    return (
      <div className={styles.emptyWrap}>
        <IconNew name="Flag" />
        <Text msg="creators.dashboard.previous_collaborations.empty_list" type="md" />
      </div>
    );
  }

  const handleChatClick = (projects: Projects) => {
    setChatDetails(projects);
    setChatsOpened(true);
  };

  const list = pastCollaborations.map((item) => {
    if (!item?.node) return null;
    return (
      <Collaboration
        key={item.node.creator.id}
        collaboration={item.node}
        onChatClick={handleChatClick}
      />
    );
  });

  const handleDrawerClose = () => {
    setChatDetails(undefined);
    setChatsOpened(false);
  };

  return (
    <>
      <div className={styles.listHeader}>
        <Text
          type="md"
          className={styles.listHeaderText}
          msg="creators.dashboard.lists.header.username"
        />
        <Text
          type="md"
          className={styles.listHeaderText}
          msg="creators.dashboard.lists.header.categories"
        />
        <Text
          type="md"
          className={styles.listHeaderText}
          msg="creators.dashboard.lists.header.followers"
        />
        <Text
          type="md"
          className={styles.listHeaderText}
          msg="creators.dashboard.lists.header.er"
        />
        <Text
          type="md"
          className={styles.listHeaderText}
          msg="creators.dashboard.lists.header.profile"
        />
        <Text
          type="md"
          className={styles.listHeaderText}
          msg="creators.dashboard.lists.header.collabs"
        />
        <div />
      </div>
      <LoaderHandler
        relay={relay}
        count={ITEMS_COUNT}
        offset={FOOTER_OFFSET}
        preloader={<Skeleton />}
      >
        <div className={styles.list}>{list}</div>
      </LoaderHandler>
      <Drawer
        opened={isChatsOpened}
        onClose={handleDrawerClose}
        rootKey="creator_chats"
        className={styles.drawer}
      >
        {chatDetails && <CreatorChats chatDetails={chatDetails} />}
      </Drawer>
    </>
  );
};

export default createPaginationContainer(
  Collaborations,
  {
    organization: graphql`
      fragment Collaborations_organization on Organization
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 18 }
        cursor: { type: "String" }
        campaignIds: { type: "[ID!]" }
        brandIds: { type: "[ID!]" }
        textQuery: { type: "String" }
      ) {
        pastCollaborations(
          first: $count
          after: $cursor
          campaignIds: $campaignIds
          brandIds: $brandIds
          textQuery: $textQuery
        ) @connection(key: "Organization_pastCollaborations", filters: []) {
          edges {
            node {
              ...Collaboration_collaboration
              creator {
                id
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.organization.pastCollaborations;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        ...props,
      };
    },
    query: graphql`
      query CollaborationsPaginationQuery(
        $count: Int!
        $cursor: String
        $campaignIds: [ID!]
        $brandIds: [ID!]
        $textQuery: String
      ) {
        currentUser {
          organization {
            ...Collaborations_organization
              @arguments(
                count: $count
                cursor: $cursor
                campaignIds: $campaignIds
                brandIds: $brandIds
                textQuery: $textQuery
              )
          }
        }
      }
    `,
  }
);
