import React, {
  useEffect,
  useRef,
  useContext,
  PropsWithChildren,
  useMemo,
  useCallback,
} from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import IconNew from '../Icon/Icon';

import styles from './Drawer.pcss';
import { getDrawerContextInstance } from './Drawer.Context';

import useMountTransition from 'Hooks/useMountTransition';

type DrawerOpenedState = boolean;
type DrawerCloseCallback = () => void;
type DrawerOpenSidePosition = 'left' | 'right' | 'center';
type DrawerRemoveOnCloseStatus = boolean;
type DrawerKey = string;
type DrawerCloseButtonStatus = boolean;
type DrawerTheme = 'dark' | 'light';

type Props = PropsWithChildren<{
  rootKey: DrawerKey;
  opened?: DrawerOpenedState;
  onClose?: DrawerCloseCallback;
  onOpen?: () => void;
  position?: DrawerOpenSidePosition;
  className?: string;
  fullView?: boolean;
  backdropClassName?: string;
  containerClassName?: string;
  theme?: DrawerTheme;
  removeWhenClosed?: DrawerRemoveOnCloseStatus;
  needCloseButton?: DrawerCloseButtonStatus;
}>;

const Drawer: React.FC<Props> = (props) => {
  const {
    opened,
    children,
    className,
    backdropClassName,
    containerClassName,
    position = 'right',
    removeWhenClosed = true,
    rootKey = 'drawer-root',
    needCloseButton = true,
    theme = 'light',
    fullView,
    onClose,
    onOpen,
  } = props;
  const { activeDrawerId, idPrefix, openDrawer, closeDrawer } = useContext(
    getDrawerContextInstance()
  );

  useEffect(() => {
    if (opened) {
      openDrawer?.(rootKey);
    } else {
      closeDrawer?.(rootKey);
    }
  }, [opened, rootKey]);

  const _opened = useMemo<boolean>(() => {
    return activeDrawerId === rootKey;
  }, [activeDrawerId, rootKey]);

  useEffect(() => {
    if (_opened) {
      onOpen?.();
    }
  }, [_opened, onOpen]);

  const drawerId = useMemo(() => `${idPrefix}${rootKey}`, [idPrefix, rootKey]);

  const createDrawerContainer = () => {
    const drawerRoot = document.createElement('div');
    drawerRoot.setAttribute('id', drawerId);
    return drawerRoot;
  };
  const bodyRef = useRef(document.body);
  const portalRootRef = useRef(document.getElementById(drawerId) || createDrawerContainer());
  const isTransitioning = useMountTransition(_opened, 300);

  useEffect(() => {
    bodyRef.current.appendChild(portalRootRef.current);
    const portal = portalRootRef.current;
    const bodyEl = bodyRef.current;

    return () => {
      portal.remove();
      bodyEl.style.overflow = '';
    };
  }, []);

  useEffect(() => {
    const updatePageScroll = () => {
      if (_opened) {
        bodyRef.current.style.overflow = 'hidden';
      } else {
        bodyRef.current.style.overflow = '';
      }
    };

    updatePageScroll();
  }, [_opened]);

  useEffect(() => {
    const onKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeDrawer(rootKey);
        onClose?.();
      }
    };

    if (_opened) {
      window.addEventListener('keyup', onKeyPress);
    }

    return () => {
      window.removeEventListener('keyup', onKeyPress);
    };
  }, [_opened, closeDrawer, rootKey]);

  const handleCloseDrawer = useCallback(() => {
    closeDrawer(rootKey);
    onClose?.();
  }, [onClose, rootKey, closeDrawer]);

  if (!isTransitioning && removeWhenClosed && !_opened) {
    return null;
  }

  return createPortal(
    <div
      className={classNames(
        styles.drawerContainer,
        styles[theme],
        {
          [styles.open]: _opened,
          [styles.in]: isTransitioning,
          [styles.fullView]: fullView,
        },
        containerClassName
      )}
    >
      <div className={classNames(styles.backdrop, backdropClassName)} onClick={handleCloseDrawer} />
      <div className={classNames(styles.drawer, styles[position], className)} role="dialog">
        {children}
        {needCloseButton && (
          <div className={styles.closeBtn} onClick={handleCloseDrawer}>
            <IconNew name={'Close-small'} />
          </div>
        )}
      </div>
    </div>,
    portalRootRef.current
  );
};

export default Drawer;

// types

export type { Props as DrawerProps };
