import React, { ChangeEvent, FC, InputHTMLAttributes, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { useIntl } from 'react-intl';

import styles from './CheckboxSelect.pcss';

import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import { IconName } from 'Components/ui/types';
import Input from 'Components/ui/Input/Input';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import useOutsideClick from 'Hooks/useClickOutside';
import { Props as TextProps } from 'Components/ui/Text/Text';

export type CheckboxItemType = {
  id: string | number;
  label?: string;
  labelMsg?: string;
  subtitleData?: TextProps;
  isChecked: boolean;
  category?: string;
  isPopular?: boolean;
  icon?: React.ReactNode;
  iconName?: IconName;
};

interface CheckboxItemProps {
  item: CheckboxItemType;
  onClick: (item: CheckboxItemType) => void;
}
const CheckboxItem: FC<CheckboxItemProps> = ({ item, onClick }) => {
  const { label, isChecked, labelMsg, subtitleData, icon = null, iconName } = item;
  const handleOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (onClick) {
      onClick(item);
    }
  };
  return (
    <div
      onClick={handleOnClick}
      className={classnames(styles.checkboxItem, { [styles.withIcon]: !!(icon || iconName) })}
    >
      <div className={styles.checkboxItemContent}>
        {icon}
        {iconName && <Icon name={iconName} />}
        <div>
          <Text text={label} type="md" msg={labelMsg} className={styles.title} />
          {subtitleData && <Text type="md" {...subtitleData} className={styles.subtitle} />}
        </div>
      </div>
      <div
        className={classnames(styles.box, {
          [styles.checked]: isChecked,
          [styles.alignedRight]: !!icon,
        })}
      >
        {isChecked && <div className={styles.checkboxCheckedItem} />}
      </div>
    </div>
  );
};

export interface ISelectCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  items: CheckboxItemType[];
  placeholder?: string;
  placeholderMsg?: string;
  handleClick: (item: string | number) => void;
  handleClear?: () => void;
  handleOpenStateChange?: () => void;
  withSearch?: boolean;
  handleDirty?: (newState: boolean) => void;
  isDirty?: boolean;
  handleSearch?: (value: string) => void;
  className?: string;
  containerClassName?: string;
  hideByAlphabet?: boolean;
  groupSubtitle?: string;
  accent?: boolean;
  bordered?: boolean;
  place?: 'bottomLeft' | 'bottomRight';
  hideSelected?: boolean;
}
const CheckboxSelect: FC<ISelectCheckboxProps> = ({
  placeholderMsg,
  items = [],
  handleClick,
  withSearch = false,
  placeholder,
  handleDirty,
  isDirty,
  handleSearch,
  className,
  containerClassName,
  hideByAlphabet,
  groupSubtitle,
  accent,
  bordered,
  place = 'bottomLeft',
  handleClear,
  handleOpenStateChange,
  hideSelected,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleContainerClick = () => setIsOpen((prevState) => !prevState);

  const dropdownRef = useOutsideClick<HTMLDivElement>(() => {
    setIsOpen(false);
  });
  const [currentSearch, setCurrentSearch] = useState<string>('');
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (handleSearch) {
      handleSearch(event.target.value);
    }
    setCurrentSearch(event?.target?.value);
  };

  useEffect(() => {
    if (isOpen && handleOpenStateChange) {
      handleOpenStateChange();
    }
  }, [isOpen]);

  const filteredBySearchItems = useMemo(
    () =>
      currentSearch && !handleSearch
        ? items.filter((item) => item?.label?.toUpperCase().includes(currentSearch.toUpperCase()))
        : items,
    [items, currentSearch, handleSearch]
  );
  const filteredPopularItems = useMemo(
    () => filteredBySearchItems.filter((item) => item?.isPopular),
    [filteredBySearchItems, handleSearch]
  );

  const filteredUnpopularItems = useMemo(
    () => filteredBySearchItems.filter((item) => !item?.isPopular),
    [filteredBySearchItems, handleSearch]
  );

  const intl = useIntl();

  const currentPlaceholder = useMemo(() => {
    if (isDirty === undefined || hideSelected) {
      return placeholderMsg ? intl.formatMessage({ id: placeholderMsg }) : placeholder;
    }
    const checkedItems = items.filter((item) => item.isChecked);
    if (checkedItems.length === 0) {
      return placeholderMsg ? intl.formatMessage({ id: placeholderMsg }) : placeholder;
    }
    if (checkedItems.length === 1) {
      if (checkedItems[0].labelMsg) {
        return intl.formatMessage({ id: checkedItems[0].labelMsg });
      }
      return checkedItems[0].label;
    }
    const checkedFirstItem = checkedItems[0]?.label
      ? checkedItems[0]?.label
      : intl.formatMessage({ id: checkedItems[0].labelMsg });
    return `${checkedFirstItem}... +${checkedItems?.length - 1}`;
  }, [isDirty, items, placeholder, placeholderMsg, hideSelected]);

  const handleResetSearch = () => setCurrentSearch('');
  const handleSelectionChange = (item: CheckboxItemType) => {
    if (handleDirty) {
      const checked: CheckboxItemType[] = [];
      const unchecked: CheckboxItemType[] = [];
      items.forEach((item) => {
        if (item.isChecked) {
          checked.push(item);
        } else {
          unchecked.push(item);
        }
      });
      if (checked.length === 0) {
        handleDirty(true);
      }
      if (checked.length === 1 && item.id === checked[0].id) {
        handleDirty(false);
      }
    }
    handleClick(item.id);
  };

  return (
    <div
      onClick={handleContainerClick}
      ref={dropdownRef}
      className={classnames(styles.root, className, styles[place], {
        [styles.open]: isOpen,
        [styles.dirty]: isDirty,
        [styles.bordered]: bordered && !isDirty,
        [styles.accentSelect]: !isDirty && accent,
      })}
    >
      <Text type="md" text={currentPlaceholder} className={styles.placeholder} />
      <Icon name="Arrow-small-down" />
      {isOpen && (
        <div
          className={classnames(styles.container, containerClassName)}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {withSearch && (
            <Input
              leftIcon="Search-loop"
              placeholderMsg="search"
              onChange={handleSearchChange}
              value={currentSearch}
              className={styles.searchInput}
              borderless
              onResetValue={handleResetSearch}
            />
          )}
          <div className={styles.content}>
            {!!filteredPopularItems?.length && (
              <>
                <Text text="Popular" type="label" color="grey" className={styles.label} />
                {filteredPopularItems?.map((item) => (
                  <CheckboxItem item={item} onClick={handleSelectionChange} key={item.id} />
                ))}
              </>
            )}
            {!!filteredUnpopularItems?.length && (
              <>
                {(!hideByAlphabet || groupSubtitle) && (
                  <Text
                    msg={hideByAlphabet ? 'general.by_alphabet' : groupSubtitle}
                    type="label"
                    color="grey"
                    className={styles.label}
                  />
                )}
                {filteredUnpopularItems?.map((item) => (
                  <CheckboxItem item={item} onClick={handleSelectionChange} key={item.id} />
                ))}
              </>
            )}
            {!filteredBySearchItems?.length && (
              <Text
                msg="searchNotFound"
                type="label"
                color="grey"
                className={classnames(styles.label, styles.centered)}
              />
            )}
          </div>
          {isDirty && handleClear && (
            <div className={styles.clearBtnWrap}>
              <AlterButton
                bordered
                fluid
                msg="projects.filter.clear"
                handleClick={handleClear}
                className={styles.clearBtn}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default CheckboxSelect;
