import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { useIntl } from 'react-intl';

import styles from './NotificationItem.pcss';

import { amplitude } from 'Helpers/amplitude';
import { useMarkInAppNotificationsAsReadMutation } from 'Mutations/MarkInAppNotificationsAsRead.Mutation';
import { createDate } from 'Util/dateCreator';
import { getProjectsLink } from 'Util/links';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import TextButton from 'Components/ui/TextButton/TextButton';
import { NotificationItem_notification$key } from 'GraphTypes/NotificationItem_notification.graphql';
import notificationRocket from 'Images/general/notification-rocket.png';
import notificationRocket2x from 'Images/general/notification-rocket_2x.png';
import notificationWarning from 'Images/general/notification-warning.png';
import notificationWarning2x from 'Images/general/notification-warning_2x.png';
import notificationClose from 'Images/general/notification_close.png';
import notificationClose2x from 'Images/general/notification_close_2x.png';
import notificationApplications from 'Images/general/notification-applications.png';
import notificationApplications2x from 'Images/general/notification-applications_2x.png';
import notificationCreative from 'Images/general/notification-creative.png';
import notificationCreative2x from 'Images/general/notification-creative_2x.png';
import notificationContent from 'Images/general/notification-content.png';
import notificationContent2x from 'Images/general/notification-content_2x.png';
import notificationMessages from 'Images/general/notification_messages.png';
import notificationMessages2x from 'Images/general/notification_messages_2x.png';
import notificationUnresponsiveness from 'Images/general/notification-unresponsiveness.png';
import notificationUnresponsiveness2x from 'Images/general/notification-unresponsiveness_2x.png';
import classnames from 'classnames';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import { getCampaignLink } from 'Util/links';
import Tooltip from 'Atoms/Tooltip/Tooltip';

interface Props {
  inDropdown?: boolean;
  notification?: NotificationItem_notification$key;
}

const NotificationItem: React.FC<Props> = (props) => {
  const { notification, inDropdown } = props;

  const [markInAppNotificationsAsRead] = useMarkInAppNotificationsAsReadMutation();

  const intl = useIntl();

  const data = useFragment(
    graphql`
      fragment NotificationItem_notification on InAppNotification {
        id
        createdAt
        icon
        ctaText
        read
        type
        ... on InAppNotifications_CampaignActivated {
          campaign {
            id
            name
          }
        }
        ... on InAppNotifications_DailyOfferAcceptances {
          id
          totalApplicantsCount
        }
        ... on InAppNotifications_CreativeCreated {
          creative {
            project {
              id
              campaign {
                id
                name
              }
            }
          }
        }
        ... on InAppNotifications_ContentReviewSubmitted {
          project {
            id
            campaign {
              id
              name
              type
            }
          }
          contentReview {
            attachments {
              totalCount
            }
          }
          creatives {
            totalCount
          }
        }
        ... on InAppNotifications_DraftDeadlineOverdue {
          project {
            id
            campaign {
              id
              name
            }
          }
        }
        ... on InAppNotifications_BcaPermissionRevoked {
          project {
            id
            campaign {
              id
            }
          }
        }
        ... on InAppNotifications_AdvertiserUnresponsiveness {
          project {
            id
            campaign {
              id
              name
            }
          }
        }
      }
    `,
    notification
  );

  const type = data?.type;
  const id = data?.id || '';
  const read = data?.read;
  const createdAt = data?.createdAt ? createDate(data?.createdAt, 'MMM DD HH:mm') : '';

  const handleReadClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    markAsRead();
  };

  const markAsRead = () => {
    markInAppNotificationsAsRead({
      variables: {
        input: {
          ids: [id],
        },
      },
      optimisticUpdater: (store) => {
        const notificationItem = store.get(id);
        if (notificationItem) {
          notificationItem.setValue(true, 'read');
        }
      },
      updater: (store) => {
        const notificationItem = store.get(id);
        if (notificationItem) {
          notificationItem.setValue(true, 'read');
        }

        const userId = store?.getRoot()?.getLinkedRecord('currentUser')?.getValue('id');
        if (userId) {
          const count = store
            .get(userId as string)
            ?.getLinkedRecord('inAppNotifications(first:1,read:false)');
          const value = count?.getValue('totalCount');
          count?.setValue(0, 'totalCount');
          if (value) {
            count?.setValue(Number(value) - 1, 'totalCount');
          }
        }
      },
    });
  };

  const hadleItemClick = () => {
    amplitude.sendEvent<426>({
      id: '426',
      category: 'notification_item',
      name: 'click',
      param: { is_unread: !read, notification_type: type || '', is_bell: !!inDropdown },
    });
    markAsRead();
  };

  const getNotificationData = () => {
    switch (type) {
      case 'CAMPAIGN_ACTIVATED':
        const campaignId = data?.campaign?.id || '';
        const link = getCampaignLink({ campaignId });

        const actionBtn = (
          <SmartLink path={link} onClick={hadleItemClick}>
            <TextButton msg="notification_center.item.action.got_to_campaign" color="purple" />
          </SmartLink>
        );

        const icon = `${notificationRocket} 1x, ${notificationRocket2x} 2x`;

        const titleValues = { campaignName: `<b>${data?.campaign?.name}</b>` };

        return { link, actionBtn, titleValues, icon };

      case 'DAILY_OFFER_ACCEPTANCES': {
        const link = getProjectsLink({ stages: 'ACCEPTED' });
        const actionBtn = (
          <SmartLink path={link} onClick={hadleItemClick}>
            <TextButton msg="notification_center.item.action.review" color="purple" />
          </SmartLink>
        );
        const titleValues = {
          count: `<b>${data?.totalApplicantsCount}</b>`,
          title: `<b>${
            data?.totalApplicantsCount === 1 ? 'New Application' : 'New Applications'
          }</b>`,
        };
        const icon = `${notificationApplications} 1x, ${notificationApplications2x} 2x`;
        return { link, actionBtn, titleValues, icon };
      }
      case 'CREATIVE_CREATED': {
        const campaignId = data?.creative?.project?.campaign.id || '';
        const campaignName = data?.creative?.project?.campaign?.name || '';
        const projectId = data?.creative?.project?.id;
        const link = getProjectsLink({ projectId, campaignId });
        const actionBtn = (
          <SmartLink path={link} onClick={hadleItemClick}>
            <TextButton msg="notification_center.item.action.review" color="purple" />
          </SmartLink>
        );
        const icon = `${notificationCreative} 1x, ${notificationCreative2x} 2x`;
        const titleValues = {
          link: '<b>1 new publication link</b>',
          campaignName: `<b>${campaignName}</b>`,
        };
        return { link, actionBtn, titleValues, icon };
      }

      case 'CONTENT_REVIEW_SUBMITTED': {
        const campaignId = data?.project?.campaign?.id || '';
        const campaignName = data?.project?.campaign?.name || '';
        const campaignType = data?.project?.campaign?.type;
        const projectId = data?.project?.id;
        const count =
          campaignType === 'PRODUCT_SEEDING'
            ? data?.creatives?.totalCount
            : data?.contentReview?.attachments.totalCount;
        const link = getProjectsLink({ projectId, campaignId });
        const actionBtn = (
          <SmartLink path={link} onClick={hadleItemClick}>
            <TextButton msg="notification_center.item.action.review" color="purple" />
          </SmartLink>
        );
        const icon = `${notificationContent} 1x, ${notificationContent2x} 2x`;
        const titleValues = {
          value: `<b>${count} Content${Number(count) > 1 ? 's' : ''} for the ${campaignName}</b>`,
        };
        return { link, actionBtn, titleValues, icon };
      }

      case 'DRAFT_DEADLINE_OVERDUE': {
        const projectId = data?.project?.id;
        const campaignName = data?.project?.campaign.name;
        const campaignId = data?.project?.campaign.id;
        const link = getProjectsLink({ projectId, campaignId });

        const actionBtn = (
          <SmartLink path={link} onClick={hadleItemClick}>
            <TextButton msg="notification_center.item.action.review" color="purple" />
          </SmartLink>
        );

        const icon = `${notificationWarning} 1x, ${notificationWarning2x} 2x`;

        const titleValues = { campaignName: `<b>${campaignName}</b>` };

        return { link, actionBtn, icon, titleValues };
      }

      case 'BCA_PERMISSION_REVOKED': {
        const projectId = data?.project?.id;
        const campaignId = data?.project?.campaign.id;
        const link = getProjectsLink({ projectId, campaignId });
        const icon = `${notificationWarning} 1x, ${notificationWarning2x} 2x`;

        const actionBtn = (
          <SmartLink path={link} onClick={hadleItemClick}>
            <TextButton msg="notification_center.item.action.check_connection" color="purple" />
          </SmartLink>
        );

        return { link, actionBtn, icon };
      }

      case 'ADVERTISER_UNRESPONSIVENESS': {
        const projectId = data?.project?.id;
        const campaignId = data?.project?.campaign.id;
        const campaignName = data?.project?.campaign.name;
        const link = getProjectsLink({ projectId, campaignId });
        const icon = `${notificationUnresponsiveness} 1x, ${notificationUnresponsiveness2x} 2x`;

        const actionBtn = (
          <SmartLink path={link} onClick={hadleItemClick}>
            <TextButton msg="notification_center.item.action.go_to_chat" color="purple" />
          </SmartLink>
        );

        const titleValues = { campaignName: `<b>${campaignName}</b>` };

        return { link, actionBtn, titleValues, icon };
      }

      default:
        return { link: undefined, actionBtn: null, titleValues: undefined, icon: undefined };
    }
  };

  const { link, actionBtn, titleValues, icon } = getNotificationData();

  const titleValue = intl.formatMessage(
    { id: `notification_center.title.${type?.toLowerCase()}` },
    titleValues
  );

  return (
    <SmartLink
      path={inDropdown ? link : undefined}
      onClick={inDropdown ? hadleItemClick : undefined}
    >
      <div className={classnames(styles.root, { [styles.inDropdown]: inDropdown })}>
        <div className={styles.iconWrap}>
          <img srcSet={icon} className={styles.icon} />
        </div>
        <div className={styles.details}>
          <Text type="md">
            <span dangerouslySetInnerHTML={{ __html: titleValue || '' }} />
          </Text>
          <Text type="label" text={createdAt || ''} className={styles.date} />
        </div>
        {inDropdown ? <Icon name="Arrow-small-right" /> : actionBtn}
        {!read && (
          <>
            <div className={styles.readMark} onClick={handleReadClick} />
            <Tooltip
              id={`read-btn-${id}`}
              place="top"
              tooltipMsg="notification_center.item.read_mark.tooltip"
              className={styles.readBtn}
            >
              <div onClick={handleReadClick}>
                <Icon name="Eye" />
              </div>
            </Tooltip>
          </>
        )}
      </div>
    </SmartLink>
  );
};

export default NotificationItem;
