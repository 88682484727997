import React from 'react';

import styles from './Header.pcss';
import UploadMediaplan from './UploadMediaplan/UploadMediaplan';

interface Props {
  className: string;
  campaignId: string;
  importDisabled?: boolean;
  admin?: boolean;
  hasSubmission?: boolean;
}

const Header: React.FC<Props> = (props) => {
  const { className, campaignId, importDisabled, admin, hasSubmission } = props;

  return (
    <div className={`${styles.root} ${className}`}>
      <UploadMediaplan
        campaignId={campaignId}
        admin={admin}
        hasSubmission={hasSubmission}
        disabled={importDisabled}
      />
    </div>
  );
};

export default Header;
