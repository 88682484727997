import { DiscoverySearchSectionQuery$data } from 'GraphTypes/DiscoverySearchSectionQuery.graphql';
import { SearchQueryInput } from 'GraphTypes/SearchResultsContainerQuery.graphql';
import { createContext } from 'react';

type DiscoveryCreatorsContextType = {
  campaignId?: string;
  organizationId?: string;
  queryParams: SearchQueryInput;
  params: SearchQueryInput;
  updateParams: (data: unknown) => void;
  setOrganizationId: (orgId: string) => void;
  admin: boolean;
  setAdminStatus: (status: boolean) => void;
  selectedCreators: DiscoverySelectedCreator[];
  addCreatorToList: (creator: DiscoverySelectedCreator) => void;
  removeCreatorFromList: (creatorId: string) => void;
  importCreatorsToCustomList: (callback: (data: DiscoveryCreatorsImportCallback) => void) => void;
  isImportCreatorsInProgress: boolean;
  listId: string;
  isCreatingList: boolean;
  listData: DiscoverySearchSectionQuery$data['customList'];
  setListData: (data: DiscoverySearchSectionQuery$data['customList']) => void;
  setCounterData: (data: DiscoveryCreatorsCounterData) => void;
  counter: DiscoveryCreatorsCounterData;
  backUrl?: string;
};

export type DiscoverySelectedCreator = {
  id: string;
  name: string;
};

export type DiscoveryCreatorsImportCallback = {
  success: boolean;
};

export type DiscoveryCreatorsCounterData = {
  loaded: number | null;
  total: number | null;
};

const DiscoveryCreatorsContext = createContext<DiscoveryCreatorsContextType>(
  {} as DiscoveryCreatorsContextType
);

export { DiscoveryCreatorsContext };
export type { DiscoveryCreatorsContextType };
