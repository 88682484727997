/**
 * @generated SignedSource<<78b776b0a3d92a952f428be69fe0ff18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InAppNotificationCreatedSubscription$variables = Record<PropertyKey, never>;
export type InAppNotificationCreatedSubscription$data = {
  readonly inAppNotificationCreated: {
    readonly inAppNotification: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"NotificationItem_notification">;
    } | null | undefined;
    readonly user: {
      readonly inAppNotifications: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"NotificationItem_notification">;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly unreadCount: {
        readonly totalCount: number;
      } | null | undefined;
    } | null | undefined;
  };
};
export type InAppNotificationCreatedSubscription = {
  response: InAppNotificationCreatedSubscription$data;
  variables: InAppNotificationCreatedSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "NotificationItem_notification"
  }
],
v2 = {
  "kind": "Literal",
  "name": "read",
  "value": false
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v4 = {
  "alias": "unreadCount",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1
    },
    (v2/*: any*/)
  ],
  "concreteType": "InAppNotificationConnection",
  "kind": "LinkedField",
  "name": "inAppNotifications",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": "inAppNotifications(first:1,read:false)"
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 3
  },
  (v2/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Campaign",
  "kind": "LinkedField",
  "name": "campaign",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Project",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v10 = [
  (v9/*: any*/)
],
v11 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  (v0/*: any*/),
  {
    "kind": "TypeDiscriminator",
    "abstractKey": "__isInAppNotification"
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "createdAt",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "icon",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "ctaText",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "read",
    "storageKey": null
  },
  (v6/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      (v8/*: any*/)
    ],
    "type": "InAppNotifications_CampaignActivated",
    "abstractKey": null
  },
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalApplicantsCount",
        "storageKey": null
      }
    ],
    "type": "InAppNotifications_DailyOfferAcceptances",
    "abstractKey": null
  },
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Creative",
        "kind": "LinkedField",
        "name": "creative",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "InAppNotifications_CreativeCreated",
    "abstractKey": null
  },
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v7/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ContentReview",
        "kind": "LinkedField",
        "name": "contentReview",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentReviewAttachmentConnection",
            "kind": "LinkedField",
            "name": "attachments",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CreativeConnection",
        "kind": "LinkedField",
        "name": "creatives",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      }
    ],
    "type": "InAppNotifications_ContentReviewSubmitted",
    "abstractKey": null
  },
  {
    "kind": "InlineFragment",
    "selections": (v10/*: any*/),
    "type": "InAppNotifications_DraftDeadlineOverdue",
    "abstractKey": null
  },
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "InAppNotifications_BcaPermissionRevoked",
    "abstractKey": null
  },
  {
    "kind": "InlineFragment",
    "selections": (v10/*: any*/),
    "type": "InAppNotifications_AdvertiserUnresponsiveness",
    "abstractKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "InAppNotificationCreatedSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InAppNotificationCreatedPayload",
        "kind": "LinkedField",
        "name": "inAppNotificationCreated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "inAppNotification",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "InAppNotificationConnection",
                "kind": "LinkedField",
                "name": "inAppNotifications",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InAppNotificationEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v1/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "inAppNotifications(first:3,read:false)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "InAppNotificationCreatedSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InAppNotificationCreatedPayload",
        "kind": "LinkedField",
        "name": "inAppNotificationCreated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "inAppNotification",
            "plural": false,
            "selections": (v11/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "InAppNotificationConnection",
                "kind": "LinkedField",
                "name": "inAppNotifications",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InAppNotificationEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "inAppNotifications(first:3,read:false)"
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b29d628216105cc84b01a608d6dc7662",
    "id": null,
    "metadata": {},
    "name": "InAppNotificationCreatedSubscription",
    "operationKind": "subscription",
    "text": "subscription InAppNotificationCreatedSubscription {\n  inAppNotificationCreated {\n    inAppNotification {\n      __typename\n      id\n      ...NotificationItem_notification\n    }\n    user {\n      unreadCount: inAppNotifications(first: 1, read: false) {\n        totalCount\n      }\n      inAppNotifications(first: 3, read: false) {\n        edges {\n          node {\n            __typename\n            id\n            ...NotificationItem_notification\n          }\n        }\n      }\n      id\n    }\n  }\n}\n\nfragment NotificationItem_notification on InAppNotification {\n  __isInAppNotification: __typename\n  id\n  createdAt\n  icon\n  ctaText\n  read\n  type\n  ... on InAppNotifications_CampaignActivated {\n    campaign {\n      id\n      name\n    }\n  }\n  ... on InAppNotifications_DailyOfferAcceptances {\n    id\n    totalApplicantsCount\n  }\n  ... on InAppNotifications_CreativeCreated {\n    creative {\n      project {\n        id\n        campaign {\n          id\n          name\n        }\n      }\n      id\n    }\n  }\n  ... on InAppNotifications_ContentReviewSubmitted {\n    project {\n      id\n      campaign {\n        id\n        name\n        type\n      }\n    }\n    contentReview {\n      attachments {\n        totalCount\n      }\n      id\n    }\n    creatives {\n      totalCount\n    }\n  }\n  ... on InAppNotifications_DraftDeadlineOverdue {\n    project {\n      id\n      campaign {\n        id\n        name\n      }\n    }\n  }\n  ... on InAppNotifications_BcaPermissionRevoked {\n    project {\n      id\n      campaign {\n        id\n      }\n    }\n  }\n  ... on InAppNotifications_AdvertiserUnresponsiveness {\n    project {\n      id\n      campaign {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c5f08fd3ed76d1f88349d1f5030b44c1";

export default node;
