import React, { useContext } from 'react';

import styles from './Notifications.pcss';

import Notification from 'Atoms/Notification/Notification';
import {
  NotificationsContext,
  Types as NotificationsTypes,
} from 'Containers/Notifications/Notifications.Context';

interface Props {
  disable?: boolean;
  removeDelay?: number;
}

const Notifications: React.FC<Props> = ({ removeDelay, disable }) => {
  if (disable) return null;

  const { state: notificationsState, dispatch: notificationsDispatch } =
    useContext(NotificationsContext);

  const notifications = notificationsState.items;

  const removeItemFromStore = (id: string) => {
    notificationsDispatch({
      type: NotificationsTypes.REMOVE_NOTIFICATION,
      payload: { itemId: id },
    });
  };

  return (
    <div className={styles.root}>
      {notifications.map((notification) => {
        return (
          <Notification
            key={notification.id}
            id={notification.id}
            text={notification.text}
            values={notification.values}
            btnCaption={notification.btnCaption}
            removeDelay={removeDelay}
            onBtnClick={notification.callback}
            onHideClick={removeItemFromStore}
          />
        );
      })}
    </div>
  );
};

export default Notifications;
