/**
 * @generated SignedSource<<9fb1199fd384c72a40100460d7d6bd9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT" | "%future added value";
export type CampaignStage = "ACTIVE" | "COMPLETED" | "DRAFT" | "IN_REVIEW" | "%future added value";
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type ImportCreatorsBySearchQueryState = "FAILURE" | "PENDING" | "SUCCESS" | "%future added value";
export type SearchQueryInput = {
  acceptsBarter?: boolean | null | undefined;
  active?: boolean | null | undefined;
  ageRangeIds?: ReadonlyArray<string> | null | undefined;
  audienceAgeRangePercentage?: number | null | undefined;
  audienceCitiesIds?: ReadonlyArray<string> | null | undefined;
  audienceCityPercentage?: number | null | undefined;
  audienceCountryPercentage?: number | null | undefined;
  audienceEthnicityIds?: ReadonlyArray<string> | null | undefined;
  audienceGenderId?: string | null | undefined;
  audienceGenderPercentage?: number | null | undefined;
  audienceInterestPercentage?: number | null | undefined;
  businessAccountEnabled?: boolean | null | undefined;
  campaignId?: string | null | undefined;
  campaignsCompletionsCountFrom?: number | null | undefined;
  campaignsCompletionsCountTo?: number | null | undefined;
  categoryIds?: ReadonlyArray<string> | null | undefined;
  cityIds?: ReadonlyArray<string> | null | undefined;
  contentCreator?: boolean | null | undefined;
  countryIds?: ReadonlyArray<string> | null | undefined;
  creatorAnimalIds?: ReadonlyArray<string> | null | undefined;
  creatorBadgeIds?: ReadonlyArray<string> | null | undefined;
  creatorBirthdateFrom?: any | null | undefined;
  creatorBirthdateTo?: any | null | undefined;
  creatorBodyTypeIds?: ReadonlyArray<string> | null | undefined;
  creatorCountryIds?: ReadonlyArray<string> | null | undefined;
  creatorEthnicityIds?: ReadonlyArray<string> | null | undefined;
  creatorGenderId?: string | null | undefined;
  creatorHairTypeIds?: ReadonlyArray<string> | null | undefined;
  creatorIdsToExclude?: ReadonlyArray<string> | null | undefined;
  creatorLanguageIds?: ReadonlyArray<string> | null | undefined;
  creatorTagIds?: ReadonlyArray<string> | null | undefined;
  creatorTypes?: ReadonlyArray<CreatorType> | null | undefined;
  currency?: Currency | null | undefined;
  customListIds?: ReadonlyArray<string> | null | undefined;
  employed?: boolean | null | undefined;
  engagementRateFrom?: number | null | undefined;
  engagementRateTo?: number | null | undefined;
  excludeBadges?: boolean | null | undefined;
  excludeCreatorsWorkedWith?: boolean | null | undefined;
  excludeHiredMoreThanOnce?: boolean | null | undefined;
  exclusions?: ReadonlyArray<SearchQueryExclusionParams> | null | undefined;
  favorite?: boolean | null | undefined;
  followersFrom?: number | null | undefined;
  followersTo?: number | null | undefined;
  hashtags?: ReadonlyArray<string> | null | undefined;
  includeAmazonStorefrontLink?: boolean | null | undefined;
  includeHidden?: boolean | null | undefined;
  interestIds?: ReadonlyArray<string> | null | undefined;
  limit?: number | null | undefined;
  onlyCreatorsWorkedWith?: boolean | null | undefined;
  owned?: boolean | null | undefined;
  paidSocialEnabled?: boolean | null | undefined;
  postPriceFrom?: number | null | undefined;
  postPriceTo?: number | null | undefined;
  postsReachFrom?: number | null | undefined;
  postsReachTo?: number | null | undefined;
  realFollowersFrom?: number | null | undefined;
  realFollowersTo?: number | null | undefined;
  reelsPlaysMedianFrom?: number | null | undefined;
  reelsPlaysMedianTo?: number | null | undefined;
  reviewedAtFrom?: any | null | undefined;
  reviewedAtTo?: any | null | undefined;
  storiesReachFrom?: number | null | undefined;
  storiesReachTo?: number | null | undefined;
  strictlyByPrimaryCategory?: boolean | null | undefined;
  textQuery?: string | null | undefined;
  viewsMedianFrom?: number | null | undefined;
  viewsMedianTo?: number | null | undefined;
  viewsPerPostFrom?: number | null | undefined;
  viewsPerPostTo?: number | null | undefined;
  withTiktokShop?: boolean | null | undefined;
};
export type SearchQueryExclusionParams = {
  advertiserProjectStages?: ReadonlyArray<AdvertiserProjectStage> | null | undefined;
  campaignId: string;
};
export type CreatorsCountQuery$variables = {
  campaignId: string;
  searchQueryInput?: SearchQueryInput | null | undefined;
};
export type CreatorsCountQuery$data = {
  readonly campaign: {
    readonly latestImportCreatorsBySearchQueryOperation: {
      readonly state: ImportCreatorsBySearchQueryState;
    } | null | undefined;
    readonly searchCreators: {
      readonly totalCount: number;
    } | null | undefined;
    readonly stage: CampaignStage;
  } | null | undefined;
  readonly currentUser: {
    readonly admin: boolean;
  } | null | undefined;
};
export type CreatorsCountQuery = {
  response: CreatorsCountQuery$data;
  variables: CreatorsCountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "campaignId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchQueryInput"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "campaignId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stage",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "searchQueryInput",
      "variableName": "searchQueryInput"
    }
  ],
  "concreteType": "CreatorSearchResultConnection",
  "kind": "LinkedField",
  "name": "searchCreators",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "admin",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatorsCountQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ImportCreatorsBySearchQueryOperation",
            "kind": "LinkedField",
            "name": "latestImportCreatorsBySearchQueryOperation",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreatorsCountQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ImportCreatorsBySearchQueryOperation",
            "kind": "LinkedField",
            "name": "latestImportCreatorsBySearchQueryOperation",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4541f9e3ef42c31a83553a245bf23552",
    "id": null,
    "metadata": {},
    "name": "CreatorsCountQuery",
    "operationKind": "query",
    "text": "query CreatorsCountQuery(\n  $campaignId: ID!\n  $searchQueryInput: SearchQueryInput\n) {\n  campaign(id: $campaignId) {\n    stage\n    searchCreators(searchQueryInput: $searchQueryInput) {\n      totalCount\n    }\n    latestImportCreatorsBySearchQueryOperation {\n      state\n      id\n    }\n    id\n  }\n  currentUser {\n    admin\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "672d1743507abac06a78dfd78f158637";

export default node;
