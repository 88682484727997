import { createContext } from 'react';

type MessageAttachmentsContextType = {
  loadingAll: boolean;
  setLoadingAll: (data: boolean) => void;
  loadedCount: number;
  setLoadedCount: (data: number) => void;
  handleFileUploaded: () => void;
};

const MessageAttachmentsContext = createContext<MessageAttachmentsContextType>(
  {} as MessageAttachmentsContextType
);

export { MessageAttachmentsContext };
export type { MessageAttachmentsContextType };
